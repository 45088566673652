import { Injectable } from '@angular/core';
import { InMemoryDbService } from 'angular-in-memory-web-api';

@Injectable({
	providedIn: 'root',
})
export class InMemoryDataService implements InMemoryDbService {
	createDb() {
		const pages = [
			{
				id: 1,
				name: 'intro',
				tagline: 'About Us',
				title: 'Buying and Trading Coins',
				description:
					'George Gilliam has been collecting rare coins his whole life, and he has passed his passion down to his children. Together, they run Gilliam World Coins. We buy and sell rare coins, foreign and U.S. paper currency. We collect various forms of currency from the United States, United Kingdom to world coins.  The coins and currency collection is a great opportunity for collectors and investors alike.',
			},
			{
				id: 2,
				name: 'Help Center',
				tagline: 'Help Center',
				title: 'Helpful links',
				description:
					'Explore the following external links to connect with other agencies, organizations, programs, and resources related to coin collecting.',
			},
			{
				id: 3,
				name: 'services',
				tagline: 'BELIEVING',
				title: 'Focusing On What Matters Most',
				description:
					'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam!',
			},
			{
				id: 4,
				name: 'testimonials',
				tagline: 'FEEDBACK',
				title: 'What our customers are saying',
				description: '',
			},
			{
				id: 5,
				name: 'pricing',
				tagline: 'YOUR CHOICE',
				title: 'We have the right package for you',
				description: '',
			},
			{
				id: 6,
				name: 'footer',
				tagline: '❤ ',
				developer: 'yoski inc',
				developerlink: 'gilliamworldcoinscom',
			},
			{
				id: 7,
				name: 'header',
				tagline: 'Gilliam World Coin',
				title:
					'Welcome to world of coin collecting, Coin collecting can be a masterclass in history, geography, and even economics. Gilliam world coins is a family-owned coin shop. We specialize in coins, currency. We work with all types of coins including UK Coins, US Coins, foreign coins, ancient coins, antique coins, mint and proof sets.',
				buttontext: 'Get an Estimate',
				buttonlink: '/home',
				image: 'homepage.png',
			},
			{
				id: 8,
				name: 'queen-victoria1847',
				tagline: 'Queen Victoria',
				title: 'Queen Victoria 1847',
				description:
					'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam!',
			},
		];

		const features = [
			{
				id: 1,
				icon: 'tags',
				title: 'Get an Estimate',
				description:
					'Do you have a coin collection you’re thinking about selling? First research their worth online, you may find conflicting information from different websites.  Each coin can be based on year, quality and availability.  ',
			},
			{
				id: 2,
				icon: 'envelope',
				title: 'Contact Us',
				description: 'hachu@yahoo.com',
			},
			{
				id: 3,
				icon: 'thumbtack"',
				title: 'Feedback',
				description: 'We have all good feedback ',
			},
		];
		const images = [
			{ id: 1, name: 'crown.jpg' },
			{ id: 2, name: 'halfcrown.jpg' },
			{ id: 3, name: 'florin.jpg' },
			{ id: 4, name: 'shilling.jpg' },
			{ id: 5, name: 'sixpence.jpg' },
			{ id: 6, name: 'threepence.jpg' },
			{ id: 7, name: 'penny.jpg' },
			{ id: 8, name: 'halfpenny.jpeg' },
			{ id: 9, name: 'farthing.jpeg' },
		];
		const menu = [
			{ id: 1, title: 'Home', link: '/home' },
			{ id: 2, title: 'About', link: '/about' },
			{ id: 3, title: 'British Coins', link: '/britishcoins' },
			{ id: 4, title: 'US Coins', link: '/uscoins' },
			{ id: 4, title: 'World Coins', link: '/worldcoins' },
			{ id: 6, title: 'Help Center', link: '/clients' },
		];
		const companies = [
			{
				id: 1,
				name: 'Royal Museum',
				weblink: 'https://www.royalmintmuseum.org.uk/',
				logo: 'company-logo3.png',
			},
			{
				id: 2,
				name: 'Royal Mint',
				weblink: 'https://www.royalmint.com/',
				logo: 'company-logo2.png',
			},
			{
				id: 3,
				name: 'NGC',
				weblink: 'https://www.ngccoin.com/ncs-conservation/',
				logo: 'company-logo9.png',
			},
			{
				id: 4,
				name: 'American Numismatic Association',
				weblink: 'https://www.money.org/',
				logo: 'company-logo4.png',
			},
			{
				id: 5,
				name: 'United States Mint',
				weblink: 'https://www.usmint.gov/',
				logo: 'company-logo6.png',
			},
		];

		const feedback = [
			{
				id: 1,
				name: 'John Doe',
				userimage: 'user-1.jpg',
				comments:
					'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
				company: 'ABC',
			},
			{
				id: 2,
				name: 'Roslyn Doe',
				userimage: 'user-2.jpg',
				comments:
					'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
				company: 'Happy Customer',
			},
			{
				id: 3,
				name: 'Thomas Doe',
				userimage: 'user-3.jpg',
				comments:
					'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
				company: 'Happy Customer',
			},
		];
		const plans = [
			{
				id: 1,
				title: 'PERSONAL',
				subtitle: 'The standard version',
				description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
				price: '19',
				currency: '₹',
				downloads: '5 Downloads',
				extensions: '2 Extensions',
				tutorials: 'Tutorials',
				support: 'Forum Support',
				updates: '1 year free updates',
				buttontext: 'BUY TODAY',
				buttonlink: '#',
				featured: false,
			},
			{
				id: 2,
				title: 'STUDENT',
				subtitle: 'Most popular choice',
				description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
				price: '29',
				currency: '₹',
				downloads: '15 Downloads',
				extensions: '5 Extensions',
				tutorials: 'Tutorials with files',
				support: 'Forum Support',
				updates: '2 year free updates',
				buttontext: 'BUY TODAY',
				buttonlink: '#',
				featured: true,
			},
			{
				id: 3,
				title: 'BUSINESS',
				subtitle: 'For the whole team',
				description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
				price: '49',
				currency: '₹',
				downloads: 'Unlimited Downloads',
				extensions: 'Unlimited Downloads',
				tutorials: 'HD Video Tutorials',
				support: 'Chat Support',
				updates: 'Lifetime free updates',
				buttontext: 'BUY TODAY',
				buttonlink: '#',
				featured: false,
			},
		];
		const websites = [
			{
				id: 1,
				link: 'https://facebook.com/',
				title: 'Facebook',
				target: '_blank',
				username: 'Thor',
				icon: 'facebook',
			},
			// {
			//   id: 2,
			//   link: "https://googleplus.com/",
			//   title: "Google+",
			//   target: "_blank",
			//   username: "+jagmohan",
			//   icon: "google-plus",
			// },
			{
				id: 3,
				link: 'https://twitter.com/',
				title: 'Twitter',
				target: '_blank',
				username: 'joker',
				icon: 'twitter',
			},
			{
				id: 4,
				link: 'https://instagram.com/',
				title: 'Instagram',
				target: '_blank',
				username: 'superman',
				icon: 'instagram',
			},
			// {
			//   id: 5,
			//   link: "https://behance.com/",
			//   title: "Behance",
			//   target: "_blank",
			//   username: "jagmohan",
			//   icon: "behance",
			// },
		];

		const monarchList = [
			{
				id: 1,
				country: 'unitedkingdom',
				countryId: 1,
				type: 'Charles2',
				name: 'King Charles II',
				tagline: 'Charles II of England',
				title: '30 January 1649 – 3 September 1651',
				description:
					'Charles II was King of Scotland from 1649 until 1651 and King of England, Scotland, and Ireland from the 1660 Restoration of the monarchy until his death in 1685. Charles II was the eldest surviving child of Charles I of England, Scotland and Ireland and Henrietta Maria of France.',
				Img: 'King_Charles_II.jpg',
				bottomContent:
					'Charles II was the first king of England following his return from exile and the Restoration of the Monarchy in 1660.',
			},
			{
				id: 2,
				country: 'unitedkingdom',
				countryId: 1,
				type: 'James2',
				name: 'King James II',
				tagline: 'James II of England',
				title: '6 February 1685 – 23 December 1688',
				description:
					'James VII and II was King of England and Ireland as James II and King of Scotland as James VII from the death of his elder brother, Charles II, on 6 February 1685. He was deposed in the Glorious Revolution of 1688. He was the last Catholic monarch of England, Scotland, and Ireland.',
				Img: 'James_II.jpg',
				bottomContent:
					'James II was the last Catholic monarch in Britain and his short reign is known for his desire for religious tolerance.',
			},
			{
				id: 3,
				country: 'unitedkingdom',
				countryId: 1,
				type: 'WilliamAndMary',
				name: 'William And Mary',
				tagline: 'William III of England',
				title: '1689 – 1694',
				description:
					'William III, also widely known as William of Orange, was the sovereign Prince of Orange from birth, Stadtholder of Holland, Zeeland, Utrecht, Guelders, and Overijssel in the Dutch Republic from the 1670s, and King of England, Ireland, and Scotland from 1689 until his death in 1702. ',
				Img: 'William_Mary.jpg',
				bottomContent:
					'Religious divides dominated international relations and war was never far away. Our William III coins selection will take you closer to this turbulent period.',
			},
			{
				id: 4,
				country: 'unitedkingdom',
				countryId: 1,
				type: 'William3',
				name: 'King William III',
				tagline: 'William III of England',
				title: '1689 – 8 March 1702',
				description:
					'William III, also widely known as William of Orange, was the sovereign Prince of Orange from birth, Stadtholder of Holland, Zeeland, Utrecht, Guelders, and Overijssel in the Dutch Republic from the 1670s, and King of England, Ireland, and Scotland from 1689 until his death in 1702. ',
				Img: 'King_William_III.jpg',
				bottomContent:
					'Religious divides dominated international relations and war was never far away. Our William III coins selection will take you closer to this turbulent period.',
			},
			{
				id: 5,
				country: 'United Kingdom',
				countryId: 1,
				type: 'Anne',
				name: 'Queen Anne',
				tagline: 'Anne Queen of Great Britain',
				title: '8 March 1702 – 1 August 1714',
				description:
					'Anne was Queen of Great Britain and Ireland following the ratification of the Acts of Union on 1 May 1707, which merged the kingdoms of Scotland and England. Before this, she was Queen of England, Scotland, and Ireland from 8 March 1702. Anne was born during the reign of her uncle King Charles II. ',
				Img: 'Queen_Anne.jpg',
				bottomContent:
					'The reign of Queen Anne transformed Britain with the historic Acts of Union in 1707 that brought together the nations of England and Scotland in one kingdom, Great Britain.',
			},
			{
				id: 6,
				country: 'unitedkingdom',
				countryId: 1,
				type: 'George1',
				name: 'King George I',
				tagline: 'George I of Great Britain',
				title: '1 August 1714 – 11 June 1727',
				description:
					'George I was King of Great Britain and Ireland from 1 August 1714 and ruler of the Electorate of Hanover within the Holy Roman Empire from 23 January 1698 until his death in 1727. He was the first British monarch of the House of Hanover.',
				Img: 'King_George_I.jpg',
				bottomContent: 'Great Britain George I South Seas Company SSC.',
			},
			{
				id: 7,
				country: 'unitedkingdom',
				countryId: 1,
				type: 'George2',
				name: 'King George II ',
				tagline: 'George II of Great Britain',
				title: 'June 1727 – 25 October 1760',
				description:
					'George II was King of Great Britain and Ireland, Duke of Brunswick-Lüneburg and a prince-elector of the Holy Roman Empire from 11 June 1727 until his death in 1760. Born and brought up in northern Germany, George is the most recent British monarch born outside Great Britain.',
				Img: 'George_II.jpg',
				bottomContent: 'George II.',
			},
			{
				id: 8,
				country: 'unitedkingdom',
				countryId: 1,
				type: 'George3',
				name: 'King George III',
				tagline: 'George III of Great Britain',
				title: '25 October 1760 – 29 January 1820',
				description:
					'George III was King of Great Britain and Ireland from 25 October 1760 until his death in 1820. The Acts of Union 1800 unified Great Britain and Ireland into the United Kingdom of Great Britain and Ireland, with George as its king.',
				Img: 'King_George_III.jpg',
				bottomContent:
					'He is the longest-reigning male monarch in British history.',
			},
			{
				id: 9,
				country: 'unitedkingdom',
				countryId: 1,
				type: 'George4',
				name: 'King George IV',
				tagline: 'George IV of Great Britain',
				title: '29 January 1820 – 26 June 1830',
				description:
					'George IV was King of the United Kingdom of Great Britain and Ireland and King of Hanover from 29 January 1820 until his death in 1830.',
				Img: 'King_George_IV.jpg',
				bottomContent:
					'George IV was the eldest child of King George III and Queen Charlotte.',
			},
			{
				id: 10,
				country: 'unitedkingdom',
				countryId: 1,
				type: 'William4',
				name: 'King William IV',
				tagline: 'King William of England',
				title: '26 June 1830 – 20 June 1837',
				description:
					'William IV was King of the United Kingdom of Great Britain and Ireland and King of Hanover from 26 June 1830 until his death in 1837. The third son of George III, William succeeded his elder brother George IV, becoming the last king and penultimate monarch of Britains House of Hanover.',
				Img: 'William_IV.jpg',
				bottomContent:
					'The third son of George III, William succeeded his elder brother George IV, becoming the last king and penultimate monarch of Britains House of Hanover.',
			},
			{
				id: 11,
				country: 'unitedkingdom',
				countryId: 1,
				type: 'Victoria',
				name: 'Queen Victoria',
				tagline: 'Queen Victoria of England',
				title: '20 June 1837 – 22 January 1901',
				description:
					'Queen Victoria was crowned when she was just 18, and her reign lasted 63 years. She restored the image of the monarchy during a time of great economic, political and societal change. Lived: 1819–1901. Field: Royalty. Key fact: Reigned during a period of immense change which saw a great expansion of the British Empire.',
				Img: 'queen_victoria.jpg',
				bottomContent:
					'She restored the image of the monarchy during a time of great economic, political and societal change.',
			},
			{
				id: 12,
				country: 'unitedkingdom',
				countryId: 1,
				type: 'Edward7',
				name: 'King Edward VII',
				tagline: 'King Edward VII of England',
				title: '22 January 1901 – 6 May 1910',
				description:
					'Edward VII was King of the United Kingdom and the British Dominions, and Emperor of India, from 22 January 1901 until his death in 1910. The second child and eldest son of Queen Victoria and Prince Albert of Saxe-Coburg and Gotha, Edward, nicknamed "Bertie", was related to royalty throughout Europe.',
				Img: 'King_Edward_VII.jpg',
				bottomContent: 'Maundy Coins.',
			},
			{
				id: 13,
				country: 'unitedkingdom',
				countryId: 1,
				type: 'George5',
				name: 'King George V',
				tagline: 'King George V of England',
				title: '6 May 1910 – 20 January 1936',
				description:
					'George V was King of the United Kingdom and the British Dominions, and Emperor of India, from 6 May 1910 until his death in 1936. George was born during the reign of his paternal grandmother, Queen Victoria, as the second son of the Prince and Princess of Wales.',
				Img: 'King_George_V.jpg',
				bottomContent: 'King of the United Kingdom.',
			},
			{
				id: 14,
				country: 'unitedkingdom',
				countryId: 1,
				type: 'Edward8',
				name: 'King Edward VIII',
				tagline: 'King Edward VIII of England',
				title: '20 January 1936 – 11 December 1936',
				description:
					'Edward VIII, later known as the Duke of Windsor, was King of the United Kingdom and the Dominions of the British Empire, and Emperor of India, from 20 January 1936 until his abdication in December of the same year.',
				Img: 'King_Edward_VIII.jpg',
				bottomContent:
					' Edward became the second monarch of the House of Windsor.',
			},
			{
				id: 15,
				country: 'unitedkingdom',
				countryId: 1,
				type: 'George6',
				name: 'King George VI',
				tagline: 'King George VI of England',
				title: '11 December 1936 – 6 February 1952',
				description:
					'George VI (born December 14, 1895, Sandringham, Norfolk, England—died February 6, 1952, Sandringham) was the king of the United Kingdom from 1936 to 1952. George VI. Also called (1920–36): Prince Albert, duke of York. In full: Albert Frederick Arthur George.',
				Img: 'King_George_VI.jpg',
				bottomContent: 'King of the United Kingdom.',
			},
			{
				id: 16,
				country: 'unitedkingdom',
				countryId: 1,
				type: 'Elizabeth2',
				name: 'Queen Elizabeth II ',
				tagline: 'Queen Elizabeth II of England',
				title: '6 February 1952 – 8 September 2022',
				description:
					'Elizabeth II was Queen of the United Kingdom and other Commonwealth realms from 6 February 1952 until her death in 2022. She was queen regnant of 32 sovereign states over the course of her lifetime and remained the monarch of 15 realms by the time of her death.',
				Img: 'Queen_Elizabeth_II.jpg',
				bottomContent: 'Head of the Commonwealth.',
			},
			{
				id: 17,
				country: 'unitedkingdom',
				countryId: 1,
				type: 'Charles3',
				name: 'King Charles III',
				tagline: 'King Charles III of England',
				title: '8 September 2022 – present',
				description:
					'Charles III is King of the United Kingdom and the 14 other Commonwealth realms. Charles was born in Buckingham Palace during the reign of his maternal grandfather, King George VI, and became heir apparent when his mother, Queen Elizabeth II, acceded to the throne in 1952.',
				Img: 'King_Charles_III.jpg',
				bottomContent: 'Head of the Commonwealth.',
			},
		];

		const countrytypeList = [
			// {
			// 	id: 1,
			// 	country: 'unitedkingdom',
			// 	name: 'United Kingdom',
			// 	tagline: 'British Coin List',
			// 	title: 'The Crown',
			// 	description:
			// 		'The British crown was a denomination of sterling coinage worth 1⁄4 of one pound, or 5 shillings, or 60 (old) pence.  The crown was first issued during the reign of Edward VI, as part of the coinage of the Kingdom of England.',
			// 	Img: 'unitedkingdom.png',
			// 	bottomContent:
			// 		'The coins origins lie in the English silver crown, one of many silver coins that appeared in various countries from the 16th century onwards (most famously the Spanish piece of eight).',
			// },
			// {
			// 	id: 2,
			// 	country: 'unitedstates',
			// 	name: 'United States',
			// 	tagline: 'E pluribus unum',
			// 	title: 'In God We Trust',
			// 	description:
			// 		'The U.S. is a country of 50 states covering a vast swath of North America, with Alaska in the northwest and Hawaii extending the nation’s presence into the Pacific Ocean. Major Atlantic Coast cities are New York, a global finance and culture center, and capital Washington, DC. Midwestern metropolis Chicago is known for influential architecture and on the west coast, Los Angeles Hollywood is famed for filmmaking.',
			// 	Img: 'unitedstates.png',
			// 	bottomContent: 'E pluribus unum.',
			// },
			{
				id: 3,
				country: 'austria',
				name: 'Austria',
				tagline: 'World Coins',
				title: 'Austria est imperio optime unita',
				description:
					'Austria, formally the Republic of Austria, is a landlocked country in Central Europe, lying in the Eastern Alps. It is a federation of nine federal states, one of which is the capital, Vienna, the most populous city and federal state.',
				Img: 'austria.png',
				bottomContent: 'Austria is best united by the Empire',
			},
			{
				id: 4,
				country: 'belgium',
				name: 'Belgium',
				tagline: 'World Coins',
				title: 'l union fait la force',
				description:
					'Kingdom of Belgium,[B] is a country in Northwestern Europe. The country is bordered by the Netherlands to the north, Germany to the east, Luxembourg to the southeast, France to the south, and the North Sea to the west. ',
				Img: 'belgium.png',
				bottomContent: '',
			},
			{
				id: 5,
				country: 'cccp',
				name: 'CCCP',
				tagline: 'World Coins',
				title: 'The Soviet Union',
				description:
					'The Soviet Union, officially the Union of Soviet Socialist Republics, was a transcontinental country that spanned much of Eurasia from 1922 to 1991..',
				Img: 'ussr.png',
				bottomContent: 'Union of Socialist Socialist Republics.',
			},
			{
				id: 6,
				country: 'mexico',
				name: 'Mexico ',
				tagline: 'World Coins',
				title: 'United Mexican States',
				description:
					'Mexico, officially the United Mexican States, is a country in the southern portion of North America..',
				Img: 'mexico.png',
				bottomContent: 'United Mexican States',
			},
			{
				id: 7,
				country: 'cuba',
				name: 'Cuba',
				tagline: 'World Coins',
				title: 'Patria o Muerte, Venceremos',
				description:
					'Cuba, officially the Republic of Cuba, is an island country, comprising the island of Cuba, Isla de la Juventud, archipelagos, 4,195 islands and cays surrounding the main island. Cuba is located where the northern Caribbean Sea, Gulf of Mexico, and Atlantic Ocean meet.',
				Img: 'cuba.png',
				bottomContent: 'Republic of Cuba.',
			},
			{
				id: 8,
				country: 'china',
				name: 'China',
				tagline: 'World Coins',
				title: 'Peoples Republic of China',
				description:
					'China, officially the Peoples Republic of China (PRC), is a country in East Asia. With a population exceeding 1.4 billion, it is the worlds second-most populous country.',
				Img: 'china.png',
				bottomContent: 'cradles of civilization.',
			},
			{
				id: 9,
				country: 'france',
				name: 'France',
				tagline: 'World Coins',
				title: 'Liberté, Egalité, Fraternité',
				description:
					'France,officially the French Republic,[b] is a country located primarily in Western Europe. It also includes overseas regions and territories in the Americas and the Atlantic, Pacific and Indian oceans,[XII] giving it one of the largest discontiguous exclusive economic zones in the world',
				Img: 'france.png',
				bottomContent: 'Liberty, Equality, Justice',
			},
			{
				id: 10,
				country: 'iceland',
				name: 'Iceland',
				tagline: 'World Coins',
				title: 'Sagas of Icelanders',
				description:
					'Iceland (Icelandic: Ísland is a Nordic island country between the North Atlantic and Arctic Oceans, on the Mid-Atlantic Ridge between North America and Europe.',
				Img: 'iceland.png',
				bottomContent: 'Come and be inspired by Iceland.',
			},
			{
				id: 11,
				country: 'italy',
				name: 'Italy',
				tagline: 'World Coins',
				title: 'LItalia e una Repubblica democratica, fondata sul lavoro',
				description:
					'Italy, a European country with a long Mediterranean coastline, has left a powerful mark on Western culture and cuisine. Its capital, Rome, is home to the Vatican as well as landmark art and ancient ruins. Other major cities include Florence, with Renaissance masterpieces such as Michelangelos David and Brunelleschis Duomo; Venice, the city of canals and Milan, Italys fashion capital.',
				Img: 'italy.png',
				bottomContent:
					'Assissi and Saint Caterina of Siena are the patron saints of Italy.',
			},
			{
				id: 12,
				country: 'luxembourg',
				name: 'Luxembourg',
				tagline: 'World Coins',
				title: 'Mir wëlle bleiwe wat mir sinn',
				description:
					'Luxembourg is a small European country, surrounded by Belgium, France and Germany. It’s mostly rural, with dense Ardennes forest and nature parks in the north, rocky gorges of the Mullerthal region in the east and the Moselle river valley in the southeast. Its capital, Luxembourg City, is famed for its fortified medieval old town perched on sheer cliffs.',
				Img: 'luxembourg.png',
				bottomContent: 'we will remain what we are.',
			},
			{
				id: 13,
				country: 'monaco',
				name: 'Monaco',
				tagline: 'World Coins',
				title: 'Deo Juvante',
				description:
					'Monaco, officially the Principality of Monaco, is a sovereign city-state and microstate on the French Riviera a few kilometres west of the Italian region of Liguria, in Western Europe, on the Mediterranean Sea.',
				Img: 'monaco.png',
				bottomContent: 'With Gods Help.',
			},
			{
				id: 14,
				country: 'netherlands',
				name: 'Netherlands',
				tagline: 'World Coins',
				title: 'Je Maintiendrai',
				description:
					'The Netherlands, a country in northwestern Europe, is known for a flat landscape of canals, tulip fields, windmills and cycling routes. Amsterdam, the capital, is home to the Rijksmuseum, Van Gogh Museum and the house where Jewish diarist Anne Frank hid during WWII. Canalside mansions and a trove of works from artists including Rembrandt and Vermeer remain from the citys 17th-century.',
				Img: 'netherlands.png',
				bottomContent: 'Amsterdam.',
			},
			{
				id: 15,
				country: 'poland',
				name: 'Poland',
				tagline: 'World Coins',
				title: 'Za wolność Naszą i Waszą',
				description:
					'Poland, officially the Republic of Poland, is a country in Central Europe. It is divided into 16 administrative voivodeship provinces, covering an area of 312,696 km². Poland has a population of over 38 million and is the fifth-most populous member state of the European Union.',
				Img: 'poland.png',
				bottomContent: 'Rzeczpospolita Polska.',
			},
			{
				id: 16,
				country: 'spain',
				name: 'Spain',
				tagline: 'World Coins',
				title: 'Plus ultra',
				description:
					'Spain, a country on Europes Iberian Peninsula, includes 17 autonomous regions with diverse geography and cultures. Capital city Madrid is home to the Royal Palace and Prado museum, housing works by European masters. Segovia has a medieval castle (the Alcázar) and an intact Roman aqueduct. Catalonia’s capital, Barcelona, is defined by Antoni Gaudí’s whimsical modernist landmarks like the Sagrada Família church.',
				Img: 'spain.png',
				bottomContent: 'Pillars of Hercules.',
			},
			{
				id: 17,
				country: 'switzerland',
				name: 'Switzerland',
				tagline: 'World Coins',
				title: 'Unus pro Omnibus, Omnes pro Uno',
				description:
					'Switzerland is a mountainous Central European country, home to numerous lakes, villages and the high peaks of the Alps. Its cities contain medieval quarters, with landmarks like capital Bern’s Zytglogge clock tower and Lucerne’s wooden chapel bridge. The country is also known for its ski resorts and hiking trails. Banking and finance are key industries, and Swiss watches and chocolate are world renowned.',
				Img: 'switzerland.png',
				bottomContent: 'Swiss Confederation.',
			},
			{
				id: 18,
				country: 'yugoslavia',
				name: 'Yugoslavia',
				tagline: 'World Coins',
				title: 'Brotherhood and unity',
				description:
					'Yugoslavia was a country in Southeast and Central Europe that existed from 1918 to 1992. It came into existence in 1918 following World War I, under the name of the Kingdom of Serbs, Croats and Slovenes.',
				Img: 'yugoslavia.png',
				bottomContent: 'Communists of Yugoslavia.',
			},
			{
				id: 19,
				country: 'denmark',
				name: 'Denmark',
				tagline: 'World Coins',
				title: 'Guds hjaelp, Folkets kaerlighed, Danmarks styrke.',
				description:
					'Denmark is a Scandinavian country comprising the Jutland Peninsula and numerous islands. Its linked to nearby Sweden via the Öresund bridge. Copenhagen, its capital, is home to royal palaces and colorful Nyhavn harbor, plus the Tivoli amusement park and the iconic Little Mermaid statue. Odense is writer Hans Christian Andersens hometown, with a medieval core of cobbled streets and half-timbered houses. ',
				Img: 'denmark.png',
				bottomContent: 'Danish Realm.',
			},
			{
				id: 20,
				country: 'norway',
				name: 'Norway',
				tagline: 'World Coins',
				title: 'Alt for Norge',
				description:
					'Norway is a Scandinavian country encompassing mountains, glaciers and deep coastal fjords. Oslo, the capital, is a city of green spaces and museums. Preserved 9th-century Viking ships are displayed at Oslos Viking Ship Museum. Bergen, with colorful wooden houses, is the starting point for cruises to the dramatic Sognefjord. Norway is also known for fishing, hiking and skiing, notably at Lillehammers Olympic resort. ',
				Img: 'norway.png',
				bottomContent: 'All for Norway.',
			},
			{
				id: 21,
				country: 'sweden',
				name: 'Sweden',
				tagline: 'World Coins',
				title: 'För Sverige – i tiden',
				description:
					'Sweden is a Scandinavian nation with thousands of coastal islands and inland lakes, along with vast boreal forests and glaciated mountains. Its principal cities, eastern capital Stockholm and southwestern Gothenburg and Malmö, are all coastal. Stockholm is built on 14 islands. It has more than 50 bridges, as well as the medieval old town, Gamla Stan, royal palaces and museums such as open-air Skansen.',
				Img: 'sweden.png',
				bottomContent: 'sweden.',
			},
			{
				id: 22,
				country: 'ireland',
				name: 'Ireland',
				tagline: 'World Coins',
				title: 'Erin go Bragh',
				description:
					'The Republic of Ireland occupies most of the island of Ireland, off the coast of England and Wales. Its capital, Dublin, is the birthplace of writers like Oscar Wilde, and home of Guinness beer. The 9th-century Book of Kells and other illustrated manuscripts are on show in Dublin’s Trinity College Library. Dubbed the "Emerald Isle” for its lush landscape, the country is dotted with castles like medieval Cahir Castle.',
				Img: 'ireland.png',
				bottomContent: 'Ireland.',
			},
			{
				id: 23,
				country: 'germany',
				name: 'Germany',
				tagline: 'World Coins',
				title: 'Einigkeit und Recht und Freiheit',
				description:
					'Germany is a Western European country with a landscape of forests, rivers, mountain ranges and North Sea beaches. It has over 2 millennia of history. Berlin, its capital, is home to art and nightlife scenes, the Brandenburg Gate and many sites relating to WWII. Munich is known for its Oktoberfest and beer halls, including the 16th-century Hofbräuhaus. Frankfurt, with its skyscrapers, houses the European Central Bank. ',
				Img: 'germany.png',
				bottomContent: 'Deutschland - Federal Republic of Germany.',
			},
			{
				id: 24,
				country: 'india',
				name: 'India',
				tagline: 'World Coins',
				title: 'Satyameva Jayate',
				description:
					'India, officially the Republic of India (ISO: Bhārat Gaṇarājya), is a country in South Asia. It is the seventh-largest country by area; the most populous country as of June 2023; and from the time of its independence in 1947, the worlds most populous democracy. ',
				Img: 'india.png',
				bottomContent: 'Truth alone triumphs.',
			},
		];

		const cointypeList = [
			{
				id: 1,
				country: 'United Kingdom',
				countryId: 1,
				type: 'crowns',
				name: 'Crown',
				tagline: 'British Coin List',
				title: 'The Crown',
				description:
					'The British crown was a denomination of sterling coinage worth 1⁄4 of one pound, or 5 shillings, or 60 (old) pence.  The crown was first issued during the reign of Edward VI, as part of the coinage of the Kingdom of England.',
				Img: 'crown.jpg',
				bottomContent:
					'The coins origins lie in the English silver crown, one of many silver coins that appeared in various countries from the 16th century onwards (most famously the Spanish piece of eight).',
			},
			{
				id: 2,
				country: 'United Kingdom',
				countryId: 1,
				type: 'halfcrown',
				name: 'Half Crown',
				tagline: 'British Coin List',
				title: 'The Half Crown',
				description:
					'The British half crown was a denomination of sterling coinage worth 1⁄8 of one pound, or two shillings and six pence (abbreviated "2/6", familiarly "two and six"), or 30 pre-decimal pence. The half crown was first issued in 1549, in the reign of Edward VI. No half crowns were issued in the reign of Mary, but from the reign of Elizabeth I half crowns were issued in every reign except that of Edward VIII, until the coins were discontinued in 1970.',
				Img: 'halfcrown.jpg',
				bottomContent: 'halfcrown.',
			},
			{
				id: 3,
				country: 'United Kingdom',
				countryId: 1,
				type: 'florin',
				name: 'Florin',
				tagline: 'British Coin List',
				title: 'The Florin',
				description:
					'The Florentine florin was a gold coin struck from 1252 to 1533 with no significant change in its design or metal content standard during that time. ',
				Img: 'florin.jpg',
				bottomContent: 'florin.',
			},
			{
				id: 4,
				country: 'United Kingdom',
				countryId: 1,
				type: 'Shilling',
				name: 'Shilling',
				tagline: 'British Coin List',
				title: 'The Shilling',
				description:
					'The British shilling, abbreviated "1s" or "1/-", was a unit of currency and a denomination of sterling coinage worth 1⁄20 of one pound, or twelve pence. ',
				Img: 'shilling.jpg',
				bottomContent:
					'First entering circulation during the mid-sixteenth century, the shilling became known as a bob. Its regular appearances in literature, role in military recruitment and use by sailors as a talisman for good luck demonstrate its special place in public affection.',
			},
			{
				id: 5,
				country: 'United Kingdom',
				countryId: 1,
				type: 'sixpence',
				name: 'sixpence',
				tagline: 'British Coin List',
				title: 'The Sixpence',
				description:
					'The British sixpence piece, sometimes known as a tanner or sixpenny bit, was a denomination of sterling coinage worth 1⁄40 of one pound or half of one shilling. It was first minted in 1551, during the reign of Edward VI, and circulated until 1980. The coin was made from silver from its introduction in 1551 until 1947, and thereafter in cupronickel.',
				Img: 'sixpence.jpg',
				bottomContent: 'sixpence.',
			},
			{
				id: 6,
				country: 'United Kingdom',
				countryId: 1,
				type: 'threepence',
				name: 'Threepence',
				tagline: 'British Coin List',
				title: 'The Three Pence',
				description:
					'The British threepence piece, usually simply known as a threepence, thruppence, or thruppenny bit, was a denomination of sterling coinage worth 1⁄80 of one pound or 1⁄4 of one shilling. It was used in the United Kingdom, and earlier in Great Britain and England. Similar denominations were later used throughout the British Empire and Commonwealth countries, notably in Australia, New Zealand and South Africa.',
				Img: 'threepence.jpg',
				bottomContent: 'threepence.',
			},
			{
				id: 7,
				country: 'United Kingdom',
				countryId: 1,
				type: 'penny',
				name: 'Penny',
				tagline: 'British Coin List',
				title: 'The Penny',
				description:
					'The British decimal one penny (1p) coin is a unit of currency and denomination of sterling coinage worth 1⁄100 of one pound.',
				Img: 'penny.jpg',
				bottomContent: 'penny.',
			},
			{
				id: 8,
				country: 'United Kingdom',
				countryId: 1,
				type: 'halfpenny',
				name: 'halfpenny',
				tagline: 'British Coin List',
				title: 'The Half Penny',
				description:
					'The original reverse of the bronze version of the coin, designed by Leonard Charles Wyon, is a seated Britannia, holding a trident, with the words HALF PENNY to either side. Issues before 1895 also feature a lighthouse to Britannias left and a ship to her right. Various minor adjustments to the level of the sea depicted around Britannia, and the angle of her trident were also made over the years. Some issues feature toothed edges, while others feature beading.',
				Img: 'halfpenny.jpeg',
				bottomContent: 'halfpenny.',
			},
			{
				id: 9,
				country: 'United Kingdom',
				countryId: 1,
				type: 'farthing',
				name: 'Farthing',
				tagline: 'British Coin List',
				title: 'The Farthing',
				description:
					'The British farthing (derived from the Old English feorthing, a fourth part) was a British coin worth a quarter of an old penny (1⁄960 of a pound sterling). It ceased to be struck after 1956 and was demonetised from 1 January 1961.',
				Img: 'farthing.jpeg',
				bottomContent: 'farthing.',
			},
			{
				id: 10,
				country: 'United States',
				countryId: 2,
				type: 'morgan',
				name: 'Morgan Dollars',
				tagline: 'U.S Coin List',
				title: 'The Morgan Dollars',
				description:
					'The Morgan dollar, designed by George T. Morgan, was struck intermittently from 1878 to 1921. Since this coins inception it has become one of the most popular silver dollars in America.',
				Img: 'morgan.png',
				bottomContent: 'farthing.',
			},
			{
				id: 11,
				country: 'United Kingdom',
				countryId: 1,
				type: 'maundy',
				name: 'maundy',
				tagline: 'British Coin List',
				title: 'Maundy Coins',
				description:
					'Charles II introduced the custom of Maundy money – coins struck specifically for the service – when he distributed undated hammered coins in 1662. A set consisted of a fourpence (groat), threepence, twopence and one penny piece. By 1670 the King started giving out dated sets, a tradition that continues to this day. The coins were originally the same types as circulating coinage but in the late-18th century they developed into soecially-designed pieces, though they retained their legal tender status. Maundy money no longer circulates due to its silver content and numismatic value. A small sum of ordinary money is also given in lieu of gifts of clothing and food.',
				Img: 'maundy.jpeg',
				bottomContent: 'Maundy Coins.',
			},
			{
				id: 12,
				country: 'United Kingdom',
				countryId: 1,
				type: 'uktokens',
				name: 'uktokens',
				tagline: 'British Coin List',
				title: 'UK Tokens and Medals',
				description:
					'Tokens are coin-like objects, although not always made of metal, that serve as a money substitute. They are not issued by a governmental authority. Often they serve in times of a shortage of coins, and are usually meant for local use for a limited duration. Medals are never used as money.',
				Img: 'uktokens.jpg',
				bottomContent: 'UK Tokens and Medals',
			},
      {
				id: 13,
				country: 'United States',
				countryId: 2,
				type: 'halfdollar',
				name: 'Half Dollars',
				tagline: 'U.S Coin List',
				title: 'The Half Dollars',
				description:
					'The half dollar, sometimes referred to as the half for short or 50-cent piece, is a United States coin worth 50 cents, or one half of a dollar.',
				Img: 'halfdollar.png',
				bottomContent: 'half dollar.',
			},
			{
				id: 14,
				country: 'United States',
				countryId: 2,
				type: 'ustokens',
				name: 'ustokens',
				tagline: 'U.S Coin List',
				title: 'US Tokens and Medals',
				description:
					'Tokens are coin-like objects, although not always made of metal, that serve as a money substitute. They are not issued by a governmental authority. Often they serve in times of a shortage of coins, and are usually meant for local use for a limited duration. Medals are never used as money.',
				Img: 'ustokens.jpeg',
				bottomContent: 'US Tokens and Medals',
			},
			{
				id: 15,
				country: 'United Kingdom',
				countryId: 1,
				type: 'ukhammered',
				name: 'ukhammered',
				tagline: 'UK Coin List',
				title: 'Milled and Hammered Coins',
				description:
					'In numismatics, the term milled coinage (also known as machine-struck coinage) is used to describe coins which are produced by some form of machine, rather than by manually hammering coin blanks between two dies (hammered coinage) or casting coins from dies.',
				Img: 'hammered.jpg',
				bottomContent: 'US Tokens and Medals',
			},
		];

		const coinList = [
      {
        "id": 1,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "crowns",
        "typeid": 1,
        "name": "King Charles II",
        "monarchtype": "Charles2",
        "year": 1676,
        "tagline": "Crown",
        "title": "1676 King Charles II Crown",
        "description": "Charles II (1676) crown 1676 OCTAVO third laureate bust right rev. crowned cruciform shields interlinked Cs in angles (S.3358; ESC.51; Bull 397).",
        "frontImg": "1676f.jpeg",
        "frontTitle": "Charles II",
        "frontDescription": "The bust of King Charles II draped in his flowing hair and laurel leaves.",
        "backImg": "1676b.jpeg",
        "backTitle": "OCTAVO",
        "backDescription": "An original crown from the reign of Charles II .",
        "bottomContent": "An original crown from the reign of Charles II .",
        "price": "$800.00",
        "ebay": ""
      },
      {
        "id": 2,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "crowns",
        "typeid": 1,
        "name": "King James II",
        "monarchtype": "James2",
        "year": 1687,
        "tagline": "Crown",
        "title": "1687 King James II Crown",
        "description": "James II Great Britain Crown England Silver Coin. Struck for King James II at the Tower Mint. James had a short reign and abdicated during the Glorious Revolution which established William & Mary as joint monarchs. He was the last English Catholic monarch.",
        "frontImg": "1687f.jpg",
        "frontTitle": "IACOBVS II DEI GRATIA",
        "frontDescription": "The second bust covered a 1687 Tertio edge.",
        "backImg": "1687b.jpg",
        "backTitle": "Cruciform",
        "backDescription": "Qvarto edge Crowned Cruciform shields.",
        "bottomContent": "Qvarto edge, Crowned Cruciform shields.",
        "price": "$5,400.00",
        "ebay": ""
      },
      {
        "id": 3,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "crowns",
        "typeid": 1,
        "name": "King William III",
        "monarchtype": "William3",
        "year": 1696,
        "tagline": "Crown",
        "title": "1696 King William III Crown",
        "description": "A collectable silver Crown of king William the Third of the first bust issue series dated 1696 with the regnal year edge of OCTAVO.",
        "frontImg": "1693f.jpg",
        "frontTitle": "William III",
        "frontDescription": "1696 Great Britain William III Silver Crown 1st Bust",
        "backImg": "1696b.jpg",
        "backTitle": "English silver Crown",
        "backDescription": "First harp in left shield",
        "bottomContent": "First harp in left shield",
        "price": "$800.00",
        "ebay": ""
      },
      {
        "id": 4,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "crowns",
        "typeid": 1,
        "name": "Queen Anne",
        "monarchtype": "Anne",
        "year": 1707,
        "tagline": "Crown",
        "title": "1707 Queen Anne Crown",
        "description": "A rare 1707 Queen Anne silver crown; Struck in the year which saw the union of England and Scotland; A coin that has historical significance which merged the kingdoms of Scotland and England.  Draped 'Edinburgh' bust of Queen Anne with rare 'E' mint mark",
        "frontImg": "1707f.jpg",
        "frontTitle": "ANNA 1707",
        "frontDescription": "ANNA DEI GRATIA",
        "backImg": "1707b.jpg",
        "backTitle": "Anne",
        "backDescription": "Crowned shields cruciform six strings to Irish harp.",
        "bottomContent": "Crowned shields cruciform, six strings to Irish harp.",
        "price": "$750.00",
        "ebay": ""
      },
      {
        "id": 5,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "crowns",
        "typeid": 1,
        "name": "King George III",
        "monarchtype": "George3",
        "year": 1819,
        "tagline": "Crown",
        "title": "1819 King George III Crown",
        "description": "A rare original George III crown Both the obverse and reverse are by Benedetto Pistrucci. One of the first coins to feature the iconic St George and the dragon design.",
        "frontImg": "1819f.jpg",
        "frontTitle": "George III crown",
        "frontDescription": "Shame on he who thinks evil of it.",
        "backImg": "1819b.jpg",
        "backTitle": "Benedetto Pistrucci",
        "backDescription": "One of the first coins to feature the iconic St George and the dragon design.",
        "bottomContent": "One of the first coins to feature the iconic St George and the dragon design.",
        "price": "$400.00",
        "ebay": ""
      },
      {
        "id": 6,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "crowns",
        "typeid": 1,
        "name": "King George III",
        "monarchtype": "George3",
        "year": 1820,
        "tagline": "Crown",
        "title": "1820 King George III Crown",
        "description": "George III (1760-1820) silver Crown 1820 LX Mintage of 448000",
        "frontImg": "1820f.jpg",
        "frontTitle": "King George III",
        "frontDescription": "Shows a laureate head portrait of George II by Benedetto Pistrucci.",
        "backImg": "1820b.jpg",
        "backTitle": "LX Edge Year",
        "backDescription": "Shame on him who thinks evil of it.",
        "bottomContent": "GEORGE",
        "price": "$600.00",
        "ebay": ""
      },
      {
        "id": 7,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "crowns",
        "typeid": 1,
        "name": "King George IV",
        "monarchtype": "George4",
        "year": 1822,
        "tagline": "Crown",
        "title": "1822 King George IV Crown",
        "description": "The inscription reads Georgius IIII. George IV was the last British King to be shown wearing the Roman Style Laurel Wreath.",
        "frontImg": "1822f.jpg",
        "frontTitle": "SECUNDO",
        "frontDescription": "Head of king George IV facing left.",
        "backImg": "1822b.jpg",
        "backTitle": "Saint George and the Dragon",
        "backDescription": "Reverse by the legendary Benedetto Pistrucc.",
        "bottomContent": "reverse by the legendary Benedetto Pistrucc.",
        "price": "$500.00",
        "ebay": ""
      },
      {
        "id": 8,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "crowns",
        "typeid": 1,
        "name": "Queen Victoria",
        "monarchtype": "Victoria",
        "year": 1844,
        "tagline": "Crown",
        "title": "1844 Queen Victoria Crown",
        "description": "Victoria 1844 Crown young head cinquefoil stops on edge. The legend on the edge translates as An ornament and a safeguard in the eighth year of her reign.",
        "frontImg": "1844f.jpg",
        "frontTitle": "Victoria Young Head",
        "frontDescription": "VICTORIA DEI GRATIA",
        "backImg": "1844b.jpg",
        "backTitle": "BRITANNIARUM REGIBA FID: DEF",
        "backDescription": "Crowned arms within branches",
        "bottomContent": "Crowned arms within branches",
        "price": "$200.00",
        "ebay": ""
      },
      {
        "id": 9,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "crowns",
        "typeid": 1,
        "name": "Queen Victoria",
        "monarchtype": "Victoria",
        "year": 1847,
        "tagline": "Crown",
        "title": "1847 The Gothic Crown Crown",
        "description": "Just 8000 Gothic crowns were used in 1847 and it remains one of the most beautiful and rarest coins in the world.",
        "frontImg": "1847f.jpg",
        "frontTitle": "Gothic Portrait",
        "frontDescription": "A sought after 1847 silver Crown of Queen Victoria. This issue is known as the Gothic Crown because its style was inspired by medieval revivalism a popular art style of that looked to the middle ages for inspiration. The ornate obverse portrait shows Victoria wearing an ornate dress and crown a plait looping around her ear.",
        "backImg": "1847b.jpg",
        "backTitle": "UNDECIMO",
        "backDescription": "The reverse though the crowned cruciform shields motif is the work of Scottish artist William Wyon.",
        "bottomContent": "Just 8,000 Gothic crowns were used in 1847 and it remains one of the most beautiful and rarest coins in the world.",
        "price": "$31,500.00",
        "ebay": ""
      },
      {
        "id": 10,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "crowns",
        "typeid": 1,
        "name": "Queen Victoria",
        "monarchtype": "Victoria",
        "year": 1892,
        "tagline": "Crown",
        "title": "1892 Queen Victoria Crown",
        "description": "The Queen Victoria Jubilee Head Silver Crown.  designed by medallist Sir Joseph Edgar Boehm to celebrate 50 years of Victorias reign. Benedetto Pistruccis St George and the Dragon masterpiece is on the reverse.",
        "frontImg": "1892f.jpg",
        "frontTitle": "Victoria Jubilee Head",
        "frontDescription": "UK crown obverse features the robed jubilee bust of the mature Queen Victoria",
        "backImg": "1892b.jpg",
        "backTitle": "Victoria",
        "backDescription": "Featuring the traditional George and Dragon",
        "bottomContent": "Victoria",
        "price": "$200.00",
        "ebay": ""
      },
      {
        "id": 11,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "crowns",
        "typeid": 1,
        "name": "Queen Victoria",
        "monarchtype": "Victoria",
        "year": 1896,
        "tagline": "Crown",
        "title": "1896 Queen Victoria Crown",
        "description": "The 1896 LIX crown is the rarest crown in the Victoria veiled head series.  Queen Victoria's 60th (LX) regnal year started at 20 June 1896.",
        "frontImg": "1896f.jpg",
        "frontTitle": "Victoria",
        "frontDescription": "Victoria Old (Veiled) head.",
        "backImg": "1896b.jpg",
        "backTitle": "Victoria Crown LX",
        "backDescription": "Featuring the traditional George and Dragon",
        "bottomContent": "Featuring the traditional George and Dragon",
        "price": "$800.00",
        "ebay": ""
      },
      {
        "id": 12,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "crowns",
        "typeid": 1,
        "name": "King Edward VII",
        "monarchtype": "Edward7",
        "year": 1902,
        "tagline": "Crown",
        "title": "1902 King Edward VII Crown",
        "description": "An original Edward VII crown; Dated 1902 the only year of issue; Offered for sale in a minimum of Fine condition.",
        "frontImg": "1902f.jpg",
        "frontTitle": "King Edward",
        "frontDescription": "Seventh facing left",
        "backImg": "1902b.jpg",
        "backTitle": "St George",
        "backDescription": "Coronation day was set for 26th June 1902",
        "bottomContent": "EDWARD",
        "price": "$400.00",
        "ebay": ""
      },
      {
        "id": 13,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "crowns",
        "typeid": 1,
        "name": "King George V",
        "monarchtype": "George5",
        "year": 1928,
        "tagline": "Crown",
        "title": "1928 King George V Crown",
        "description": "This is the 1928 Silver Crown of George V. The Royal Mint struck the first of a new series of crowns known as the Christmas crowns as they displayed the Christmas wreath on the reverse. This coin was made of silver has a weight of 28.28g and a diameter of 38.60mm.",
        "frontImg": "1928f.jpg",
        "frontTitle": "George V",
        "frontDescription": "The initials BM appear on the truncation of George V neck for the artist: Bertram MacKennal.",
        "backImg": "1928b.jpg",
        "backTitle": "Wreath Crown",
        "backDescription": "A large royal crown surrounded by a wreath of shamrocks with roses and thistles.",
        "bottomContent": "GEORGE",
        "price": "$400.00",
        "ebay": ""
      },
      {
        "id": 14,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "crowns",
        "typeid": 1,
        "name": "King George V",
        "monarchtype": "George5",
        "year": 1935,
        "tagline": "Crown",
        "title": "1935 King George V Crown",
        "description": "This is the 1935 Silver Crown of George V.  The Royal Mint in 1935 to mark King George V\u001as Silver Jubilee. Struck in Solid Silver. This coin was made of silver has a weight of 28.28g and a diameter of 38.60mm.",
        "frontImg": "1935f.jpg",
        "frontTitle": "Jubilee Crown",
        "frontDescription": "GEORGE",
        "backImg": "1935b.jpg",
        "backTitle": "Rocking Horse",
        "backDescription": "Stylised St George and dragon.",
        "bottomContent": "GEORGE",
        "price": "$300.00",
        "ebay": ""
      },
      {
        "id": 15,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "crowns",
        "typeid": 1,
        "name": "King George VI",
        "monarchtype": "George6",
        "year": 1937,
        "tagline": "Crown",
        "title": "1937 King George VI Crown",
        "description": "1937 George VI Silver Crown Struck for the Coronation year of King George VI in 1937",
        "frontImg": "1937f.jpg",
        "frontTitle": "GEORGE VI",
        "frontDescription": "The Coronation year of King George VI in 1937",
        "backImg": "1937b.jpg",
        "backTitle": "Lion and unicorn",
        "backDescription": "The engravers initials K.G. appear at either side of the shield between the animals legs.",
        "bottomContent": "The engravers initials K.G. appear at either side of the shield, between the animals legs.",
        "price": "$150.00",
        "ebay": ""
      },
      {
        "id": 17,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "crowns",
        "typeid": 1,
        "name": "Queen Elizabeth II",
        "monarchtype": "Elizabeth2",
        "year": 1953,
        "tagline": "Crown",
        "title": "1953 Queen Elizabeth II Crown",
        "description": "1953 Elizabeth II 5 Shilling Commemorative Crown Features a portrait of The Queen mounted on her horse Winston",
        "frontImg": "1953f.jpg",
        "frontTitle": "Queen Elizabeth",
        "frontDescription": "1953 Great Britain 5 Shillings",
        "backImg": "1953b.jpg",
        "backTitle": "Crowned Queen",
        "backDescription": "The coronation of Queen Elizabeth II",
        "bottomContent": "The coronation of Queen Elizabeth II",
        "price": "$40.00",
        "ebay": ""
      },
      {
        "id": 18,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "crowns",
        "typeid": 1,
        "name": "Queen Elizabeth II",
        "monarchtype": "Elizabeth2",
        "year": 1960,
        "tagline": "Crown",
        "title": "1960 Queen Elizabeth II Crown",
        "description": "1960 Elizabeth II Crown - Young Head Variant British Exhibition in New York",
        "frontImg": "1960f.jpg",
        "frontTitle": "Elizabeth II",
        "frontDescription": "In 1960 the Royal Mint issued a Special Crown for the British Exhibition in New York.",
        "backImg": "1960b.jpg",
        "backTitle": "Four shields",
        "backDescription": "DEI GRATIA REGINA F D. FIVE SHILLINGS",
        "bottomContent": "DEI GRATIA REGINA F D. FIVE SHILLINGS",
        "price": "$40.00",
        "ebay": ""
      },
      {
        "id": 19,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "crowns",
        "typeid": 1,
        "name": "Queen Elizabeth II",
        "monarchtype": "Elizabeth2",
        "year": 1965,
        "tagline": "Crown",
        "title": "1965 Queen Elizabeth II Crown",
        "description": "1965 Queen Elizabeth II Churchill Crown.  The first time a non-monarch had appeared on a coin of the United Kingdom",
        "frontImg": "1965f.jpg",
        "frontTitle": "Elizabeth II",
        "frontDescription": "1965 crown to commemorate the death of Sir Winston Churchill",
        "backImg": "1977b.jpg",
        "backTitle": "Churchill",
        "backDescription": "Portrait of Churchill.",
        "bottomContent": "Portrait of Churchill.",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 20,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "crowns",
        "typeid": 1,
        "name": "Queen Elizabeth II",
        "monarchtype": "Elizabeth2",
        "year": 1977,
        "tagline": "Crown",
        "title": "1977 Queen Elizabeth II Crown",
        "description": "Silver Jubilee Crowns were minted in 1977 to celebrate the Silver Jubilee of Her Majesty Queen Elizabeth II.",
        "frontImg": "1977f.jpg",
        "frontTitle": "horseback",
        "frontDescription": "THE JUBILEE EQUESTRIAN PORTRAIT",
        "backImg": "1977b.jpg",
        "backTitle": "Jubilee",
        "backDescription": "Silver Jubilee of Her Majesty Queen Elizabeth II.",
        "bottomContent": "Silver Jubilee of Her Majesty, Queen Elizabeth II.",
        "price": "$40.00",
        "ebay": ""
      },
      {
        "id": 21,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "crowns",
        "typeid": 1,
        "name": "King Charles III",
        "monarchtype": "Charles3",
        "year": 2023,
        "tagline": "Crown",
        "title": "2023 King Charles III Crown",
        "description": "2023 King Charles III Coronation Two Ounce Silver Proof Coin.  limited edition of just 1000 pieces this beautiful two-ounce silver proof coin was produced by The Royal Mint to celebrate the coronation of King Charles III.",
        "frontImg": "2023f.jpg",
        "frontTitle": "Charles III",
        "frontDescription": "Struck to celebrate the coronation of His Majesty The King.",
        "backImg": "2023b.jpg",
        "backTitle": "Martin Jennings",
        "backDescription": "The Royal Arms",
        "bottomContent": "The Royal Arms",
        "price": "$350.00",
        "ebay": ""
      },
      {
        "id": 22,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "crowns",
        "typeid": 1,
        "name": "King Charles III",
        "monarchtype": "Charles3",
        "year": 2024,
        "tagline": "Crown",
        "title": "2024 King Charles III Crown",
        "description": "2024 UK 2 Pound King Charles III Silver Britannia 1 oz .999 BU",
        "frontImg": "2024f.jpg",
        "frontTitle": "Charles III",
        "frontDescription": "Design from Martin Jennings",
        "backImg": "2024b.jpg",
        "backTitle": "Britannia",
        "backDescription": "Great Britain Silver Britannia.",
        "bottomContent": "Great Britain Silver Britannia.",
        "price": "$50.00",
        "ebay": ""
      },
      {
        "id": 23,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "halfcrown",
        "typeid": 2,
        "name": "William & Mary",
        "monarchtype": "WilliamAndMary",
        "year": 1689,
        "tagline": "Half Crowns",
        "title": "1689 William & Mary Half Crowns",
        "description": "The Latin legends translate as on the obverse \"William and Mary by the Grace of God\" and abbreviated on the reverse as \"King and Queen of Great Britain France and Ireland.\" and additionally on the edge \"An ornament and a safeguard in the first year of the reign.",
        "frontImg": "1689f.jpg",
        "frontTitle": "William & Mary",
        "frontDescription": "Very Fine William & Mary Great Britain silver 1/2 crown coin.",
        "backImg": "1689b.jpg",
        "backTitle": "Grace of God",
        "backDescription": "Very attractive original coin.",
        "bottomContent": "William & Mary",
        "price": "$500.00",
        "ebay": ""
      },
      {
        "id": 24,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "halfcrown",
        "typeid": 2,
        "name": "King William III",
        "monarchtype": "William3",
        "year": 1696,
        "tagline": "Half Crowns",
        "title": "1696 King William III  Half Crowns",
        "description": "The Latin legends translate as on the obverse \"William III by the Grace of God\" and abbreviated on the reverse as King of Great Britain France and Ireland. and additionally on the edge An ornament and a safeguard in the eighth year of the reign.",
        "frontImg": "1696f.jpg",
        "frontTitle": "GVLIELMVS· III·DEI·GRA",
        "frontDescription": "First laureate and draped bust of King William III right legend around.",
        "backImg": "1696b.jpg",
        "backTitle": "William III",
        "backDescription": "Crowned cruciform shields around cental Nassau lion divided date above legend around.",
        "bottomContent": "William III",
        "price": "$500.00",
        "ebay": ""
      },
      {
        "id": 25,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "halfcrown",
        "typeid": 2,
        "name": "Queen Anne",
        "monarchtype": "Anne",
        "year": 1714,
        "tagline": "Half Crowns",
        "title": "1714 Queen Anne  Half Crowns",
        "description": "1714 Queen Anne Early Milled Silver Half Crown Roses and Plumes",
        "frontImg": "1714f.jpg",
        "frontTitle": "ANNA·DEI· GRATIA",
        "frontDescription": "Anne by the Grace of God",
        "backImg": "1714b.jpg",
        "backTitle": "Queen Anne",
        "backDescription": "Crowned cruciform shields with central Garter star divided date above legend around.",
        "bottomContent": "Queen Anne",
        "price": "$400.00",
        "ebay": ""
      },
      {
        "id": 26,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "halfcrown",
        "typeid": 2,
        "name": "King George II",
        "monarchtype": "George2",
        "year": 1745,
        "tagline": "Half Crowns",
        "title": "1745 King George II Half Crowns",
        "description": "Great Britain under KING GEORGE II: 1745 - LIMA. 1745 Silver Half-Crown..  War Of Jenkins Ear Cost Spain A Million Pieces Of Eight.",
        "frontImg": "1745f.jpg",
        "frontTitle": "GEORGIUS·II· DEI·GRATIA",
        "frontDescription": "George the Second by the Grace of God",
        "backImg": "1745b.jpg",
        "backTitle": "George II",
        "backDescription": "Crowned cruciform shields with central Garter star divided date above legend around.",
        "bottomContent": "George II",
        "price": "$550.00",
        "ebay": ""
      },
      {
        "id": 27,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "halfcrown",
        "typeid": 2,
        "name": "King George III",
        "monarchtype": "George3",
        "year": 1817,
        "tagline": "Half Crowns",
        "title": "1817 King George III Half Crowns",
        "description": "1817 King George III Silver Halfcrown Coin Pistrucci Small Laureate Head.",
        "frontImg": "1817f.jpg",
        "frontTitle": "George III",
        "frontDescription": "GEORGIUS III DEI GRATIA",
        "backImg": "1817b.jpg",
        "backTitle": "BRITANNIARUM",
        "backDescription": "Crowned and quartered ornate shield",
        "bottomContent": "George III",
        "price": "$220.00",
        "ebay": ""
      },
      {
        "id": 28,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "halfcrown",
        "typeid": 2,
        "name": "King George III",
        "monarchtype": "George3",
        "year": 1819,
        "tagline": "Half Crowns",
        "title": "1819 King George III Half Crowns",
        "description": "1819 George III Half-crown Dated with the year of Queen Victoria's birth; An original George III half-crown; A circulating coin of the era used in everyday transactions",
        "frontImg": "1819f.jpg",
        "frontTitle": "George III",
        "frontDescription": "GEORGIUS III DEI GRATIA",
        "backImg": "1819b.jpg",
        "backTitle": "BRITANNIARUM",
        "backDescription": "Crowned and quartered ornate shield",
        "bottomContent": "George III",
        "price": "$300.00",
        "ebay": ""
      },
      {
        "id": 29,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "halfcrown",
        "typeid": 2,
        "name": "King George IV",
        "monarchtype": "George4",
        "year": 1820,
        "tagline": "Half Crowns",
        "title": "1820 King George IV Half Crowns",
        "description": "1820 King George IV Half Crowns This 1820 half crown was issued during the reign of King George III. The coin features the small portrait of George on the obverse with the crowned shield on the reverse.",
        "frontImg": "1820f.jpg",
        "frontTitle": "GEORGIUS",
        "frontDescription": "George the Fourth by the Grace of God",
        "backImg": "1820b.jpg",
        "backTitle": "BRITANNIARUM",
        "backDescription": "King of the Britains Defender of the FaithGod and my right",
        "bottomContent": "George IIII",
        "price": "$300.00",
        "ebay": ""
      },
      {
        "id": 30,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "halfcrown",
        "typeid": 2,
        "name": "King George IV",
        "monarchtype": "George4",
        "year": 1826,
        "tagline": "Half Crowns",
        "title": "1826 King George IV Half Crowns",
        "description": "1826 George IV Milled Silver Half Crown A/EF. Light toning over underlying lustre",
        "frontImg": "1826f.jpg",
        "frontTitle": "GEORGIUS",
        "frontDescription": "George the Fourth by the Grace of God",
        "backImg": "1826b.jpg",
        "backTitle": "BRITANNIARUM",
        "backDescription": "King of the Britains Defender of the FaithGod and my right",
        "bottomContent": "George IV",
        "price": "$300.00",
        "ebay": ""
      },
      {
        "id": 31,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "halfcrown",
        "typeid": 2,
        "name": "King William IIII",
        "monarchtype": "William4",
        "year": 1834,
        "tagline": "Half Crowns",
        "title": "1834 King William IIII  Half Crowns",
        "description": "WILLIAM IV 1834 HALF-CROWN .  The half crowns were the highest denomination issued for William IV",
        "frontImg": "1834f.jpg",
        "frontTitle": "GEORGIUS",
        "frontDescription": "George the Fourth by the Grace of God",
        "backImg": "1834b.jpg",
        "backTitle": "King William IIII",
        "backDescription": "King of the Britains Defender of the FaithGod and my right",
        "bottomContent": "King William IIII",
        "price": "$400.00",
        "ebay": ""
      },
      {
        "id": 32,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "halfcrown",
        "typeid": 2,
        "name": "Queen Victoria",
        "monarchtype": "Victoria",
        "year": 1883,
        "tagline": "Half Crowns",
        "title": "1883 Queen Victoria Half Crowns",
        "description": "1883 Queen Victoria Half Crown (2/6d) Silver (92.5%) Coin.",
        "frontImg": "1883f.jpg",
        "frontTitle": "VICTORIA DEI GRATIA",
        "frontDescription": "Young uncrowned portrait (Young Head) of Queen Victoria",
        "backImg": "1883b.jpg",
        "backTitle": "BRITANNIARUM",
        "backDescription": "Crowned quartered shield of arms within olive wreath legend around national flower emblems below.",
        "bottomContent": "Queen Victoria",
        "price": "$300.00",
        "ebay": ""
      },
      {
        "id": 33,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "halfcrown",
        "typeid": 2,
        "name": "Queen Victoria",
        "monarchtype": "Victoria",
        "year": 1887,
        "tagline": "Half Crowns",
        "title": "1887 Queen Victoria Half Crowns",
        "description": "1887 Victoria Halfcrown (Jubilee Head) 1887-1892 VG",
        "frontImg": "1887f.jpg",
        "frontTitle": "VICTORIA DEI GRATIA",
        "frontDescription": "Crowned and veiled bust (Jubilee Head) of Queen Victoria",
        "backImg": "1887b.jpg",
        "backTitle": "Queen Victoria",
        "backDescription": "Crowned quartered shield of arms within garter and collar legend around divided date below.",
        "bottomContent": "Queen Victoria",
        "price": "$150.00",
        "ebay": ""
      },
      {
        "id": 34,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "halfcrown",
        "typeid": 2,
        "name": "Queen Victoria",
        "monarchtype": "Victoria",
        "year": 1893,
        "tagline": "Half Crowns",
        "title": "1893 Queen Victoria Half Crowns",
        "description": "1893 Victoria Half Crown The 1893 United Kingdom halfcrown reverse shows a crowned shield inside a floral design.",
        "frontImg": "1893f.jpg",
        "frontTitle": "Queen Victoria",
        "frontDescription": "Victoria Old Veiled Head Crown",
        "backImg": "1893b.jpg",
        "backTitle": "Victorian crown",
        "backDescription": "Silver Half Crown",
        "bottomContent": "Queen Victoria",
        "price": "$75.00",
        "ebay": ""
      },
      {
        "id": 35,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "halfcrown",
        "typeid": 2,
        "name": "King Edward VII",
        "monarchtype": "Edward7",
        "year": 1902,
        "tagline": "Half Crowns",
        "title": "1902 King Edward VII Half Crowns",
        "description": "1902 King Edward VII Half Crowns Silver Half Crown coins of Edward VII with the portrait of the King on the obverse and crowned quartered shield of arms on the reverse.",
        "frontImg": "1902f.jpg",
        "frontTitle": "Edward VII",
        "frontDescription": "Uncrowned portrait of King Edward VII",
        "backImg": "1902b.jpg",
        "backTitle": "Edwardus VII",
        "backDescription": "Crowned quartered shield of arms within Garter legend around.",
        "bottomContent": "Edward VII",
        "price": "$200.00",
        "ebay": ""
      },
      {
        "id": 36,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "halfcrown",
        "typeid": 2,
        "name": "King George V",
        "monarchtype": "George5",
        "year": 1911,
        "tagline": "Half Crowns",
        "title": "1911 King George V Half Crowns",
        "description": "This 1911 Half Crown from Great Britain features King George V and is made of 92.5% silver.",
        "frontImg": "1911f.jpg",
        "frontTitle": "GEORGIVS V",
        "frontDescription": "George the Fifth by the Grace of God King of all the Britains",
        "backImg": "1911b.jpg",
        "backTitle": "George V",
        "backDescription": "Crowned quartered shield of arms within Garter band legend around.",
        "bottomContent": "George V",
        "price": "$150.00",
        "ebay": ""
      },
      {
        "id": 37,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "halfcrown",
        "typeid": 2,
        "name": "King George V",
        "monarchtype": "George5",
        "year": 1935,
        "tagline": "Half Crowns",
        "title": "1935 King George V Half Crowns",
        "description": "1935 Year UK Half Crown Coins ; 1935 United Kingdom 1 Crown - .500 Silver - 38.6mm",
        "frontImg": "1935f.jpg",
        "frontTitle": "GEORGIVS V",
        "frontDescription": "George the Fifth by the Grace of God King of all the Britains",
        "backImg": "1935b.jpg",
        "backTitle": "George V",
        "backDescription": "Crowned quartered shield of arms within Garter band legend around.",
        "bottomContent": "George V",
        "price": "$150.00",
        "ebay": ""
      },
      {
        "id": 38,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "halfcrown",
        "typeid": 2,
        "name": "King George VI",
        "monarchtype": "George6",
        "year": 1940,
        "tagline": "Half Crowns",
        "title": "1940 King George VI Half Crowns",
        "description": "1940 Great Britain 1/2 Crown Silver Coin George VI Half Crown.",
        "frontImg": "1940f.jpg",
        "frontTitle": "GEORGIVS VI",
        "frontDescription": "George the Sixth by the Grace of God King of all the Britains",
        "backImg": "1940b.jpg",
        "backTitle": "Pound sterling",
        "backDescription": "Quartered shield of arms flanked by crowned royal cyphers legend above denomination.",
        "bottomContent": "George VI",
        "price": "$100.00",
        "ebay": ""
      },
      {
        "id": 39,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "shilling",
        "typeid": 4,
        "name": "Queen Elizabeth II",
        "monarchtype": "Elizabeth2",
        "year": 1970,
        "tagline": "Shilling",
        "title": "1970 Queen Elizabeth II Shilling",
        "description": "1970 Great Britain One Shilling.  The coin is made from Cupro-Nickel weighs approx 11.5 grammes and is 28.5mm in diameter the edge is milled.",
        "frontImg": "1970f.jpg",
        "frontTitle": "Queen ELIZABETH II",
        "frontDescription": "Young laureate bust of Queen Elizabeth II",
        "backImg": "1970b.jpg",
        "backTitle": "Shillings",
        "backDescription": "Central Tudor rose surrounded by thistles shamrocks and leeks legend above denomination.",
        "bottomContent": "Queen ELIZABETH II",
        "price": "$20.00",
        "ebay": ""
      },
      {
        "id": 40,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "shilling",
        "typeid": 4,
        "name": "Queen Elizabeth II",
        "monarchtype": "Elizabeth2",
        "year": 1964,
        "tagline": "Shilling",
        "title": "1964 Queen Elizabeth II Shilling",
        "description": "The 1964 Queen Elizabeth II Scottish Shilling is also available in an English version and was worth twelve pennies in the pre-decimal coin system.",
        "frontImg": "1964f.jpg",
        "frontTitle": "Queen ELIZABETH II",
        "frontDescription": "Young laureate bust of Queen Elizabeth II",
        "backImg": "1964b.jpg",
        "backTitle": "Shillings",
        "backDescription": "Central Tudor rose surrounded by thistles shamrocks and leeks legend above denomination.",
        "bottomContent": "Queen ELIZABETH II",
        "price": "$20.00",
        "ebay": ""
      },
      {
        "id": 41,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "shilling",
        "typeid": 4,
        "name": "Queen Elizabeth II",
        "monarchtype": "Elizabeth2",
        "year": 1956,
        "tagline": "Shilling",
        "title": "1956 Queen Elizabeth II Shilling",
        "description": "The 1956 Queen Elizabeth II English Shilling is also available in an Scottish version and was worth twelve pennies in the pre-decimal coin system.",
        "frontImg": "1956f.jpg",
        "frontTitle": "Queen ELIZABETH II",
        "frontDescription": "Young laureate bust of Queen Elizabeth II",
        "backImg": "1956b.jpg",
        "backTitle": "Shillings",
        "backDescription": "Central Tudor rose surrounded by thistles shamrocks and leeks legend above denomination.",
        "bottomContent": "Queen ELIZABETH II",
        "price": "$20.00",
        "ebay": ""
      },
      {
        "id": 42,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "shilling",
        "typeid": 4,
        "name": "Queen Elizabeth II",
        "monarchtype": "Elizabeth2",
        "year": 1955,
        "tagline": "Shilling",
        "title": "1955 Queen Elizabeth II Shilling",
        "description": "1955 Scottish Shilling Coin Great Britain From Queen Elizabeth 2nd",
        "frontImg": "1955f.jpg",
        "frontTitle": "Queen ELIZABETH II",
        "frontDescription": "Young laureate bust of Queen Elizabeth II",
        "backImg": "1955b.jpg",
        "backTitle": "Shillings",
        "backDescription": "Central Tudor rose surrounded by thistles shamrocks and leeks legend above denomination.",
        "bottomContent": "Queen ELIZABETH II",
        "price": "$20.00",
        "ebay": ""
      },
      {
        "id": 43,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "shilling",
        "typeid": 4,
        "name": "King George VI",
        "monarchtype": "George6",
        "year": 1950,
        "tagline": "Shilling",
        "title": "1950 King George VI Shilling",
        "description": "Great Britain 1950 - 1 Shilling Copper-Nickel Coin - King George VI - English ... 1950 ONE SHILLING",
        "frontImg": "1950f.jpg",
        "frontTitle": "George VI",
        "frontDescription": "Uncrowned portrait of King George VI left",
        "backImg": "1950b.jpg",
        "backTitle": "Shillings",
        "backDescription": "Crowned lion passant atop crown",
        "bottomContent": "George VI",
        "price": "$20.00",
        "ebay": ""
      },
      {
        "id": 44,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "shilling",
        "typeid": 4,
        "name": "King George VI",
        "monarchtype": "George6",
        "year": 1949,
        "tagline": "Shilling",
        "title": "1949 King George VI Shilling",
        "description": "1949 GREAT BRITAIN KING GEORGE VI ONE SHILLING COPPER-NICKEL COIN",
        "frontImg": "1949f.jpg",
        "frontTitle": "George VI",
        "frontDescription": "Uncrowned portrait of King George VI left",
        "backImg": "1949b.jpg",
        "backTitle": "Shillings",
        "backDescription": "Crowned lion passant atop crown",
        "bottomContent": "George VI",
        "price": "$20.00",
        "ebay": ""
      },
      {
        "id": 45,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "shilling",
        "typeid": 4,
        "name": "King George VI",
        "monarchtype": "George6",
        "year": 1945,
        "tagline": "Shilling",
        "title": "1945 King George VI Shilling",
        "description": "1945 GREAT BRITAIN KING GEORGE VI ONE SHILLING",
        "frontImg": "1945f.jpg",
        "frontTitle": "George VI",
        "frontDescription": "Uncrowned portrait of King George VI left",
        "backImg": "1945b.jpg",
        "backTitle": "Shillings",
        "backDescription": "Crowned lion passant atop crown",
        "bottomContent": "George VI",
        "price": "$20.00",
        "ebay": ""
      },
      {
        "id": 46,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "shilling",
        "typeid": 4,
        "name": "King George VI",
        "monarchtype": "George6",
        "year": 1943,
        "tagline": "Shilling",
        "title": "1943 King George VI Shilling",
        "description": "1943 GREAT BRITAIN KING GEORGE VI ONE SHILLING",
        "frontImg": "1943f.jpg",
        "frontTitle": "George VI",
        "frontDescription": "Uncrowned portrait of King George VI left",
        "backImg": "1943b.jpg",
        "backTitle": "Shillings",
        "backDescription": "Crowned lion passant atop crown",
        "bottomContent": "George VI",
        "price": "$20.00",
        "ebay": ""
      },
      {
        "id": 47,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "shilling",
        "typeid": 4,
        "name": "King George VI",
        "monarchtype": "George6",
        "year": 1938,
        "tagline": "Shilling",
        "title": "1938 King George VI Shilling",
        "description": "1938 GREAT BRITAIN KING GEORGE VI ONE SHILLING",
        "frontImg": "1938f.jpg",
        "frontTitle": "George VI",
        "frontDescription": "Uncrowned portrait of King George VI left",
        "backImg": "1938b.jpg",
        "backTitle": "Shillings",
        "backDescription": "Crowned lion passant atop crown",
        "bottomContent": "George VI",
        "price": "$20.00",
        "ebay": ""
      },
      {
        "id": 48,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "shilling",
        "typeid": 4,
        "name": "King George V",
        "monarchtype": "George5",
        "year": 1935,
        "tagline": "Shilling",
        "title": "1935 King George V Shilling",
        "description": "1935 Great Britain British England King George V SILVER shilling coin.",
        "frontImg": "1935f.jpg",
        "frontTitle": "GEORGIVS V",
        "frontDescription": "Uncrowned portrait of King George VI left",
        "backImg": "1935b.jpg",
        "backTitle": "Grace of God King",
        "backDescription": "Crowned lion passant atop crown legend above",
        "bottomContent": "George V",
        "price": "$20.00",
        "ebay": ""
      },
      {
        "id": 49,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "shilling",
        "typeid": 4,
        "name": "King George V",
        "monarchtype": "George5",
        "year": 1914,
        "tagline": "Shilling",
        "title": "1914 King George V Shilling",
        "description": "1914 Great Britain British England King George V SILVER shilling coin.",
        "frontImg": "1914f.jpg",
        "frontTitle": "GEORGIVS V",
        "frontDescription": "Uncrowned portrait of King George VI left",
        "backImg": "1914b.jpg",
        "backTitle": "Grace of God King",
        "backDescription": "Crowned lion passant atop crown legend above",
        "bottomContent": "George V",
        "price": "$20.00",
        "ebay": ""
      },
      {
        "id": 50,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "shilling",
        "typeid": 4,
        "name": "King George V",
        "monarchtype": "George5",
        "year": 1911,
        "tagline": "Shilling",
        "title": "1911 King George V Shilling",
        "description": "1911 Great Britain British England King George V SILVER shilling coin.",
        "frontImg": "1911f.jpg",
        "frontTitle": "GEORGIVS V",
        "frontDescription": "Uncrowned portrait of King George VI left",
        "backImg": "1911b.jpg",
        "backTitle": "Grace of God King",
        "backDescription": "Crowned lion passant atop crown legend above",
        "bottomContent": "George V",
        "price": "$20.00",
        "ebay": ""
      },
      {
        "id": 51,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "shilling",
        "typeid": 4,
        "name": "King Edward VII",
        "monarchtype": "Edward7",
        "year": 1910,
        "tagline": "Shilling",
        "title": "1910 King Edward VII Shilling",
        "description": "1910 Great Britain 3 Three pence King Edwards VII (.925) Silver Coin.",
        "frontImg": "1910f.jpg",
        "frontTitle": "Edward VII",
        "frontDescription": "Uncrowned portrait of King Edward VII",
        "backImg": "1910b.jpg",
        "backTitle": "Edward VII",
        "backDescription": "Crowned lion passant atop crown dividing date.",
        "bottomContent": "Edward VII",
        "price": "$90.00",
        "ebay": ""
      },
      {
        "id": 52,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "shilling",
        "typeid": 4,
        "name": "Queen Victoria",
        "monarchtype": "Victoria",
        "year": 1895,
        "tagline": "Shilling",
        "title": "1895 Queen Victoria Shilling",
        "description": "Great Britain 1895 QUEEN VICTORIA Silver Shilling",
        "frontImg": "1895f.jpg",
        "frontTitle": "VICTORIA DEI GRATIA",
        "frontDescription": "Victoria Old Veiled Head Crown",
        "backImg": "1895b.jpg",
        "backTitle": "Queen Victoria",
        "backDescription": "Crowned shields of arms with national flower emblems between all within Garter.",
        "bottomContent": "Queen Victoria",
        "price": "$60.00",
        "ebay": ""
      },
      {
        "id": 53,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "shilling",
        "typeid": 4,
        "name": "Queen Victoria",
        "monarchtype": "Victoria",
        "year": 1889,
        "tagline": "Shilling",
        "title": "1889 Queen Victoria Shilling",
        "description": "1889 Queen Victoria Jubilee Head Silver Shilling.",
        "frontImg": "1889f.jpg",
        "frontTitle": "VICTORIA DEI GRATIA",
        "frontDescription": "Crowned and veiled bust (Jubilee Head) of Queen Victoria",
        "backImg": "1889b.jpg",
        "backTitle": "Queen Victoria",
        "backDescription": "Crowned Royal shield within Garter divided date below.",
        "bottomContent": "Queen Victoria",
        "price": "$60.00",
        "ebay": ""
      },
      {
        "id": 54,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "shilling",
        "typeid": 4,
        "name": "Queen Victoria",
        "monarchtype": "Victoria",
        "year": 1887,
        "tagline": "Shilling",
        "title": "1887 Queen Victoria Shilling",
        "description": "1887 GREAT BRITAIN Queen Victoria Silver Shilling Coin",
        "frontImg": "1887f.jpg",
        "frontTitle": "VICTORIA DEI GRATIA",
        "frontDescription": "Crowned and veiled bust (Jubilee Head) of Queen Victoria",
        "backImg": "1887b.jpg",
        "backTitle": "Queen Victoria",
        "backDescription": "Crowned Royal shield within Garter divided date below.",
        "bottomContent": "Queen Victoria",
        "price": "$60.00",
        "ebay": ""
      },
      {
        "id": 55,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "shilling",
        "typeid": 4,
        "name": "Queen Victoria",
        "monarchtype": "Victoria",
        "year": 1881,
        "tagline": "Shilling",
        "title": "1881 Queen Victoria Shilling",
        "description": "Great Britain 1881 SILVER Shilling VG+ Nice Young Queen Victoria OLD UK Coin.",
        "frontImg": "1881f.jpg",
        "frontTitle": "VICTORIA DEI GRATIA",
        "frontDescription": "Young uncrowned portrait (Young Head) of Queen Victoria",
        "backImg": "1881b.jpg",
        "backTitle": "Queen Victoria",
        "backDescription": "Crown above denomination within wreath",
        "bottomContent": "Queen Victoria",
        "price": "$60.00",
        "ebay": ""
      },
      {
        "id": 56,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "shilling",
        "typeid": 4,
        "name": "Queen Victoria",
        "monarchtype": "Victoria",
        "year": 1875,
        "tagline": "Shilling",
        "title": "1875 Queen Victoria Shilling",
        "description": "1875 1 Shilling - Victoria Silver 925)",
        "frontImg": "1875f.jpg",
        "frontTitle": "VICTORIA DEI GRATIA",
        "frontDescription": "Young uncrowned portrait (Young Head) of Queen Victoria",
        "backImg": "1875b.jpg",
        "backTitle": "Queen Victoria",
        "backDescription": "Crown above denomination within wreath",
        "bottomContent": "Queen Victoria",
        "price": "$60.00",
        "ebay": ""
      },
      {
        "id": 57,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "shilling",
        "typeid": 4,
        "name": "King George IV",
        "monarchtype": "William4",
        "year": 1835,
        "tagline": "Shilling",
        "title": "1835 King William IIII Shilling",
        "description": "The 1835 United Kingdom shillingreverse shows the words ONE SHILLING with the royal crown above and a smallscroll below.",
        "frontImg": "1835f.jpg",
        "frontTitle": "George IIII",
        "frontDescription": "George the Third by the Grace of God King of the Britains Defender of the Faith",
        "backImg": "1835b.jpg",
        "backTitle": "Defender of the Faith",
        "backDescription": "Ornate crowned Royal shield overlaid with smaller crowned shield all within Garter.",
        "bottomContent": "George IIII",
        "price": "$160.00",
        "ebay": ""
      },
      {
        "id": 58,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "shilling",
        "typeid": 4,
        "name": "King George IV",
        "monarchtype": "George4",
        "year": 1826,
        "tagline": "Shilling",
        "title": "1826 King George IV Shilling",
        "description": "1826 Silver George IV Shilling Bare Head",
        "frontImg": "1826f2.jpg",
        "frontTitle": "George IV",
        "frontDescription": "George the Fourth by the Grace of God",
        "backImg": "1826b2.jpg",
        "backTitle": "George IV",
        "backDescription": "Crowned lion passant atop crown legend around national flower emblems below.",
        "bottomContent": "George IV",
        "price": "$140.00",
        "ebay": ""
      },
      {
        "id": 59,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "shilling",
        "typeid": 4,
        "name": "King George IV",
        "monarchtype": "George4",
        "year": 1826,
        "tagline": "Shilling",
        "title": "1826 King George IV Shilling",
        "description": "1826 Silver George IV Shilling Bare Head",
        "frontImg": "1826f.jpg",
        "frontTitle": "George IV",
        "frontDescription": "George the Fourth by the Grace of God",
        "backImg": "1826b.jpg",
        "backTitle": "George IV",
        "backDescription": "Crowned lion passant atop crown legend around national flower emblems below.",
        "bottomContent": "George IV",
        "price": "$140.00",
        "ebay": ""
      },
      {
        "id": 60,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "shilling",
        "typeid": 4,
        "name": "King George IV",
        "monarchtype": "George4",
        "year": 1823,
        "tagline": "Shilling",
        "title": "1823 King George IIII Shilling",
        "description": "George IV 1823 second issue laureate head shilling.",
        "frontImg": "1823f.jpg",
        "frontTitle": "George IIII",
        "frontDescription": "New laureate portrait of King George III",
        "backImg": "1823b.jpg",
        "backTitle": "House of Hanover",
        "backDescription": "Ornate crowned Royal shield overlaid with smaller crowned shield all within Garter.",
        "bottomContent": "George IIII",
        "price": "$60.00",
        "ebay": ""
      },
      {
        "id": 61,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "shilling",
        "typeid": 4,
        "name": "King George IV",
        "monarchtype": "George4",
        "year": 1823,
        "tagline": "Shilling",
        "title": "1823 King George IIII Shilling",
        "description": "George IV 1823 second issue laureate head shilling.",
        "frontImg": "1823f.jpg",
        "frontTitle": "George IIII",
        "frontDescription": "New laureate portrait of King George III",
        "backImg": "1823b.jpg",
        "backTitle": "House of Hanover",
        "backDescription": "Ornate crowned Royal shield overlaid with smaller crowned shield all within Garter.",
        "bottomContent": "George IIII",
        "price": "$60.00",
        "ebay": ""
      },
      {
        "id": 62,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "shilling",
        "typeid": 4,
        "name": "King George III",
        "monarchtype": "George3",
        "year": 1819,
        "tagline": "Shilling",
        "title": "1819 King George III Shilling",
        "description": "Rare 1819 over 3 Shilling George III Coin UK Silver",
        "frontImg": "1819f2.jpg",
        "frontTitle": "George III",
        "frontDescription": "George the Third by the Grace of God King of the Britains Defender of the Faith",
        "backImg": "1819b2.jpg",
        "backTitle": "George the Third",
        "backDescription": "Ornate crowned Royal shield overlaid with smaller crowned shield all within Garter.",
        "bottomContent": "George III",
        "price": "$75.00",
        "ebay": ""
      },
      {
        "id": 63,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "shilling",
        "typeid": 4,
        "name": "King George III",
        "monarchtype": "George3",
        "year": 1819,
        "tagline": "Shilling",
        "title": "1819 King George III Shilling",
        "description": "Rare 1819 over 3 Shilling George III Coin UK Silver",
        "frontImg": "1819f.jpg",
        "frontTitle": "George III",
        "frontDescription": "George the Third by the Grace of God King of the Britains Defender of the Faith",
        "backImg": "1819b.jpg",
        "backTitle": "George the Third",
        "backDescription": "Ornate crowned Royal shield overlaid with smaller crowned shield all within Garter.",
        "bottomContent": "George III",
        "price": "$75.00",
        "ebay": ""
      },
      {
        "id": 64,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "shilling",
        "typeid": 4,
        "name": "King George III",
        "monarchtype": "George3",
        "year": 1817,
        "tagline": "Shilling",
        "title": "1817 King George III Shilling",
        "description": "1817 King George III Silver Shilling Coin  House of Hanover (1714-1901)",
        "frontImg": "1817f.jpg",
        "frontTitle": "George III",
        "frontDescription": "George the Third by the Grace of God King of the Britains Defender of the Faith",
        "backImg": "1817b.jpg",
        "backTitle": "George the Third",
        "backDescription": "Ornate crowned Royal shield overlaid with smaller crowned shield all within Garter.",
        "bottomContent": "George III",
        "price": "$75.00",
        "ebay": ""
      },
      {
        "id": 65,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "shilling",
        "typeid": 4,
        "name": "King George III",
        "monarchtype": "George3",
        "year": 1817,
        "tagline": "Shilling",
        "title": "1817 King George III Shilling",
        "description": "1817 King George III Silver Shilling Coin  House of Hanover (1714-1901)",
        "frontImg": "1817f.jpg",
        "frontTitle": "George III",
        "frontDescription": "George the Third by the Grace of God King of the Britains Defender of the Faith",
        "backImg": "1817b.jpg",
        "backTitle": "George the Third",
        "backDescription": "Ornate crowned Royal shield overlaid with smaller crowned shield all within Garter.",
        "bottomContent": "George III",
        "price": "$75.00",
        "ebay": ""
      },
      {
        "id": 66,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "shilling",
        "typeid": 4,
        "name": "King George III",
        "monarchtype": "George3",
        "year": 1787,
        "tagline": "Shilling",
        "title": "1787 King George III Shilling",
        "description": "1787 George III Shilling Great Britain Silver Coin The standard weight of these coins was 6/31 troy ounce (92.9 grains).",
        "frontImg": "1787f.jpg",
        "frontTitle": "George III",
        "frontDescription": "George the Third by the Grace of God King of the Britains Defender of the Faith",
        "backImg": "1787b.jpg",
        "backTitle": "George the Third",
        "backDescription": "Ornate crowned Royal shield overlaid with smaller crowned shield all within Garter.",
        "bottomContent": "George III",
        "price": "$150.00",
        "ebay": ""
      },
      {
        "id": 67,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "shilling",
        "typeid": 4,
        "name": "King George II",
        "monarchtype": "George2",
        "year": 1745,
        "tagline": "Shilling",
        "title": "1745 King George II Shilling",
        "description": "Issued under the rule of King George II  this shilling is marked LIMA below the head. This mark denotes this coin as being struck from Spanish-American silver that was seized by the British at Lima.",
        "frontImg": "1745f2.jpg",
        "frontTitle": "Lima",
        "frontDescription": "George the Second by the Grace of God",
        "backImg": "1745b2.jpg",
        "backTitle": "George II",
        "backDescription": "Crowned cruciform shields with central Garter star divided date above legend around.",
        "bottomContent": "George II",
        "price": "$250.00",
        "ebay": ""
      },
      {
        "id": 68,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "shilling",
        "typeid": 4,
        "name": "King George II",
        "monarchtype": "George2",
        "year": 1745,
        "tagline": "Shilling",
        "title": "1745 King George II Shilling",
        "description": "Issued under the rule of King George II  this shilling is marked LIMA below the head. This mark denotes this coin as being struck from Spanish-American silver that was seized by the British at Lima.",
        "frontImg": "1745f.jpg",
        "frontTitle": "Lima",
        "frontDescription": "George the Second by the Grace of God",
        "backImg": "1745b.jpg",
        "backTitle": "George II",
        "backDescription": "Crowned cruciform shields with central Garter star divided date above legend around.",
        "bottomContent": "George II",
        "price": "$250.00",
        "ebay": ""
      },
      {
        "id": 69,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "shilling",
        "typeid": 4,
        "name": "King George I",
        "monarchtype": "George1",
        "year": 1723,
        "tagline": "Shilling",
        "title": "1723 King George I Shilling",
        "description": "1723 George I First Bust Shilling King George I Second monarch of Great Brittan. Shilling 1723 SSC in angles.",
        "frontImg": "1723f.jpg",
        "frontTitle": "George I",
        "frontDescription": "Second laureate and draped bust of King George I",
        "backImg": "1723b.jpg",
        "backTitle": "Johann Ochs",
        "backDescription": "Duke of Brunswick and Lueneburg Arch Treasurer and Prince Elector of the Holy Roman Empire",
        "bottomContent": "George I",
        "price": "$600.00",
        "ebay": ""
      },
      {
        "id": 70,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "shilling",
        "typeid": 4,
        "name": "Queen Anne",
        "monarchtype": "Anne",
        "year": 1711,
        "tagline": "Shilling",
        "title": "1711 Queen Anne Shilling",
        "description": "1711 Queen Anne Early Milled Silver Shilling Fourth Bust",
        "frontImg": "1711f.jpg",
        "frontTitle": "Third draped bust",
        "frontDescription": "Anne by the Grace of God",
        "backImg": "1711b.jpg",
        "backTitle": "Queen Anne",
        "backDescription": "Crowned cruciform shields with central Garter star divided date above legend around.",
        "bottomContent": "Queen Anne",
        "price": "$450.00",
        "ebay": ""
      },
      {
        "id": 71,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "shilling",
        "typeid": 4,
        "name": "Queen Anne",
        "monarchtype": "Anne",
        "year": 1708,
        "tagline": "Shilling",
        "title": "1708 Queen Anne Shilling",
        "description": "1708 Great Britain Queen Anne Silver Shilling Struck in highly pure 92.5% Sterling silver by the London Mint.",
        "frontImg": "1708f.jpg",
        "frontTitle": "Grace of God",
        "frontDescription": "Second draped bust of Queen Anne left",
        "backImg": "1708b.jpg",
        "backTitle": "Queen Anne",
        "backDescription": "Crowned cruciform shields with central Garter star divided date above legend around.",
        "bottomContent": "Queen Anne",
        "price": "$500.00",
        "ebay": ""
      },
      {
        "id": 72,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "shilling",
        "typeid": 4,
        "name": "Queen Anne",
        "monarchtype": "Anne",
        "year": 1707,
        "tagline": "Shilling",
        "title": "1707 Queen Anne Shilling",
        "description": "Anne was Queen of Great Britain and Ireland following the ratification of the Acts of Union on 1 May 1707 which merged the kingdoms of Scotland and England. Before this she was Queen of England Scotland and Ireland from 8 March 1702. Anne was born during the reign of her uncle King Charles II.",
        "frontImg": "1707medallf.jpg",
        "frontTitle": "ANNA·DEI· GRATIA·E",
        "frontDescription": "Draped Edinburgh bust of Queen Anne left",
        "backImg": "1707medallb.jpg",
        "backTitle": "Queen Anne",
        "backDescription": "Crowned cruciform shields around central Garter star divided date above legend around.",
        "bottomContent": "Queen Anne",
        "price": "$500.00",
        "ebay": ""
      },
      {
        "id": 73,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "shilling",
        "typeid": 4,
        "name": "Queen Anne",
        "monarchtype": "Anne",
        "year": 1701,
        "tagline": "Shilling",
        "title": "1701 Queen Anne Shilling",
        "description": "Anne was Queen of Great Britain and Ireland following the ratification of the Acts of Union on 1 May 1707 which merged the kingdoms of Scotland and England. Before this she was Queen of England Scotland and Ireland from 8 March 1702. Anne was born during the reign of her uncle King Charles II.",
        "frontImg": "1700AnnAMedalf.jpg",
        "frontTitle": "GRATIA",
        "frontDescription": "Third draped bust of Queen Anne",
        "backImg": "1700AnnAMedalb.jpg",
        "backTitle": "Queen Anne",
        "backDescription": "Magnae Britanniae Franciae Et Hiberniae Regina",
        "bottomContent": "Queen Anne",
        "price": "$500.00",
        "ebay": ""
      },
      {
        "id": 74,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "shilling",
        "typeid": 4,
        "name": "King James II",
        "monarchtype": "William3",
        "year": 1700,
        "tagline": "Shilling",
        "title": "1700 King William III Shilling",
        "description": "1700 SHILLING BRITISH SILVER COIN FROM WILLIAM III Stunning Silver",
        "frontImg": "1700f.jpg",
        "frontTitle": "King William II",
        "frontDescription": "Minted by the Royal Mint at the Tower Mint in London.",
        "backImg": "1700b.jpg",
        "backTitle": "Arms of England",
        "backDescription": "The Reverse is four crowned shields bearing the arms of England Scotland France and Ireland arranged in a cross. Star of the Garter at the centre.",
        "bottomContent": "King William IIII",
        "price": "$900.00",
        "ebay": ""
      },
      {
        "id": 75,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "shilling",
        "typeid": 4,
        "name": "King Charles II",
        "monarchtype": "Charles2",
        "year": 1663,
        "tagline": "Shilling",
        "title": "1663 King Charles II Shilling",
        "description": "Charles II silver Shilling dated 1663 First Bust.",
        "frontImg": "1663f.jpg",
        "frontTitle": "King Charles II",
        "frontDescription": "First laureate and draped bust of King Charles II right legend around.",
        "backImg": "1663b.jpg",
        "backTitle": "Milled",
        "backDescription": "Crowned cruciform shields around central Garter star with interlinked C's in angles divided date above legend around.",
        "bottomContent": "King Charles II",
        "price": "$800.00",
        "ebay": ""
      },
      {
        "id": 76,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "halfcrown",
        "typeid": 2,
        "name": "Queen Elizabeth II",
        "monarchtype": "Elizabeth2",
        "year": 1960,
        "tagline": "Half Crowns",
        "title": "1960 Queen Elizabeth II  Half Crowns",
        "description": "In 1960 the Royal Mint issued a Special Crown for the British Exhibition in New York. It is without doubt the most difficult of the Queen Elizabeth II Crowns to obtain.",
        "frontImg": "1960f.jpg",
        "frontTitle": "Queen ELIZABETH II",
        "frontDescription": "Queen ELIZABETH II",
        "backImg": "1960b.jpg",
        "backTitle": "Queen ELIZABETH II",
        "backDescription": "Queen ELIZABETH II",
        "bottomContent": "Queen ELIZABETH II",
        "price": "$40.00",
        "ebay": ""
      },
      {
        "id": 77,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "florin",
        "typeid": 3,
        "name": "Queen Victoria",
        "monarchtype": "Victoria",
        "year": 1849,
        "tagline": "Florin",
        "title": "1849 Queen Victoria Florin",
        "description": "1849 Victoria Godless Florin This infamous coin caused controversy for omitting a reference to God (Del Gratia) from Queen Victorias titles",
        "frontImg": "1877f.jpg",
        "frontTitle": "Godless",
        "frontDescription": "Crowned bust of Queen Victoria left legend and date around.",
        "backImg": "1877b.jpg",
        "backTitle": "Queen Victoria",
        "backDescription": "Crowned cruciform shields of arms around central Tudor rose national flower emblems in angles legend above denomination below.",
        "bottomContent": "Queen Victoria",
        "price": "$1,430.00",
        "ebay": ""
      },
      {
        "id": 78,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "florin",
        "typeid": 3,
        "name": "Queen Victoria",
        "monarchtype": "Victoria",
        "year": 1887,
        "tagline": "Florin",
        "title": "1887 Queen Victoria Gothic Florin",
        "description": "1887 Queen Victoria Silver Gothic Florin.",
        "frontImg": "1878f.jpg",
        "frontTitle": "mdccclxxxvii",
        "frontDescription": "Crowned bust of Queen Victoria left legend and date around.",
        "backImg": "1878b.jpg",
        "backTitle": "Queen Victoria",
        "backDescription": "Crowned cruciform shields of arms around central Tudor rose national flower emblems in angles legend above denomination below.",
        "bottomContent": "Queen Victoria",
        "price": "$1,200.00",
        "ebay": ""
      },
      {
        "id": 79,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "florin",
        "typeid": 3,
        "name": "Queen Victoria",
        "monarchtype": "Victoria",
        "year": 1887,
        "tagline": "Florin",
        "title": "1887 Queen Victoria Florin",
        "description": "Victoria was Queen of the United Kingdom of Great Britain and Ireland from 20 June 1837 until her death in 1901. Her reign of 63 years and 216 days which was longer than those of any of her predecessors is known as the Victorian era.",
        "frontImg": "1887f.jpg",
        "frontTitle": "Obverse",
        "frontDescription": "Crowned and veiled bust (Jubilee Head) of Queen Victoria",
        "backImg": "1887b.jpg",
        "backTitle": "Reverse",
        "backDescription": "Crowned cruciform shields of England Scotland and Ireland sceptres with national flower emblems in angles divided date above legend around.",
        "bottomContent": "Queen Victoria",
        "price": "$150.00",
        "ebay": ""
      },
      {
        "id": 80,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "florin",
        "typeid": 3,
        "name": "Queen Victoria",
        "monarchtype": "Victoria",
        "year": 1895,
        "tagline": "Florin",
        "title": "1895 Queen Victoria Florin",
        "description": "Victoria was Queen of the United Kingdom of Great Britain and Ireland from 20 June 1837 until her death in 1901. Her reign of 63 years and 216 days which was longer than those of any of her predecessors is known as the Victorian era.",
        "frontImg": "1895f.jpg",
        "frontTitle": "Queen Victoria",
        "frontDescription": "Older crowned and veiled bust of Queen Victoria left legend around.",
        "backImg": "1895b.jpg",
        "backTitle": "3rd portrait",
        "backDescription": "Crown above shields of England Scotland and Ireland with national flower emblems between all within Garter with divided date below and legend around.",
        "bottomContent": "Queen Victoria",
        "price": "$150.00",
        "ebay": ""
      },
      {
        "id": 81,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "florin",
        "typeid": 3,
        "name": "King Edward VII",
        "monarchtype": "Edward7",
        "year": 1902,
        "tagline": "Florin",
        "title": "1902 King Edward VII  Florin",
        "description": "The second child and eldest son of Queen Victoria and Prince Albert of Saxe-Coburg and Gotha Edward nicknamed \"Bertie\" was related to royalty throughout Europe.",
        "frontImg": "1902f.jpg",
        "frontTitle": "Edward VII",
        "frontDescription": "Uncrowned portrait of King Edward VII right legend around.",
        "backImg": "1902b.jpg",
        "backTitle": "EDWARDVS VII",
        "backDescription": "Standing figure of Britannia on ship's bow holding trident in the right hand and shield bearing the Union flag in the left legend around date below.",
        "bottomContent": "Edward VII",
        "price": "$100.00",
        "ebay": ""
      },
      {
        "id": 82,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "florin",
        "typeid": 3,
        "name": "King George V",
        "monarchtype": "George5",
        "year": 1911,
        "tagline": "Florin",
        "title": "1911 King George V Florin",
        "description": "George V (George Frederick Ernest Albert; 3 June 1865 20 January 1936) was King of the United Kingdom and the British Dominions and Emperor of India from 6 May 1910 until his death in 1936.",
        "frontImg": "1911f.jpg",
        "frontTitle": "George V",
        "frontDescription": "Uncrowned portrait of King George V left",
        "backImg": "1911b.jpg",
        "backTitle": "ONE FLORIN",
        "backDescription": "Crowned cruciform shields around central Garter star sceptres with national emblems in angles denomination above divided date below.",
        "bottomContent": "George V",
        "price": "$60.00",
        "ebay": ""
      },
      {
        "id": 83,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "florin",
        "typeid": 3,
        "name": "King George V",
        "monarchtype": "George5",
        "year": 1935,
        "tagline": "Florin",
        "title": "1935 King George V Florin",
        "description": "George V (George Frederick Ernest Albert; 3 June 1865 20 January 1936) was King of the United Kingdom and the British Dominions and Emperor of India from 6 May 1910 until his death in 1936.",
        "frontImg": "1935f.jpg",
        "frontTitle": "George V",
        "frontDescription": "Uncrowned portrait of King George V left",
        "backImg": "1935b.jpg",
        "backTitle": "ONE FLORIN",
        "backDescription": "Crowned cruciform shields around central Garter star sceptres with national emblems in angles denomination above divided date below.",
        "bottomContent": "George V",
        "price": "$60.00",
        "ebay": ""
      },
      {
        "id": 84,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "florin",
        "typeid": 3,
        "name": "King George VI",
        "monarchtype": "George6",
        "year": 1944,
        "tagline": "Florin",
        "title": "1944 King George VI Florin",
        "description": "George VI was King of the United Kingdom and the Dominions of the British Commonwealth from 11 December 1936 until his death on 6 February 1952.",
        "frontImg": "1944f.jpg",
        "frontTitle": "George VI",
        "frontDescription": "Portrait of King George VI facing left",
        "backImg": "1944b.jpg",
        "backTitle": "ONE FLORIN",
        "backDescription": "Coat of arms",
        "bottomContent": "George VI",
        "price": "$60.00",
        "ebay": ""
      },
      {
        "id": 85,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "florin",
        "typeid": 3,
        "name": "King George VI",
        "monarchtype": "George6",
        "year": 1951,
        "tagline": "Florin",
        "title": "1951 King George VI Florin",
        "description": "George VI was King of the United Kingdom and the Dominions of the British Commonwealth from 11 December 1936 until his death on 6 February 1952.",
        "frontImg": "1951f.jpg",
        "frontTitle": "George VI",
        "frontDescription": "Portrait of King George VI facing left",
        "backImg": "1951b.jpg",
        "backTitle": "ONE FLORIN",
        "backDescription": "Coat of arms",
        "bottomContent": "George VI",
        "price": "$60.00",
        "ebay": ""
      },
      {
        "id": 86,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "florin",
        "typeid": 3,
        "name": "Queen Elizabeth II",
        "monarchtype": "Elizabeth2",
        "year": 1970,
        "tagline": "Florin",
        "title": "1970 Queen Elizabeth II  Florin",
        "description": "Elizabeth II was Queen of the United Kingdom and other Commonwealth realms from 6 February 1952 until her death in 2022. She was queen regnant of 32 sovereign states over the course of her lifetime and remained the monarch of 15 realms by the time of her death.",
        "frontImg": "1970f.jpg",
        "frontTitle": "Queen ELIZABETH II",
        "frontDescription": "The florin was introduced as part of an experiment in decimalisation that went no further at the time.",
        "backImg": "1970b.jpg",
        "backTitle": "ONE FLORIN",
        "backDescription": "floral design",
        "bottomContent": "Queen ELIZABETH II",
        "price": "$40.00",
        "ebay": ""
      },
      {
        "id": 87,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "florin",
        "typeid": 3,
        "name": "Queen Elizabeth II",
        "monarchtype": "Elizabeth2",
        "year": 1970,
        "tagline": "Florin",
        "title": "1970 Queen Elizabeth II  Florin",
        "description": "Elizabeth II was Queen of the United Kingdom and other Commonwealth realms from 6 February 1952 until her death in 2022. She was queen regnant of 32 sovereign states over the course of her lifetime and remained the monarch of 15 realms by the time of her death.",
        "frontImg": "19702f.jpg",
        "frontTitle": "Queen ELIZABETH II",
        "frontDescription": "The florin was introduced as part of an experiment in decimalisation that went no further at the time.",
        "backImg": "19702b.jpg",
        "backTitle": "ONE FLORIN",
        "backDescription": "floral design",
        "bottomContent": "Queen ELIZABETH II",
        "price": "$40.00",
        "ebay": ""
      },
      {
        "id": 88,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "florin",
        "typeid": 3,
        "name": "Queen Elizabeth II",
        "monarchtype": "Elizabeth2",
        "year": 1956,
        "tagline": "Florin",
        "title": "1956 Queen Elizabeth II  Florin",
        "description": "Elizabeth II was Queen of the United Kingdom and other Commonwealth realms from 6 February 1952 until her death in 2022. She was queen regnant of 32 sovereign states over the course of her lifetime and remained the monarch of 15 realms by the time of her death.",
        "frontImg": "1956f.jpg",
        "frontTitle": "Queen ELIZABETH II",
        "frontDescription": "The florin was introduced as part of an experiment in decimalisation that went no further at the time.",
        "backImg": "1956b.jpg",
        "backTitle": "ONE FLORIN",
        "backDescription": "floral design",
        "bottomContent": "Queen ELIZABETH II",
        "price": "$40.00",
        "ebay": ""
      },
      {
        "id": 89,
        "country": "United States",
        "countryname": "unitedstates",
        "type": "morgan",
        "typeid": 10,
        "name": "Morgan Dollars",
        "monarchtype": "unitedstates",
        "year": 1881,
        "tagline": "Morgan Dollars",
        "title": "1881 Morgan Dollars Morgan Dollars",
        "description": "The Morgan dollar is a United States dollar coin minted from 1878 to 1904 in 1921 and beginning again in 2021 as a collectible. It was the first standard silver dollar minted since the passage of the Coinage Act of 1873.",
        "frontImg": "1881f.jpg",
        "frontTitle": "Anna Willess Williams",
        "frontDescription": "The bust of Lady Liberty",
        "backImg": "1881b.jpg",
        "backTitle": "Morgan Dollar Reverse",
        "backDescription": "Morgans depiction of the eagle is extremely intricate and the its feathers can be seen in full detail.",
        "bottomContent": "Morgans depiction of the eagle is extremely intricate and the its feathers can be seen in full detail.",
        "price": "$100.00",
        "ebay": ""
      },
      {
        "id": 90,
        "country": "United States",
        "countryname": "unitedstates",
        "type": "morgan",
        "typeid": 10,
        "name": "Morgan Dollars",
        "monarchtype": "unitedstates",
        "year": 1885,
        "tagline": "Morgan Dollars",
        "title": "1885 Morgan Dollars Morgan Dollars",
        "description": "The Morgan dollar is a United States dollar coin minted from 1878 to 1904 in 1921 and beginning again in 2021 as a collectible. It was the first standard silver dollar minted since the passage of the Coinage Act of 1873.",
        "frontImg": "1885f.jpg",
        "frontTitle": "Anna Willess Williams",
        "frontDescription": "The bust of Lady Liberty",
        "backImg": "1885b.jpg",
        "backTitle": "Morgan Dollar Reverse",
        "backDescription": "Morgans depiction of the eagle is extremely intricate and the its feathers can be seen in full detail.",
        "bottomContent": "Morgans depiction of the eagle is extremely intricate and the its feathers can be seen in full detail.",
        "price": "$75.00",
        "ebay": ""
      },
      {
        "id": 91,
        "country": "United States",
        "countryname": "unitedstates",
        "type": "morgan",
        "typeid": 10,
        "name": "Morgan Dollars",
        "monarchtype": "unitedstates",
        "year": 1890,
        "tagline": "Morgan Dollars",
        "title": "1890 Morgan Dollars Morgan Dollars",
        "description": "The Morgan dollar is a United States dollar coin minted from 1878 to 1904 in 1921 and beginning again in 2021 as a collectible. It was the first standard silver dollar minted since the passage of the Coinage Act of 1873.",
        "frontImg": "1890f.jpg",
        "frontTitle": "Anna Willess Williams",
        "frontDescription": "The bust of Lady Liberty",
        "backImg": "1890b.jpg",
        "backTitle": "Morgan Dollar Reverse",
        "backDescription": "Morgans depiction of the eagle is extremely intricate and the its feathers can be seen in full detail.",
        "bottomContent": "Morgans depiction of the eagle is extremely intricate and the its feathers can be seen in full detail.",
        "price": "$75.00",
        "ebay": ""
      },
      {
        "id": 92,
        "country": "United States",
        "countryname": "unitedstates",
        "type": "morgan",
        "typeid": 10,
        "name": "Morgan Dollars",
        "monarchtype": "unitedstates",
        "year": 1900,
        "tagline": "Morgan Dollars",
        "title": "1900 Morgan Dollars Morgan Dollars",
        "description": "The Morgan dollar is a United States dollar coin minted from 1878 to 1904 in 1921 and beginning again in 2021 as a collectible. It was the first standard silver dollar minted since the passage of the Coinage Act of 1873.",
        "frontImg": "1900f.jpg",
        "frontTitle": "Anna Willess Williams",
        "frontDescription": "The bust of Lady Liberty",
        "backImg": "1900b.jpg",
        "backTitle": "Morgan Dollar Reverse",
        "backDescription": "Morgans depiction of the eagle is extremely intricate and the its feathers can be seen in full detail.",
        "bottomContent": "Morgans depiction of the eagle is extremely intricate and the its feathers can be seen in full detail.",
        "price": "$60.00",
        "ebay": "https://www.ebay.com/itm/355850530252"
      },
      {
        "id": 93,
        "country": "United States",
        "countryname": "unitedstates",
        "type": "morgan",
        "typeid": 10,
        "name": "Morgan Dollars",
        "monarchtype": "unitedstates",
        "year": 2023,
        "tagline": "Morgan Dollars",
        "title": "2023 Morgan Dollars Morgan Dollars",
        "description": "The Morgan dollar is a United States dollar coin minted from 1878 to 1904 in 1921 and beginning again in 2021 as a collectible. It was the first standard silver dollar minted since the passage of the Coinage Act of 1873.",
        "frontImg": "2023f.jpg",
        "frontTitle": "Anna Willess Williams",
        "frontDescription": "The bust of Lady Liberty",
        "backImg": "2023b.jpg",
        "backTitle": "Morgan Dollar Reverse",
        "backDescription": "Morgans depiction of the eagle is extremely intricate and the its feathers can be seen in full detail.",
        "bottomContent": "Morgans depiction of the eagle is extremely intricate and the its feathers can be seen in full detail.",
        "price": "$75.00",
        "ebay": ""
      },
      {
        "id": 94,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "sixpence",
        "typeid": 5,
        "name": "King Charles II",
        "monarchtype": "Charles2",
        "year": 1677,
        "tagline": "Sixpence",
        "title": "1677 King Charles II Sixpence",
        "description": "Charles II (29 May 1630 6 February 1685)[c] was King of Scotland from 1649 until 1651 and King of England Scotland and Ireland from the 1660 Restoration of the monarchy until his death in 1685.",
        "frontImg": "1677f.jpg",
        "frontTitle": "CAROLVS·II",
        "frontDescription": "Laureate and draped bust of King Charles II right legend around.",
        "backImg": "1677b.jpg",
        "backTitle": "House of Stuart",
        "backDescription": "Crowned cruciform shields around central Garter star with interlinked C's in angles divided date above legend around.",
        "bottomContent": "King Charles II",
        "price": "$600.00",
        "ebay": ""
      },
      {
        "id": 95,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "sixpence",
        "typeid": 5,
        "name": "King William III",
        "monarchtype": "William3",
        "year": 1698,
        "tagline": "Sixpence",
        "title": "1698 King William III  Sixpence",
        "description": "William III (William Henry; Dutch: Willem Hendrik; 4 November 1650 8 March 1702)[b] also widely known as William of Orange",
        "frontImg": "1698f.jpg",
        "frontTitle": "GVLIELMVS· III·DEI·GRA",
        "frontDescription": "Third laureate and draped bust of King William III right legend around.",
        "backImg": "1698b.jpg",
        "backTitle": "House of Stuart",
        "backDescription": "Crowned cruciform shields around central lion rampant divided date above legend around.",
        "bottomContent": "",
        "price": "$600.00",
        "ebay": ""
      },
      {
        "id": 96,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "sixpence",
        "typeid": 5,
        "name": "Queen Anne",
        "monarchtype": "Anne",
        "year": 1707,
        "tagline": "Sixpence",
        "title": "1707 Queen Anne Sixpence",
        "description": "Anne was Queen of Great Britain and Ireland following the ratification of the Acts of Union on 1 May 1707 which merged the kingdoms of Scotland and England. Before this she was Queen of England Scotland and Ireland from 8 March 1702. Anne was born during the reign of her uncle King Charles II.",
        "frontImg": "1707f.jpg",
        "frontTitle": "ANNA·DEI· GRATIA",
        "frontDescription": "Normal draped bust of Queen Anne left legend around.",
        "backImg": "1707b.jpg",
        "backTitle": "Circulated",
        "backDescription": "Crowned cruciform shields around central Garter star divided date above legend around.",
        "bottomContent": "Queen Anne",
        "price": "$700.00",
        "ebay": ""
      },
      {
        "id": 97,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "sixpence",
        "typeid": 5,
        "name": "George I",
        "monarchtype": "George1",
        "year": 1723,
        "tagline": "Sixpence",
        "title": "1723 King George I Sixpence",
        "description": "George I (George Louis; German: Georg Ludwig; 28 May 1660 \u001a 11 June 1727)[a] was King of Great Britain and Ireland from 1 August 1714 and ruler of the Electorate of Hanover within the Holy Roman Empire from 23 January 1698 until his death in 1727. He was the first British monarch of the House of Hanover.",
        "frontImg": "1723f.jpg",
        "frontTitle": "GEORGIVS",
        "frontDescription": "Laureate and draped bust of King George I right legend around.",
        "backImg": "1723b.jpg",
        "backTitle": "Reverse",
        "backDescription": "Crowned cruciform shields around central Garter star divided date above legend around.",
        "bottomContent": "King George I",
        "price": "$600.00",
        "ebay": ""
      },
      {
        "id": 98,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "sixpence",
        "typeid": 5,
        "name": "George II",
        "monarchtype": "George2",
        "year": 1757,
        "tagline": "Sixpence",
        "title": "1757 King George II Sixpence",
        "description": "George II (George Augustus; German: Georg August; 30 October / 9 November 1683[a] 25 October 1760) was King of Great Britain and Ireland Duke of Brunswick-Lüneburg (Hanover) and a prince-elector of the Holy Roman Empire from 11 June 1727 (O.S.) until his death in 1760.",
        "frontImg": "1757f.jpg",
        "frontTitle": "GEORGIUS·II· DEI·GRATIA",
        "frontDescription": "Older laureate and draped bust of King George II left legend around.",
        "backImg": "1757b.jpg",
        "backTitle": "House of Hanover",
        "backDescription": "Crowned cruciform shields around central Garter star divided date above legend around.",
        "bottomContent": "King George II",
        "price": "$150.00",
        "ebay": ""
      },
      {
        "id": 99,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "sixpence",
        "typeid": 5,
        "name": "George III",
        "monarchtype": "George3",
        "year": 1782,
        "tagline": "Sixpence",
        "title": "1782 King George III Sixpence",
        "description": "George III was King of Great Britain and Ireland from 25 October 1760 until his death in 1820. The Acts of Union 1800 unified Great Britain and Ireland into the United Kingdom of Great Britain and Ireland with George as its king.",
        "frontImg": "1782f.jpg",
        "frontTitle": "GEORGIVS·III",
        "frontDescription": "Laureate and draped bust of King George III right legend around.",
        "backImg": "1782b.jpg",
        "backTitle": "Grace of God",
        "backDescription": "Cruciform shields around central Garter star with crowns in angles legend around date below.",
        "bottomContent": "King George III",
        "price": "$80.00",
        "ebay": ""
      },
      {
        "id": 100,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "sixpence",
        "typeid": 5,
        "name": "George III",
        "monarchtype": "George3",
        "year": 1810,
        "tagline": "Sixpence",
        "title": "1810 King George III Sixpence",
        "description": "George III was King of Great Britain and Ireland from 25 October 1760 until his death in 1820. The Acts of Union 1800 unified Great Britain and Ireland into the United Kingdom of Great Britain and Ireland with George as its king.",
        "frontImg": "1810f.jpg",
        "frontTitle": "GEORGIVS·III",
        "frontDescription": "Laureate portrait of King George III right legend around date below.",
        "backImg": "1810b.jpg",
        "backTitle": "GEOR:III",
        "backDescription": "George the Third by the Grace of God King of the Britains Defender of the Faith",
        "bottomContent": "King George III",
        "price": "$60.00",
        "ebay": ""
      },
      {
        "id": 101,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "sixpence",
        "typeid": 5,
        "name": "King George IV",
        "monarchtype": "George4",
        "year": 1821,
        "tagline": "Sixpence",
        "title": "1821 King George IIII Sixpence",
        "description": "George IV was the eldest child of King George III and Queen Charlotte. He led an extravagant lifestyle that contributed to the fashions of the Regency era. He was a patron of new forms of leisure style and taste.",
        "frontImg": "1821f.jpg",
        "frontTitle": "GEORGIUS IIII",
        "frontDescription": "Laureate portrait of King George IV left legend around.",
        "backImg": "1821b.jpg",
        "backTitle": "ANNO 1821",
        "backDescription": "Ornate garnished crowned quartered shield of arms overlaid with second smaller crowned shield national flower emblems around date below",
        "bottomContent": "King George IIII",
        "price": "$90.00",
        "ebay": ""
      },
      {
        "id": 102,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "sixpence",
        "typeid": 5,
        "name": "King George IV",
        "monarchtype": "George4",
        "year": 1826,
        "tagline": "Sixpence",
        "title": "1826 King George IIII Sixpence",
        "description": "George IV was the eldest child of King George III and Queen Charlotte. He led an extravagant lifestyle that contributed to the fashions of the Regency era. He was a patron of new forms of leisure style and taste.",
        "frontImg": "1826f.jpg",
        "frontTitle": "GEORGIUS IIII",
        "frontDescription": "Laureate portrait of King George IV left legend around.",
        "backImg": "1826b.jpg",
        "backTitle": "Benedetto Pistrucci",
        "backDescription": "Square topped crowned quartered shield of arms overlaid with second smaller crowned shield Garter with legend around date below.",
        "bottomContent": "King George IIII",
        "price": "$50.00",
        "ebay": ""
      },
      {
        "id": 103,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "sixpence",
        "typeid": 5,
        "name": "King William IV",
        "monarchtype": "William4",
        "year": 1834,
        "tagline": "Sixpence",
        "title": "1834 King William IV Sixpence",
        "description": "The reign of King William IV was very short 1830-1837. The largest silver coin struck for circulation was the Halfcrown or two shillings and sixpence.",
        "frontImg": "1834f.jpg",
        "frontTitle": "GEORGIUS IIII",
        "frontDescription": "Laureate portrait of King George III right legend around date below.",
        "backImg": "1834b.jpg",
        "backTitle": "Benedetto Pistrucci",
        "backDescription": "Crowned quartered shield of arms overlaid with smaller crowned shield legend around on garter.",
        "bottomContent": "King William IV",
        "price": "$150.00",
        "ebay": ""
      },
      {
        "id": 104,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "sixpence",
        "typeid": 5,
        "name": "Queen Victoria",
        "monarchtype": "Victoria",
        "year": 1887,
        "tagline": "Sixpence",
        "title": "1887 Queen Victoria Sixpence",
        "description": "In 1887 the shield reverse of the Jubilee issue proved a favourite with some who gold plated them and passed them off as half sovereigns. As a result the reverse reverted to the earlier SIX PENCE type during that year, making three different designs of sixpence all dated 1887. The withdrawn shield type is common in good condition as many were saved as curios.",
        "frontImg": "1887f.jpg",
        "frontTitle": "VICTORIA",
        "frontDescription": "Crowned and veiled bust (Jubilee Head) of Queen Victoria left legend around engraver's initials below truncation.",
        "backImg": "1887b.jpg",
        "backTitle": "Edgar Boehm",
        "backDescription": "Crowned quartered shield of arms within Garter legend around divided date below.",
        "bottomContent": "Queen Victoria",
        "price": "$30.00",
        "ebay": ""
      },
      {
        "id": 105,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "sixpence",
        "typeid": 5,
        "name": "Queen Victoria",
        "monarchtype": "Victoria",
        "year": 1887,
        "tagline": "Sixpence",
        "title": "1887 Queen Victoria Sixpence",
        "description": "In 1887 the shield reverse of the Jubilee issue proved a favourite with some who gold plated them and passed them off as half sovereigns. As a result the reverse reverted to the earlier SIX PENCE type during that year, making three different designs of sixpence all dated 1887. The withdrawn shield type is common in good condition as many were saved as curios.",
        "frontImg": "1887yf.jpg",
        "frontTitle": "VICTORIA",
        "frontDescription": "Crowned and veiled bust (Jubilee Head) of Queen Victoria left legend around engraver's initials below truncation.",
        "backImg": "1887yb.jpg",
        "backTitle": "Edgar Boehm",
        "backDescription": "Crowned quartered shield of arms within Garter legend around divided date below.",
        "bottomContent": "Queen Victoria",
        "price": "$30.00",
        "ebay": ""
      },
      {
        "id": 106,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "sixpence",
        "typeid": 5,
        "name": "Queen Victoria",
        "monarchtype": "Victoria",
        "year": 1888,
        "tagline": "Sixpence",
        "title": "1888 Queen Victoria Sixpence",
        "description": "Victoria was Queen of the United Kingdom of Great Britain and Ireland from 20 June 1837 until her death in 1901. Her reign of 63 years and 216 days which was longer than those of any of her predecessors is known as the Victorian era.",
        "frontImg": "1888f.jpg",
        "frontTitle": "VICTORIA",
        "frontDescription": "Crowned and veiled bust (Jubilee Head) of Queen Victoria left legend around engraver's initials below truncation.",
        "backImg": "1888b.jpg",
        "backTitle": "Edgar Boehm",
        "backDescription": "Crowned quartered shield of arms within Garter legend around divided date below.",
        "bottomContent": "Queen Victoria",
        "price": "$25.00",
        "ebay": ""
      },
      {
        "id": 107,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "sixpence",
        "typeid": 5,
        "name": "Queen Victoria",
        "monarchtype": "Victoria",
        "year": 1895,
        "tagline": "Sixpence",
        "title": "1895 Queen Victoria Sixpence",
        "description": "Victoria was Queen of the United Kingdom of Great Britain and Ireland from 20 June 1837 until her death in 1901. Her reign of 63 years and 216 days which was longer than those of any of her predecessors is known as the Victorian era.",
        "frontImg": "1895f.jpg",
        "frontTitle": "VICTORIA",
        "frontDescription": "Older diademed and veiled bust (Old Head) of Queen Victoria left legend around.",
        "backImg": "1895b.jpg",
        "backTitle": "Thomas Brock",
        "backDescription": "Crowned denomination within wreath date below.",
        "bottomContent": "Queen Victoria",
        "price": "$25.00",
        "ebay": ""
      },
      {
        "id": 108,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "sixpence",
        "typeid": 5,
        "name": "King Edward VII",
        "monarchtype": "Edward7",
        "year": 1902,
        "tagline": "Sixpence",
        "title": "1902 King Edward VII  Sixpence",
        "description": "The second child and eldest son of Queen Victoria and Prince Albert of Saxe-Coburg and Gotha Edward nicknamed \"Bertie\" was related to royalty throughout Europe.",
        "frontImg": "1902f.jpg",
        "frontTitle": "EDWARDVS VII",
        "frontDescription": "Uncrowned bust of King Edward VII right legend around.",
        "backImg": "1902b.jpg",
        "backTitle": "de Saulles",
        "backDescription": "Crowned denomination within wreath (olive sprig on left oak on right) date below.",
        "bottomContent": "King Edward VII",
        "price": "$20.00",
        "ebay": ""
      },
      {
        "id": 109,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "sixpence",
        "typeid": 5,
        "name": "King George V",
        "monarchtype": "George5",
        "year": 1911,
        "tagline": "Sixpence",
        "title": "1911 King George V Sixpence",
        "description": "George V (George Frederick Ernest Albert; 3 June 1865 20 January 1936) was King of the United Kingdom and the British Dominions and Emperor of India from 6 May 1910 until his death in 1936.",
        "frontImg": "1911f.jpg",
        "frontTitle": "GEORGIVS V",
        "frontDescription": "Uncrowned portrait of King George V left legend around.",
        "backImg": "1911b.jpg",
        "backTitle": "MacKennal",
        "backDescription": "Crowned lion passant atop crown dividing date within circle legend above denomination below.",
        "bottomContent": "King George V",
        "price": "$15.00",
        "ebay": ""
      },
      {
        "id": 110,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "sixpence",
        "typeid": 5,
        "name": "King George V",
        "monarchtype": "George5",
        "year": 1935,
        "tagline": "Sixpence",
        "title": "1935 King George V Sixpence",
        "description": "George V (George Frederick Ernest Albert; 3 June 1865 20 January 1936) was King of the United Kingdom and the British Dominions and Emperor of India from 6 May 1910 until his death in 1936.",
        "frontImg": "1935f.jpg",
        "frontTitle": "GEORGIVS V",
        "frontDescription": "Uncrowned portrait of King George V left legend around.",
        "backImg": "1935b.jpg",
        "backTitle": "MacKennal",
        "backDescription": "Three oak sprigs with six acorns denomination above date below.",
        "bottomContent": "King George V",
        "price": "$15.00",
        "ebay": ""
      },
      {
        "id": 111,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "sixpence",
        "typeid": 5,
        "name": "King George VI",
        "monarchtype": "George6",
        "year": 1943,
        "tagline": "Sixpence",
        "title": "1943 King George VI Sixpence",
        "description": "George VI was King of the United Kingdom and the Dominions of the British Commonwealth from 11 December 1936 until his death on 6 February 1952.",
        "frontImg": "1943f.jpg",
        "frontTitle": "GEORGIVS VI",
        "frontDescription": "Uncrowned portrait of King George VI left legend around.",
        "backImg": "1943b.jpg",
        "backTitle": "Paget",
        "backDescription": "Crowned royal cypher dividing date legend above denomination below.",
        "bottomContent": "King George VI",
        "price": "$15.00",
        "ebay": ""
      },
      {
        "id": 112,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "sixpence",
        "typeid": 5,
        "name": "King George VI",
        "monarchtype": "George6",
        "year": 1948,
        "tagline": "Sixpence",
        "title": "1948 King George VI Sixpence",
        "description": "George VI was King of the United Kingdom and the Dominions of the British Commonwealth from 11 December 1936 until his death on 6 February 1952.",
        "frontImg": "1948f.jpg",
        "frontTitle": "GEORGIVS VI",
        "frontDescription": "Uncrowned portrait of King George VI left legend around.",
        "backImg": "1948b.jpg",
        "backTitle": "Paget",
        "backDescription": "Crowned royal cypher dividing date legend above denomination below.",
        "bottomContent": "King George VI",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 113,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "sixpence",
        "typeid": 5,
        "name": "King George VI",
        "monarchtype": "George6",
        "year": 1950,
        "tagline": "Sixpence",
        "title": "1950 King George VI Sixpence",
        "description": "George VI was King of the United Kingdom and the Dominions of the British Commonwealth from 11 December 1936 until his death on 6 February 1952.",
        "frontImg": "1950f.jpg",
        "frontTitle": "GEORGIVS VI",
        "frontDescription": "Uncrowned portrait of King George VI left legend around.",
        "backImg": "1950b.jpg",
        "backTitle": "Paget",
        "backDescription": "Crowned royal cypher dividing date legend above denomination below.",
        "bottomContent": "King George VI",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 114,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "sixpence",
        "typeid": 5,
        "name": "Queen Elizabeth II",
        "monarchtype": "Elizabeth2",
        "year": 1970,
        "tagline": "Sixpence",
        "title": "1970 Queen Elizabeth II  Sixpence",
        "description": "Elizabeth II was Queen of the United Kingdom and other Commonwealth realms from 6 February 1952 until her death in 2022. She was queen regnant of 32 sovereign states over the course of her lifetime and remained the monarch of 15 realms by the time of her death.",
        "frontImg": "1970f.jpg",
        "frontTitle": "ELIZABETH·II",
        "frontDescription": "Young laureate portrait of Queen Elizabeth II right legend around.",
        "backImg": "1970b.jpg",
        "backTitle": "Gillick",
        "backDescription": "Tudor rose shamrock thistle and leek aligned in a cross legend above denomination and date below.",
        "bottomContent": "Queen Elizabeth II",
        "price": "$20.00",
        "ebay": ""
      },
      {
        "id": 115,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "threepence",
        "typeid": 6,
        "name": "Queen Victoria",
        "monarchtype": "Victoria",
        "year": 1884,
        "tagline": "Threepence",
        "title": "1884 Queen Victoria Threepence",
        "description": "Victoria was Queen of the United Kingdom of Great Britain and Ireland from 20 June 1837 until her death in 1901. Her reign of 63 years and 216 days which was longer than those of any of her predecessors is known as the Victorian era.",
        "frontImg": "1884f.jpg",
        "frontTitle": "VICTORIA",
        "frontDescription": "Uncrowned portrait (Young Head) of Queen Victoria left legend around.",
        "backImg": "1884f.jpg",
        "backTitle": "Wyon",
        "backDescription": "Crowned denomination divides date within oak wreath.",
        "bottomContent": "Queen Victoria",
        "price": "$20.00",
        "ebay": ""
      },
      {
        "id": 116,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "threepence",
        "typeid": 6,
        "name": "Queen Victoria",
        "monarchtype": "Victoria",
        "year": 1889,
        "tagline": "Threepence",
        "title": "1889 Queen Victoria Threepence",
        "description": "Victoria was Queen of the United Kingdom of Great Britain and Ireland from 20 June 1837 until her death in 1901. Her reign of 63 years and 216 days which was longer than those of any of her predecessors is known as the Victorian era.",
        "frontImg": "1889f.jpg",
        "frontTitle": "VICTORIA",
        "frontDescription": "Crowned and veiled portrait (Jubilee Head) of Queen Victoria left legend around.",
        "backImg": "1889b.jpg",
        "backTitle": "Boehm",
        "backDescription": "Crowned denomination divides date within oak wreath.",
        "bottomContent": "Queen Victoria",
        "price": "$15.00",
        "ebay": ""
      },
      {
        "id": 117,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "threepence",
        "typeid": 6,
        "name": "Queen Victoria",
        "monarchtype": "Victoria",
        "year": 1900,
        "tagline": "Threepence",
        "title": "1900 Queen Victoria Threepence",
        "description": "Victoria was Queen of the United Kingdom of Great Britain and Ireland from 20 June 1837 until her death in 1901. Her reign of 63 years and 216 days which was longer than those of any of her predecessors is known as the Victorian era.",
        "frontImg": "1900f.jpg",
        "frontTitle": "VICTORIA",
        "frontDescription": "Older crowned and veiled bust (Old Head) of Queen Victoria left with designer's initials below truncation and legend around.",
        "backImg": "1900b.jpg",
        "backTitle": "Brock",
        "backDescription": "Crowned denomination divides date within oak sprigs.",
        "bottomContent": "Queen Victoria",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 118,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "threepence",
        "typeid": 6,
        "name": "King Edward VII",
        "monarchtype": "Edward7",
        "year": 1902,
        "tagline": "Threepence",
        "title": "1902 King Edward VII  Threepence",
        "description": "The second child and eldest son of Queen Victoria and Prince Albert of Saxe-Coburg and Gotha Edward nicknamed \"Bertie\" was related to royalty throughout Europe.",
        "frontImg": "1902f.jpg",
        "frontTitle": "EDWARDVS VII",
        "frontDescription": "Uncrowned portrait of King Edward VII right legend around.",
        "backImg": "1902b.jpg",
        "backTitle": "de Saulles",
        "backDescription": "Crowned denomination dividing date within oak wreath.",
        "bottomContent": "King Edward VII",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 119,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "threepence",
        "typeid": 6,
        "name": "King George V",
        "monarchtype": "George5",
        "year": 1911,
        "tagline": "Threepence",
        "title": "1911 King George V Threepence",
        "description": "George V (George Frederick Ernest Albert; 3 June 1865 20 January 1936) was King of the United Kingdom and the British Dominions and Emperor of India from 6 May 1910 until his death in 1936.",
        "frontImg": "1911f.jpg",
        "frontTitle": "GEORGIVS V",
        "frontDescription": "Uncrowned portrait of King George V left legend around.",
        "backImg": "1911b.jpg",
        "backTitle": "incl. Maundy",
        "backDescription": "Crowned denomination dividing date within oak wreath.",
        "bottomContent": "King George V",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 120,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "threepence",
        "typeid": 6,
        "name": "King George V",
        "monarchtype": "George5",
        "year": 1935,
        "tagline": "Threepence",
        "title": "1935 King George V Threepence",
        "description": "George V (George Frederick Ernest Albert; 3 June 1865 20 January 1936) was King of the United Kingdom and the British Dominions and Emperor of India from 6 May 1910 until his death in 1936.",
        "frontImg": "1935f.jpg",
        "frontTitle": "GEORGIVS V",
        "frontDescription": "Modified uncrowned portrait of King George V facing left legend around.",
        "backImg": "1935b.jpg",
        "backTitle": "MacKennal",
        "backDescription": "Three oak sprigs with three acorns denomination above date below.",
        "bottomContent": "King George V",
        "price": "$30.00",
        "ebay": ""
      },
      {
        "id": 121,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "threepence",
        "typeid": 6,
        "name": "King George VI",
        "monarchtype": "George6",
        "year": 1943,
        "tagline": "Threepence",
        "title": "1943 King George VI Threepence",
        "description": "George VI was King of the United Kingdom and the Dominions of the British Commonwealth from 11 December 1936 until his death on 6 February 1952.",
        "frontImg": "1943f.jpg",
        "frontTitle": "GEORGIVS VI",
        "frontDescription": "Uncrowned portrait of King George VI left legend around designer's initials below truncation.",
        "backImg": "1943b.jpg",
        "backTitle": "Paget",
        "backDescription": "Three-headed thrift'plant denomination above date below.",
        "bottomContent": "King George VI",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 122,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "threepence",
        "typeid": 6,
        "name": "King George VI",
        "monarchtype": "George6",
        "year": 1944,
        "tagline": "Threepence",
        "title": "1944 King George VI Threepence",
        "description": "George VI was King of the United Kingdom and the Dominions of the British Commonwealth from 11 December 1936 until his death on 6 February 1952.",
        "frontImg": "1944f.jpg",
        "frontTitle": "GEORGIVS VI",
        "frontDescription": "Uncrowned portrait of King George VI left legend around designer's initials below truncation.",
        "backImg": "1944b.jpg",
        "backTitle": "Paget",
        "backDescription": "Three-headed thrift'plant denomination above date below.",
        "bottomContent": "King George VI",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 123,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "threepence",
        "typeid": 6,
        "name": "Queen Elizabeth II",
        "monarchtype": "Elizabeth2",
        "year": 1953,
        "tagline": "Threepence",
        "title": "1953 Queen Elizabeth II  Threepence",
        "description": "Elizabeth II was Queen of the United Kingdom and other Commonwealth realms from 6 February 1952 until her death in 2022. She was queen regnant of 32 sovereign states over the course of her lifetime and remained the monarch of 15 realms by the time of her death.",
        "frontImg": "1953f.jpg",
        "frontTitle": "Queen Elizabeth II",
        "frontDescription": "Uncrowned portrait of King George VI left legend around designer's initials below truncation.",
        "backImg": "1953b.jpg",
        "backTitle": "House of Windsor",
        "backDescription": "A Tudor portcullis and side chains with crown above dividing value date below.",
        "bottomContent": "Queen Elizabeth II",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 124,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "threepence",
        "typeid": 6,
        "name": "Queen Elizabeth II",
        "monarchtype": "Elizabeth2",
        "year": 1954,
        "tagline": "Threepence",
        "title": "1954 Queen Elizabeth II  Threepence",
        "description": "Elizabeth II was Queen of the United Kingdom and other Commonwealth realms from 6 February 1952 until her death in 2022. She was queen regnant of 32 sovereign states over the course of her lifetime and remained the monarch of 15 realms by the time of her death.",
        "frontImg": "1954f.jpg",
        "frontTitle": "Queen Elizabeth II",
        "frontDescription": "Uncrowned portrait of King George VI left legend around designer's initials below truncation.",
        "backImg": "1954b.jpg",
        "backTitle": "House of Windsor",
        "backDescription": "A Tudor portcullis and side chains with crown above dividing value date below.",
        "bottomContent": "Queen Elizabeth II",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 125,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "threepence",
        "typeid": 6,
        "name": "Queen Elizabeth II",
        "monarchtype": "Elizabeth2",
        "year": 1955,
        "tagline": "Threepence",
        "title": "1955 Queen Elizabeth II  Threepence",
        "description": "Elizabeth II was Queen of the United Kingdom and other Commonwealth realms from 6 February 1952 until her death in 2022. She was queen regnant of 32 sovereign states over the course of her lifetime and remained the monarch of 15 realms by the time of her death.",
        "frontImg": "1955f.jpg",
        "frontTitle": "Queen Elizabeth II",
        "frontDescription": "Uncrowned portrait of King George VI left legend around designer's initials below truncation.",
        "backImg": "1955b.jpg",
        "backTitle": "House of Windsor",
        "backDescription": "A Tudor portcullis and side chains with crown above dividing value date below.",
        "bottomContent": "Queen Elizabeth II",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 126,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "threepence",
        "typeid": 6,
        "name": "Queen Elizabeth II",
        "monarchtype": "Elizabeth2",
        "year": 1956,
        "tagline": "Threepence",
        "title": "1956 Queen Elizabeth II  Threepence",
        "description": "Elizabeth II was Queen of the United Kingdom and other Commonwealth realms from 6 February 1952 until her death in 2022. She was queen regnant of 32 sovereign states over the course of her lifetime and remained the monarch of 15 realms by the time of her death.",
        "frontImg": "1956f.jpg",
        "frontTitle": "Queen Elizabeth II",
        "frontDescription": "Uncrowned portrait of King George VI left legend around designer's initials below truncation.",
        "backImg": "1956b.jpg",
        "backTitle": "House of Windsor",
        "backDescription": "A Tudor portcullis and side chains with crown above dividing value date below.",
        "bottomContent": "Queen Elizabeth II",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 127,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "threepence",
        "typeid": 6,
        "name": "Queen Elizabeth II",
        "monarchtype": "Elizabeth2",
        "year": 1957,
        "tagline": "Threepence",
        "title": "1957 Queen Elizabeth II  Threepence",
        "description": "Elizabeth II was Queen of the United Kingdom and other Commonwealth realms from 6 February 1952 until her death in 2022. She was queen regnant of 32 sovereign states over the course of her lifetime and remained the monarch of 15 realms by the time of her death.",
        "frontImg": "1957f.jpg",
        "frontTitle": "Queen Elizabeth II",
        "frontDescription": "Uncrowned portrait of King George VI left legend around designer's initials below truncation.",
        "backImg": "1957b.jpg",
        "backTitle": "House of Windsor",
        "backDescription": "A Tudor portcullis and side chains with crown above dividing value date below.",
        "bottomContent": "Queen Elizabeth II",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 128,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "threepence",
        "typeid": 6,
        "name": "Queen Elizabeth II",
        "monarchtype": "Elizabeth2",
        "year": 1958,
        "tagline": "Threepence",
        "title": "1958 Queen Elizabeth II  Threepence",
        "description": "Elizabeth II was Queen of the United Kingdom and other Commonwealth realms from 6 February 1952 until her death in 2022. She was queen regnant of 32 sovereign states over the course of her lifetime and remained the monarch of 15 realms by the time of her death.",
        "frontImg": "1958f.jpg",
        "frontTitle": "Queen Elizabeth II",
        "frontDescription": "Uncrowned portrait of King George VI left legend around designer's initials below truncation.",
        "backImg": "1958b.jpg",
        "backTitle": "House of Windsor",
        "backDescription": "A Tudor portcullis and side chains with crown above dividing value date below.",
        "bottomContent": "Queen Elizabeth II",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 129,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "threepence",
        "typeid": 6,
        "name": "Queen Elizabeth II",
        "monarchtype": "Elizabeth2",
        "year": 1959,
        "tagline": "Threepence",
        "title": "1959 Queen Elizabeth II  Threepence",
        "description": "Elizabeth II was Queen of the United Kingdom and other Commonwealth realms from 6 February 1952 until her death in 2022. She was queen regnant of 32 sovereign states over the course of her lifetime and remained the monarch of 15 realms by the time of her death.",
        "frontImg": "1959f.jpg",
        "frontTitle": "Queen Elizabeth II",
        "frontDescription": "Uncrowned portrait of King George VI left legend around designer's initials below truncation.",
        "backImg": "1959b.jpg",
        "backTitle": "House of Windsor",
        "backDescription": "A Tudor portcullis and side chains with crown above dividing value date below.",
        "bottomContent": "Queen Elizabeth II",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 130,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "threepence",
        "typeid": 6,
        "name": "Queen Elizabeth II",
        "monarchtype": "Elizabeth2",
        "year": 1960,
        "tagline": "Threepence",
        "title": "1960 Queen Elizabeth II  Threepence",
        "description": "Elizabeth II was Queen of the United Kingdom and other Commonwealth realms from 6 February 1952 until her death in 2022. She was queen regnant of 32 sovereign states over the course of her lifetime and remained the monarch of 15 realms by the time of her death.",
        "frontImg": "1960f.jpg",
        "frontTitle": "Queen Elizabeth II",
        "frontDescription": "Uncrowned portrait of King George VI left legend around designer's initials below truncation.",
        "backImg": "1960b.jpg",
        "backTitle": "House of Windsor",
        "backDescription": "A Tudor portcullis and side chains with crown above dividing value date below.",
        "bottomContent": "Queen Elizabeth II",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 131,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "threepence",
        "typeid": 6,
        "name": "Queen Elizabeth II",
        "monarchtype": "Elizabeth2",
        "year": 1961,
        "tagline": "Threepence",
        "title": "1961 Queen Elizabeth II  Threepence",
        "description": "Elizabeth II was Queen of the United Kingdom and other Commonwealth realms from 6 February 1952 until her death in 2022. She was queen regnant of 32 sovereign states over the course of her lifetime and remained the monarch of 15 realms by the time of her death.",
        "frontImg": "1961f.jpg",
        "frontTitle": "Queen Elizabeth II",
        "frontDescription": "Uncrowned portrait of King George VI left legend around designer's initials below truncation.",
        "backImg": "1961b.jpg",
        "backTitle": "House of Windsor",
        "backDescription": "A Tudor portcullis and side chains with crown above dividing value date below.",
        "bottomContent": "Queen Elizabeth II",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 132,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "threepence",
        "typeid": 6,
        "name": "Queen Elizabeth II",
        "monarchtype": "Elizabeth2",
        "year": 1962,
        "tagline": "Threepence",
        "title": "1962 Queen Elizabeth II  Threepence",
        "description": "Elizabeth II was Queen of the United Kingdom and other Commonwealth realms from 6 February 1952 until her death in 2022. She was queen regnant of 32 sovereign states over the course of her lifetime and remained the monarch of 15 realms by the time of her death.",
        "frontImg": "1962f.jpg",
        "frontTitle": "Queen Elizabeth II",
        "frontDescription": "Uncrowned portrait of King George VI left legend around designer's initials below truncation.",
        "backImg": "1962b.jpg",
        "backTitle": "House of Windsor",
        "backDescription": "A Tudor portcullis and side chains with crown above dividing value date below.",
        "bottomContent": "Queen Elizabeth II",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 133,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "threepence",
        "typeid": 6,
        "name": "Queen Elizabeth II",
        "monarchtype": "Elizabeth2",
        "year": 1963,
        "tagline": "Threepence",
        "title": "1963 Queen Elizabeth II  Threepence",
        "description": "Elizabeth II was Queen of the United Kingdom and other Commonwealth realms from 6 February 1952 until her death in 2022. She was queen regnant of 32 sovereign states over the course of her lifetime and remained the monarch of 15 realms by the time of her death.",
        "frontImg": "1963f.jpg",
        "frontTitle": "Queen Elizabeth II",
        "frontDescription": "Uncrowned portrait of King George VI left legend around designer's initials below truncation.",
        "backImg": "1963b.jpg",
        "backTitle": "House of Windsor",
        "backDescription": "A Tudor portcullis and side chains with crown above dividing value date below.",
        "bottomContent": "Queen Elizabeth II",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 134,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "threepence",
        "typeid": 6,
        "name": "Queen Elizabeth II",
        "monarchtype": "Elizabeth2",
        "year": 1964,
        "tagline": "Threepence",
        "title": "1964 Queen Elizabeth II  Threepence",
        "description": "Elizabeth II was Queen of the United Kingdom and other Commonwealth realms from 6 February 1952 until her death in 2022. She was queen regnant of 32 sovereign states over the course of her lifetime and remained the monarch of 15 realms by the time of her death.",
        "frontImg": "1964f.jpg",
        "frontTitle": "Queen Elizabeth II",
        "frontDescription": "Uncrowned portrait of King George VI left legend around designer's initials below truncation.",
        "backImg": "1964b.jpg",
        "backTitle": "House of Windsor",
        "backDescription": "A Tudor portcullis and side chains with crown above dividing value date below.",
        "bottomContent": "Queen Elizabeth II",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 135,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "threepence",
        "typeid": 6,
        "name": "Queen Elizabeth II",
        "monarchtype": "Elizabeth2",
        "year": 1965,
        "tagline": "Threepence",
        "title": "1965 Queen Elizabeth II  Threepence",
        "description": "Elizabeth II was Queen of the United Kingdom and other Commonwealth realms from 6 February 1952 until her death in 2022. She was queen regnant of 32 sovereign states over the course of her lifetime and remained the monarch of 15 realms by the time of her death.",
        "frontImg": "1965f.jpg",
        "frontTitle": "Queen Elizabeth II",
        "frontDescription": "Uncrowned portrait of King George VI left legend around designer's initials below truncation.",
        "backImg": "1965b.jpg",
        "backTitle": "House of Windsor",
        "backDescription": "A Tudor portcullis and side chains with crown above dividing value date below.",
        "bottomContent": "Queen Elizabeth II",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 136,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "threepence",
        "typeid": 6,
        "name": "Queen Elizabeth II",
        "monarchtype": "Elizabeth2",
        "year": 1966,
        "tagline": "Threepence",
        "title": "1966 Queen Elizabeth II  Threepence",
        "description": "Elizabeth II was Queen of the United Kingdom and other Commonwealth realms from 6 February 1952 until her death in 2022. She was queen regnant of 32 sovereign states over the course of her lifetime and remained the monarch of 15 realms by the time of her death.",
        "frontImg": "1966f.jpg",
        "frontTitle": "Queen Elizabeth II",
        "frontDescription": "Uncrowned portrait of King George VI left legend around designer's initials below truncation.",
        "backImg": "1966b.jpg",
        "backTitle": "House of Windsor",
        "backDescription": "A Tudor portcullis and side chains with crown above dividing value date below.",
        "bottomContent": "Queen Elizabeth II",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 137,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "threepence",
        "typeid": 6,
        "name": "Queen Elizabeth II",
        "monarchtype": "Elizabeth2",
        "year": 1967,
        "tagline": "Threepence",
        "title": "1967 Queen Elizabeth II  Threepence",
        "description": "Elizabeth II was Queen of the United Kingdom and other Commonwealth realms from 6 February 1952 until her death in 2022. She was queen regnant of 32 sovereign states over the course of her lifetime and remained the monarch of 15 realms by the time of her death.",
        "frontImg": "1967f.jpg",
        "frontTitle": "Queen Elizabeth II",
        "frontDescription": "Uncrowned portrait of King George VI left legend around designer's initials below truncation.",
        "backImg": "1967b.jpg",
        "backTitle": "House of Windsor",
        "backDescription": "A Tudor portcullis and side chains with crown above dividing value date below.",
        "bottomContent": "Queen Elizabeth II",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 138,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "threepence",
        "typeid": 6,
        "name": "Queen Elizabeth II",
        "monarchtype": "Elizabeth2",
        "year": 1960,
        "tagline": "Threepence",
        "title": "1960 Queen Elizabeth II  Threepence",
        "description": "Elizabeth II was Queen of the United Kingdom and other Commonwealth realms from 6 February 1952 until her death in 2022. She was queen regnant of 32 sovereign states over the course of her lifetime and remained the monarch of 15 realms by the time of her death.",
        "frontImg": "19602f.jpg",
        "frontTitle": "Queen Elizabeth II",
        "frontDescription": "Uncrowned portrait of King George VI left legend around designer's initials below truncation.",
        "backImg": "19602b.jpg",
        "backTitle": "House of Windsor",
        "backDescription": "A Tudor portcullis and side chains with crown above dividing value date below.",
        "bottomContent": "Queen Elizabeth II",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 139,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "penny",
        "typeid": 7,
        "name": "King William IV",
        "monarchtype": "William4",
        "year": 1831,
        "tagline": "penny",
        "title": "1831 King William IIII penny",
        "description": "William III (William Henry; Dutch: Willem Hendrik; 4 November 1650 8 March 1702)[b] also widely known as William of Orange",
        "frontImg": "1831f.jpg",
        "frontTitle": "GULIELMUS",
        "frontDescription": "Uncrowned portrait of King William IV facing right legend around date below.",
        "backImg": "1831b.jpg",
        "backTitle": "Grace of God",
        "backDescription": "Seated figure of Britannia right with trident in left hand and shield bearing the Union flag in right legend around and national flower emblems in exergue.",
        "bottomContent": "King William III",
        "price": "$70.00",
        "ebay": ""
      },
      {
        "id": 140,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "penny",
        "typeid": 7,
        "name": "Queen Victoria",
        "monarchtype": "Victoria",
        "year": 1854,
        "tagline": "penny",
        "title": "1854 Queen Victoria penny",
        "description": "Victoria was Queen of the United Kingdom of Great Britain and Ireland from 20 June 1837 until her death in 1901. Her reign of 63 years and 216 days which was longer than those of any of her predecessors is known as the Victorian era.",
        "frontImg": "1854f.jpg",
        "frontTitle": "VICTORIA",
        "frontDescription": "First uncrowned bust (Young Head) of Queen Victoria left legend around date below.",
        "backImg": "1854b.jpg",
        "backTitle": "Grace of God",
        "backDescription": "Seated figure of Britannia facing right trident in left hand and shield bearing the Union flag in right legend around and national flower emblems in exergue.",
        "bottomContent": "Queen Victoria",
        "price": "$60.00",
        "ebay": ""
      },
      {
        "id": 141,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "penny",
        "typeid": 7,
        "name": "Queen Victoria",
        "monarchtype": "Victoria",
        "year": 1888,
        "tagline": "penny",
        "title": "1888 Queen Victoria penny",
        "description": "Victoria was Queen of the United Kingdom of Great Britain and Ireland from 20 June 1837 until her death in 1901. Her reign of 63 years and 216 days which was longer than those of any of her predecessors is known as the Victorian era.",
        "frontImg": "1888f.jpg",
        "frontTitle": "VICTORIA",
        "frontDescription": "Second laureate portrait (Bun Head) of Queen Victoria facing left legend around.",
        "backImg": "1888b.jpg",
        "backTitle": "Grace of God",
        "backDescription": "Seated figure of Britannia right with trident in left hand and shield bearing the Union flag in right sea behind with lighthouse on left and ship on right denomination above and date in exergue.",
        "bottomContent": "Queen Victoria",
        "price": "$40.00",
        "ebay": ""
      },
      {
        "id": 142,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "penny",
        "typeid": 7,
        "name": "Queen Victoria",
        "monarchtype": "Victoria",
        "year": 1889,
        "tagline": "penny",
        "title": "1889 Queen Victoria penny",
        "description": "Victoria was Queen of the United Kingdom of Great Britain and Ireland from 20 June 1837 until her death in 1901. Her reign of 63 years and 216 days which was longer than those of any of her predecessors is known as the Victorian era.",
        "frontImg": "1889f.jpg",
        "frontTitle": "VICTORIA",
        "frontDescription": "Second laureate portrait (Bun Head) of Queen Victoria facing left legend around.",
        "backImg": "1889b.jpg",
        "backTitle": "Grace of God",
        "backDescription": "Seated figure of Britannia right with trident in left hand and shield bearing the Union flag in right sea behind with lighthouse on left and ship on right denomination above and date in exergue.",
        "bottomContent": "Queen Victoria",
        "price": "$40.00",
        "ebay": ""
      },
      {
        "id": 143,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "penny",
        "typeid": 7,
        "name": "Queen Victoria",
        "monarchtype": "Victoria",
        "year": 1896,
        "tagline": "penny",
        "title": "1896 Queen Victoria penny",
        "description": "Victoria was Queen of the United Kingdom of Great Britain and Ireland from 20 June 1837 until her death in 1901. Her reign of 63 years and 216 days which was longer than those of any of her predecessors is known as the Victorian era.",
        "frontImg": "1896f.jpg",
        "frontTitle": "VICTORIA",
        "frontDescription": "Older diademed and veiled portrait (Old Head) of Queen Victoria left legend around designer's initials below bust.",
        "backImg": "1896b.jpg",
        "backTitle": "Grace of God",
        "backDescription": "Seated figure of Britannia right with trident in left hand and shield bearing the Union flag in right sea behind denomination above and date in exergue.",
        "bottomContent": "Queen Victoria",
        "price": "$20.00",
        "ebay": ""
      },
      {
        "id": 144,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "penny",
        "typeid": 7,
        "name": "Queen Victoria",
        "monarchtype": "Victoria",
        "year": 1901,
        "tagline": "penny",
        "title": "1901 Queen Victoria penny",
        "description": "Victoria was Queen of the United Kingdom of Great Britain and Ireland from 20 June 1837 until her death in 1901. Her reign of 63 years and 216 days which was longer than those of any of her predecessors is known as the Victorian era.",
        "frontImg": "1901f.jpg",
        "frontTitle": "VICTORIA",
        "frontDescription": "Older diademed and veiled portrait (Old Head) of Queen Victoria left legend around designer's initials below bust.",
        "backImg": "1901b.jpg",
        "backTitle": "Grace of God",
        "backDescription": "Seated figure of Britannia right with trident in left hand and shield bearing the Union flag in right sea behind denomination above and date in exergue.",
        "bottomContent": "Queen Victoria",
        "price": "$15.00",
        "ebay": ""
      },
      {
        "id": 145,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "penny",
        "typeid": 7,
        "name": "King Edward VII",
        "monarchtype": "Edward7",
        "year": 1902,
        "tagline": "penny",
        "title": "1902 King Edward VII  penny",
        "description": "The second child and eldest son of Queen Victoria and Prince Albert of Saxe-Coburg and Gotha Edward nicknamed \"Bertie\" was related to royalty throughout Europe.",
        "frontImg": "1902f.jpg",
        "frontTitle": "EDWARDVS VII",
        "frontDescription": "Uncrowned portrait of King Edward VII right legend around.",
        "backImg": "1902b.jpg",
        "backTitle": "Grace of God",
        "backDescription": "Seated figure of Britannia right with trident in left hand and shield bearing the Union flag in right sea behind denomination above and date in exergue.",
        "bottomContent": "King Edward VII",
        "price": "$12.00",
        "ebay": ""
      },
      {
        "id": 146,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "penny",
        "typeid": 7,
        "name": "King Edward VII",
        "monarchtype": "Edward7",
        "year": 1904,
        "tagline": "penny",
        "title": "1904 King Edward VII  penny",
        "description": "The second child and eldest son of Queen Victoria and Prince Albert of Saxe-Coburg and Gotha Edward nicknamed \"Bertie\" was related to royalty throughout Europe.",
        "frontImg": "1904f.jpg",
        "frontTitle": "EDWARDVS VII",
        "frontDescription": "Uncrowned portrait of King Edward VII right legend around.",
        "backImg": "1904b.jpg",
        "backTitle": "Grace of God",
        "backDescription": "Seated figure of Britannia right with trident in left hand and shield bearing the Union flag in right sea behind denomination above and date in exergue.",
        "bottomContent": "King Edward VII",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 147,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "penny",
        "typeid": 7,
        "name": "King Edward VII",
        "monarchtype": "Edward7",
        "year": 1907,
        "tagline": "penny",
        "title": "1907 King Edward VII  penny",
        "description": "The second child and eldest son of Queen Victoria and Prince Albert of Saxe-Coburg and Gotha Edward nicknamed \"Bertie\" was related to royalty throughout Europe.",
        "frontImg": "1907f.jpg",
        "frontTitle": "EDWARDVS VII",
        "frontDescription": "Uncrowned portrait of King Edward VII right legend around.",
        "backImg": "1907b.jpg",
        "backTitle": "Grace of God",
        "backDescription": "Seated figure of Britannia right with trident in left hand and shield bearing the Union flag in right sea behind denomination above and date in exergue.",
        "bottomContent": "King Edward VII",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 148,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "penny",
        "typeid": 7,
        "name": "King George V",
        "monarchtype": "George5",
        "year": 1911,
        "tagline": "penny",
        "title": "1911 King George V penny",
        "description": "George V (George Frederick Ernest Albert; 3 June 1865 20 January 1936) was King of the United Kingdom and the British Dominions and Emperor of India from 6 May 1910 until his death in 1936.",
        "frontImg": "1911f.jpg",
        "frontTitle": "GEORGIVS V",
        "frontDescription": "Uncrowned portrait of King George V facing left legend around",
        "backImg": "1911b.jpg",
        "backTitle": "Grace of God",
        "backDescription": "Seated figure of Britannia right with trident in left hand and shield bearing the Union flag in right sea behind denomination above and date in exergue.",
        "bottomContent": "King George V",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 149,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "penny",
        "typeid": 7,
        "name": "King George V",
        "monarchtype": "George5",
        "year": 1912,
        "tagline": "penny",
        "title": "1912 King George V penny",
        "description": "George V (George Frederick Ernest Albert; 3 June 1865 20 January 1936) was King of the United Kingdom and the British Dominions and Emperor of India from 6 May 1910 until his death in 1936.",
        "frontImg": "1912f.jpg",
        "frontTitle": "GEORGIVS V",
        "frontDescription": "Uncrowned portrait of King George V facing left legend around",
        "backImg": "1912b.jpg",
        "backTitle": "Grace of God",
        "backDescription": "Seated figure of Britannia right with trident in left hand and shield bearing the Union flag in right sea behind denomination above and date in exergue.",
        "bottomContent": "King George V",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 150,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "penny",
        "typeid": 7,
        "name": "King George V",
        "monarchtype": "George5",
        "year": 1913,
        "tagline": "penny",
        "title": "1913 King George V penny",
        "description": "George V (George Frederick Ernest Albert; 3 June 1865 20 January 1936) was King of the United Kingdom and the British Dominions and Emperor of India from 6 May 1910 until his death in 1936.",
        "frontImg": "1913f.jpg",
        "frontTitle": "GEORGIVS V",
        "frontDescription": "Uncrowned portrait of King George V facing left legend around",
        "backImg": "1913b.jpg",
        "backTitle": "Grace of God",
        "backDescription": "Seated figure of Britannia right with trident in left hand and shield bearing the Union flag in right sea behind denomination above and date in exergue.",
        "bottomContent": "King George V",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 151,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "penny",
        "typeid": 7,
        "name": "King George V",
        "monarchtype": "George5",
        "year": 1917,
        "tagline": "penny",
        "title": "1917 King George V penny",
        "description": "George V (George Frederick Ernest Albert; 3 June 1865 20 January 1936) was King of the United Kingdom and the British Dominions and Emperor of India from 6 May 1910 until his death in 1936.",
        "frontImg": "1917f.jpg",
        "frontTitle": "GEORGIVS V",
        "frontDescription": "Uncrowned portrait of King George V facing left legend around",
        "backImg": "1917b.jpg",
        "backTitle": "Grace of God",
        "backDescription": "Seated figure of Britannia right with trident in left hand and shield bearing the Union flag in right sea behind denomination above and date in exergue.",
        "bottomContent": "King George V",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 152,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "penny",
        "typeid": 7,
        "name": "King George V",
        "monarchtype": "George5",
        "year": 1918,
        "tagline": "penny",
        "title": "1918 King George V penny",
        "description": "George V (George Frederick Ernest Albert; 3 June 1865 20 January 1936) was King of the United Kingdom and the British Dominions and Emperor of India from 6 May 1910 until his death in 1936.",
        "frontImg": "1918f.jpg",
        "frontTitle": "GEORGIVS V",
        "frontDescription": "Uncrowned portrait of King George V facing left legend around",
        "backImg": "1918b.jpg",
        "backTitle": "Grace of God",
        "backDescription": "Seated figure of Britannia right with trident in left hand and shield bearing the Union flag in right sea behind denomination above and date in exergue.",
        "bottomContent": "King George V",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 153,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "penny",
        "typeid": 7,
        "name": "King George V",
        "monarchtype": "George5",
        "year": 1919,
        "tagline": "penny",
        "title": "1919 King George V penny",
        "description": "George V (George Frederick Ernest Albert; 3 June 1865 20 January 1936) was King of the United Kingdom and the British Dominions and Emperor of India from 6 May 1910 until his death in 1936.",
        "frontImg": "1919f.jpg",
        "frontTitle": "GEORGIVS V",
        "frontDescription": "Uncrowned portrait of King George V facing left legend around",
        "backImg": "1919b.jpg",
        "backTitle": "Grace of God",
        "backDescription": "Seated figure of Britannia right with trident in left hand and shield bearing the Union flag in right sea behind denomination above and date in exergue.",
        "bottomContent": "King George V",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 154,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "penny",
        "typeid": 7,
        "name": "King George V",
        "monarchtype": "George5",
        "year": 1930,
        "tagline": "penny",
        "title": "1930 King George V penny",
        "description": "George V (George Frederick Ernest Albert; 3 June 1865 20 January 1936) was King of the United Kingdom and the British Dominions and Emperor of India from 6 May 1910 until his death in 1936.",
        "frontImg": "1930f.jpg",
        "frontTitle": "GEORGIVS V",
        "frontDescription": "Uncrowned portrait of King George V facing left legend around",
        "backImg": "1930b.jpg",
        "backTitle": "Grace of God",
        "backDescription": "Seated figure of Britannia right with trident in left hand and shield bearing the Union flag in right sea behind denomination above and date in exergue.",
        "bottomContent": "King George V",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 155,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "penny",
        "typeid": 7,
        "name": "King George V",
        "monarchtype": "George5",
        "year": 1936,
        "tagline": "penny",
        "title": "1936 King George V penny",
        "description": "George V (George Frederick Ernest Albert; 3 June 1865 20 January 1936) was King of the United Kingdom and the British Dominions and Emperor of India from 6 May 1910 until his death in 1936.",
        "frontImg": "1936f.jpg",
        "frontTitle": "GEORGIVS V",
        "frontDescription": "Uncrowned portrait of King George V facing left legend around",
        "backImg": "1936b.jpg",
        "backTitle": "Grace of God",
        "backDescription": "Seated figure of Britannia right with trident in left hand and shield bearing the Union flag in right sea behind denomination above and date in exergue.",
        "bottomContent": "King George V",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 156,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "penny",
        "typeid": 7,
        "name": "King George VI",
        "monarchtype": "George6",
        "year": 1938,
        "tagline": "penny",
        "title": "1938 King George VI penny",
        "description": "George VI was King of the United Kingdom and the Dominions of the British Commonwealth from 11 December 1936 until his death on 6 February 1952.",
        "frontImg": "1938f.jpg",
        "frontTitle": "GEORGIVS VI",
        "frontDescription": "Uncrowned portrait of King George VI left legend around.",
        "backImg": "1938b.jpg",
        "backTitle": "Grace of God",
        "backDescription": "Seated figure of Britannia right with trident in left hand and shield bearing the Union flag in right sea behind with lighthouse to left denomination above and date in exergue.",
        "bottomContent": "King George VI",
        "price": "$5.00",
        "ebay": ""
      },
      {
        "id": 157,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "penny",
        "typeid": 7,
        "name": "King George VI",
        "monarchtype": "George6",
        "year": 1946,
        "tagline": "penny",
        "title": "1946 King George VI penny",
        "description": "George VI was King of the United Kingdom and the Dominions of the British Commonwealth from 11 December 1936 until his death on 6 February 1952.",
        "frontImg": "1946f.jpg",
        "frontTitle": "GEORGIVS VI",
        "frontDescription": "Uncrowned portrait of King George VI left legend around.",
        "backImg": "1946b.jpg",
        "backTitle": "Grace of God",
        "backDescription": "Seated figure of Britannia right with trident in left hand and shield bearing the Union flag in right sea behind with lighthouse to left denomination above and date in exergue.",
        "bottomContent": "King George VI",
        "price": "$5.00",
        "ebay": ""
      },
      {
        "id": 158,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "penny",
        "typeid": 7,
        "name": "King George VI",
        "monarchtype": "George6",
        "year": 1947,
        "tagline": "penny",
        "title": "1947 King George VI penny",
        "description": "George VI was King of the United Kingdom and the Dominions of the British Commonwealth from 11 December 1936 until his death on 6 February 1952.",
        "frontImg": "1947f.jpg",
        "frontTitle": "GEORGIVS VI",
        "frontDescription": "Uncrowned portrait of King George VI left legend around.",
        "backImg": "1947b.jpg",
        "backTitle": "Grace of God",
        "backDescription": "Seated figure of Britannia right with trident in left hand and shield bearing the Union flag in right sea behind with lighthouse to left denomination above and date in exergue.",
        "bottomContent": "King George VI",
        "price": "$5.00",
        "ebay": ""
      },
      {
        "id": 159,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "penny",
        "typeid": 7,
        "name": "Queen Elizabeth II",
        "monarchtype": "Elizabeth2",
        "year": 1963,
        "tagline": "penny",
        "title": "1963 Queen Elizabeth II  penny",
        "description": "Elizabeth II was Queen of the United Kingdom and other Commonwealth realms from 6 February 1952 until her death in 2022. She was queen regnant of 32 sovereign states over the course of her lifetime and remained the monarch of 15 realms by the time of her death.",
        "frontImg": "1963f.jpg",
        "frontTitle": "Queen Elizabeth II",
        "frontDescription": "Young laureate portrait of Queen Elizabeth II facing right legend around.",
        "backImg": "1963b.jpg",
        "backTitle": "Grace of God",
        "backDescription": "Seated figure of Britannia right with trident in left hand and shield bearing the Union flag in right sea behind with lighthouse to left denomination above and date in exergue.",
        "bottomContent": "Queen Elizabeth II",
        "price": "$5.00",
        "ebay": ""
      },
      {
        "id": 160,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "penny",
        "typeid": 7,
        "name": "King George V",
        "monarchtype": "George5",
        "year": 1936,
        "tagline": "penny",
        "title": "1936 King George V penny",
        "description": "George V (George Frederick Ernest Albert; 3 June 1865 20 January 1936) was King of the United Kingdom and the British Dominions and Emperor of India from 6 May 1910 until his death in 1936.",
        "frontImg": "19362f.jpg",
        "frontTitle": "GEORGIVS V",
        "frontDescription": "Uncrowned portrait of King George V facing left legend around",
        "backImg": "19362b.jpg",
        "backTitle": "Grace of God",
        "backDescription": "Seated figure of Britannia right with trident in left hand and shield bearing the Union flag in right sea behind denomination above and date in exergue.",
        "bottomContent": "King George V",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 161,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "halfpenny",
        "typeid": 8,
        "name": "William and Mary",
        "monarchtype": "WilliamAndMary",
        "year": 1694,
        "tagline": "halfpenny",
        "title": "1694 William and Mary halfpenny",
        "description": "House of Stuart restored (1660-1714) William and Mary (1689-94)",
        "frontImg": "1694f.jpg",
        "frontTitle": "GVLIELMVS·ET·MARIA",
        "frontDescription": "Laureate and cuirassed conjoined busts of King William and Queen Mary right legend around.",
        "backImg": "1694b.jpg",
        "backTitle": "BRITAN",
        "backDescription": "Seated figure of Britannia left with olive branch in raised right hand spear in left shield bearing the Union flag resting at left legend around date in exergue.",
        "bottomContent": "William and Mary",
        "price": "$75.00",
        "ebay": ""
      },
      {
        "id": 162,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "halfpenny",
        "typeid": 8,
        "name": "King George II",
        "monarchtype": "George2",
        "year": 1749,
        "tagline": "halfpenny",
        "title": "1749 King George II halfpenny",
        "description": "George II (George Augustus; German: Georg August; 30 October / 9 November 1683[a] 25 October 1760) was King of Great Britain and Ireland Duke of Brunswick-Lüneburg (Hanover) and a prince-elector of the Holy Roman Empire from 11 June 1727 (O.S.) until his death in 1760.",
        "frontImg": "1749f.jpg",
        "frontTitle": "GEORGIUS· II",
        "frontDescription": "Older laureate and cuirassed portrait of King George II facing left legend around.",
        "backImg": "1749b.jpg",
        "backTitle": "BRITAN",
        "backDescription": "Seated figure of Britannia facing left trident in left hand olive branch in right shield with Union flag resting on left legend around date in exergue.",
        "bottomContent": "King George II",
        "price": "$100.00",
        "ebay": ""
      },
      {
        "id": 163,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "halfpenny",
        "typeid": 8,
        "name": "King George II",
        "monarchtype": "George2",
        "year": 1754,
        "tagline": "halfpenny",
        "title": "1754 King George II halfpenny",
        "description": "George II (George Augustus; German: Georg August; 30 October / 9 November 1683[a] 25 October 1760) was King of Great Britain and Ireland Duke of Brunswick-Lüneburg (Hanover) and a prince-elector of the Holy Roman Empire from 11 June 1727 (O.S.) until his death in 1760.",
        "frontImg": "1754f.jpg",
        "frontTitle": "GEORGIUS· II",
        "frontDescription": "Older laureate and cuirassed portrait of King George II facing left legend around.",
        "backImg": "1754b.jpg",
        "backTitle": "BRITAN",
        "backDescription": "Seated figure of Britannia facing left trident in left hand olive branch in right shield with Union flag resting on left legend around date in exergue.",
        "bottomContent": "King George II",
        "price": "$150.00",
        "ebay": ""
      },
      {
        "id": 164,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "halfpenny",
        "typeid": 8,
        "name": "King George III",
        "monarchtype": "George3",
        "year": 1799,
        "tagline": "halfpenny",
        "title": "1799 King George III halfpenny",
        "description": "George III was King of Great Britain and Ireland from 25 October 1760 until his death in 1820. The Acts of Union 1800 unified Great Britain and Ireland into the United Kingdom of Great Britain and Ireland with George as its king.",
        "frontImg": "1799f.jpg",
        "frontTitle": "GEORGIUS III",
        "frontDescription": "Laureate and draped bust of King George III facing right legend around",
        "backImg": "1799b.jpg",
        "backTitle": "BRITANNIA",
        "backDescription": "Seated figure of Britannia facing left trident in left hand olive branch in right shield with Union flag resting on left legend around date in exergue.",
        "bottomContent": "King George III",
        "price": "$40.00",
        "ebay": ""
      },
      {
        "id": 165,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "halfpenny",
        "typeid": 8,
        "name": "King George III",
        "monarchtype": "George3",
        "year": 1806,
        "tagline": "halfpenny",
        "title": "1806 King George III halfpenny",
        "description": "George III was King of Great Britain and Ireland from 25 October 1760 until his death in 1820. The Acts of Union 1800 unified Great Britain and Ireland into the United Kingdom of Great Britain and Ireland with George as its king.",
        "frontImg": "1806f.jpg",
        "frontTitle": "GEORGIUS III",
        "frontDescription": "Shorter haired laureate and draped bust of King George III facing right legend around date below.",
        "backImg": "1806b.jpg",
        "backTitle": "BRITANNIA",
        "backDescription": "Seated figure of Britannia facing left trident in left hand olive branch in right shield with Union flag resting on left legend around date in exergue.",
        "bottomContent": "King George III",
        "price": "$50.00",
        "ebay": ""
      },
      {
        "id": 166,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "halfpenny",
        "typeid": 8,
        "name": "King George IIII",
        "monarchtype": "George4",
        "year": 1826,
        "tagline": "halfpenny",
        "title": "1826 King George IIII halfpenny",
        "description": "George IV was the eldest child of King George III and Queen Charlotte. He led an extravagant lifestyle that contributed to the fashions of the Regency era. He was a patron of new forms of leisure style and taste.",
        "frontImg": "1826f.jpg",
        "frontTitle": "GEORGIUS IV",
        "frontDescription": "Laureate portrait of King George IV facing left legend around date below.",
        "backImg": "1826b.jpg",
        "backTitle": "Grace of God",
        "backDescription": "Seated figure of Britannia right with trident in left hand and shield bearing the Union flag in right legend around and national flower emblems in exergue.",
        "bottomContent": "King George IIII",
        "price": "$40.00",
        "ebay": ""
      },
      {
        "id": 167,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "halfpenny",
        "typeid": 8,
        "name": "King William III",
        "monarchtype": "William4",
        "year": 1837,
        "tagline": "halfpenny",
        "title": "1837 King William IIII halfpenny",
        "description": "King William IV also known as the Sailor King only ruled from 1830-1837",
        "frontImg": "1837f.jpg",
        "frontTitle": "GULIELMUS",
        "frontDescription": "Laureate portrait of King George IV facing left legend around date below.",
        "backImg": "1837b.jpg",
        "backTitle": "Grace of God",
        "backDescription": "Seated figure of Britannia right with trident in left hand and shield bearing the Union flag in right legend around and national flower emblems in exergue.",
        "bottomContent": "King William III",
        "price": "$300.00",
        "ebay": ""
      },
      {
        "id": 168,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "halfpenny",
        "typeid": 8,
        "name": "Queen Victoria",
        "monarchtype": "Victoria",
        "year": 1853,
        "tagline": "halfpenny",
        "title": "1853 Queen Victoria halfpenny",
        "description": "Victoria was Queen of the United Kingdom of Great Britain and Ireland from 20 June 1837 until her death in 1901. Her reign of 63 years and 216 days which was longer than those of any of her predecessors is known as the Victorian era.",
        "frontImg": "1853f.jpg",
        "frontTitle": "VICTORIA",
        "frontDescription": "First uncrowned bust (Young Head) of Queen Victoria left legend around date below.",
        "backImg": "1853b.jpg",
        "backTitle": "Grace of God",
        "backDescription": "Seated figure of Britannia right with trident in left hand and shield bearing the Union flag in right legend around and national flower emblems in exergue.",
        "bottomContent": "Queen Victoria",
        "price": "$40.00",
        "ebay": ""
      },
      {
        "id": 169,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "halfpenny",
        "typeid": 8,
        "name": "Queen Victoria",
        "monarchtype": "Victoria",
        "year": 1887,
        "tagline": "halfpenny",
        "title": "1887 Queen Victoria halfpenny",
        "description": "Victoria was Queen of the United Kingdom of Great Britain and Ireland from 20 June 1837 until her death in 1901. Her reign of 63 years and 216 days which was longer than those of any of her predecessors is known as the Victorian era.",
        "frontImg": "1887f.jpg",
        "frontTitle": "VICTORIA",
        "frontDescription": "Second laureate portrait (Bun Head) of Queen Victoria facing left legend around.",
        "backImg": "1887b.jpg",
        "backTitle": "Grace of God",
        "backDescription": "Seated figure of Britannia right trident in left hand shield bearing the Union flag in right denomination around date in exergue sea behind with lighthouse on left and ship on right",
        "bottomContent": "Queen Victoria",
        "price": "$150.00",
        "ebay": ""
      },
      {
        "id": 170,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "halfpenny",
        "typeid": 8,
        "name": "Queen Victoria",
        "monarchtype": "Victoria",
        "year": 1898,
        "tagline": "halfpenny",
        "title": "1898 Queen Victoria halfpenny",
        "description": "Victoria was Queen of the United Kingdom of Great Britain and Ireland from 20 June 1837 until her death in 1901. Her reign of 63 years and 216 days which was longer than those of any of her predecessors is known as the Victorian era.",
        "frontImg": "1898f.jpg",
        "frontTitle": "VICTORIA",
        "frontDescription": "Older diademed and veiled portrait (Old Head) of Queen Victoria left legend around designer's initials below bust.",
        "backImg": "1898b.jpg",
        "backTitle": "Grace of God",
        "backDescription": "Seated figure of Britannia right trident in left hand shield bearing the Union flag in right denomination around date in exergue sea behind with lighthouse on left and ship on right",
        "bottomContent": "Queen Victoria",
        "price": "$40.00",
        "ebay": ""
      },
      {
        "id": 171,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "halfpenny",
        "typeid": 8,
        "name": "King Edward VII",
        "monarchtype": "Edward7",
        "year": 1902,
        "tagline": "halfpenny",
        "title": "1902 King Edward VII  halfpenny",
        "description": "The second child and eldest son of Queen Victoria and Prince Albert of Saxe-Coburg and Gotha Edward nicknamed \"Bertie\" was related to royalty throughout Europe.",
        "frontImg": "1902f.jpg",
        "frontTitle": "EDWARDVS VII",
        "frontDescription": "Uncrowned portrait of King Edward VII facing right legend around.",
        "backImg": "1902b.jpg",
        "backTitle": "Grace of God",
        "backDescription": "Seated figure of Britannia right trident in left hand shield bearing the Union flag in right denomination around date in exergue sea behind.",
        "bottomContent": "King Edward VII",
        "price": "$30.00",
        "ebay": ""
      },
      {
        "id": 172,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "halfpenny",
        "typeid": 8,
        "name": "King George V",
        "monarchtype": "George5",
        "year": 1911,
        "tagline": "halfpenny",
        "title": "1911 King George V halfpenny",
        "description": "George V (George Frederick Ernest Albert; 3 June 1865 20 January 1936) was King of the United Kingdom and the British Dominions and Emperor of India from 6 May 1910 until his death in 1936.",
        "frontImg": "1911f.jpg",
        "frontTitle": "GEORGIVS V",
        "frontDescription": "Uncrowned portrait of King George V left legend around.",
        "backImg": "1911b.jpg",
        "backTitle": "Grace of God",
        "backDescription": "Seated figure of Britannia right trident in left hand shield bearing the Union flag in right denomination around date in exergue sea behind.",
        "bottomContent": "King George V",
        "price": "$20.00",
        "ebay": ""
      },
      {
        "id": 173,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "halfpenny",
        "typeid": 8,
        "name": "King George V",
        "monarchtype": "George5",
        "year": 1921,
        "tagline": "halfpenny",
        "title": "1921 King George V halfpenny",
        "description": "George V (George Frederick Ernest Albert; 3 June 1865 20 January 1936) was King of the United Kingdom and the British Dominions and Emperor of India from 6 May 1910 until his death in 1936.",
        "frontImg": "1921f.jpg",
        "frontTitle": "GEORGIVS V",
        "frontDescription": "Uncrowned portrait of King George V left legend around.",
        "backImg": "1921b.jpg",
        "backTitle": "Grace of God",
        "backDescription": "Seated figure of Britannia right trident in left hand shield bearing the Union flag in right denomination around date in exergue sea behind.",
        "bottomContent": "King George V",
        "price": "$20.00",
        "ebay": ""
      },
      {
        "id": 174,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "halfpenny",
        "typeid": 8,
        "name": "King George VI",
        "monarchtype": "George6",
        "year": 1948,
        "tagline": "halfpenny",
        "title": "1948 King George VI halfpenny",
        "description": "George VI was King of the United Kingdom and the Dominions of the British Commonwealth from 11 December 1936 until his death on 6 February 1952.",
        "frontImg": "1948f.jpg",
        "frontTitle": "GEORGIVS VI",
        "frontDescription": "Head left",
        "backImg": "1948b.jpg",
        "backTitle": "Grace of God",
        "backDescription": "The Golden Hind",
        "bottomContent": "",
        "price": "$15.00",
        "ebay": ""
      },
      {
        "id": 175,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "farthing",
        "typeid": 8,
        "name": "Queen Elizabeth II",
        "monarchtype": "Elizabeth2",
        "year": 1954,
        "tagline": "halfpenny",
        "title": "1954 Queen Elizabeth II  halfpenny",
        "description": "Elizabeth II was Queen of the United Kingdom and other Commonwealth realms from 6 February 1952 until her death in 2022. She was queen regnant of 32 sovereign states over the course of her lifetime and remained the monarch of 15 realms by the time of her death.",
        "frontImg": "1954f.jpg",
        "frontTitle": "Queen Elizabeth II",
        "frontDescription": "Young laureate bust of Queen Elizabeth II right legend around.",
        "backImg": "1954b.jpg",
        "backTitle": "Grace of God",
        "backDescription": "Three-masted ship sailing left denomination above date below.",
        "bottomContent": "Queen Elizabeth II",
        "price": "$15.00",
        "ebay": ""
      },
      {
        "id": 176,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "farthing",
        "typeid": 9,
        "name": "King George III",
        "monarchtype": "George3",
        "year": 1806,
        "tagline": "farthing",
        "title": "1806 King George III farthing",
        "description": "George III was King of Great Britain and Ireland from 25 October 1760 until his death in 1820. The Acts of Union 1800 unified Great Britain and Ireland into the United Kingdom of Great Britain and Ireland with George as its king.",
        "frontImg": "1806f.jpg",
        "frontTitle": "GEORGIUS III",
        "frontDescription": "Shorter haired laureate and draped bust of King George III facing right legend around date below.",
        "backImg": "1806b.jpg",
        "backTitle": "BRITANNIA",
        "backDescription": "Seated figure of Britannia facing left trident in left hand shield with Union flag resting on left olive branch in raised right hand legend above sea behind with ship on left.",
        "bottomContent": "King George III",
        "price": "$30.00",
        "ebay": ""
      },
      {
        "id": 177,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "farthing",
        "typeid": 9,
        "name": "King William IV",
        "monarchtype": "William4",
        "year": 1837,
        "tagline": "farthing",
        "title": "1837 King William III  farthing",
        "description": "King William IV also known as the Sailor King only ruled from 1830-1837",
        "frontImg": "1837f.jpg",
        "frontTitle": "GULIELMUS IIII",
        "frontDescription": "Uncrowned portrait of King William IV facing right legend around date below.",
        "backImg": "1837b.jpg",
        "backTitle": "BRITANNIAR",
        "backDescription": "Seated figure of Britannia facing right trident in left hand shield with Union flag in right legend around national flower emblems in exergue.",
        "bottomContent": "King William III",
        "price": "$40.00",
        "ebay": ""
      },
      {
        "id": 178,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "farthing",
        "typeid": 9,
        "name": "Queen Victoria",
        "monarchtype": "Victoria",
        "year": 1875,
        "tagline": "farthing",
        "title": "1875 Queen Victoria farthing",
        "description": "Victoria was Queen of the United Kingdom of Great Britain and Ireland from 20 June 1837 until her death in 1901. Her reign of 63 years and 216 days which was longer than those of any of her predecessors is known as the Victorian era.",
        "frontImg": "1875f.jpg",
        "frontTitle": "VICTORIA",
        "frontDescription": "Second laureate portrait (Bun Head) of Queen Victoria facing left legend around.",
        "backImg": "1875b.jpg",
        "backTitle": "BRITANNIA",
        "backDescription": "Seated figure of Britannia facing right trident in left hand shield with Union flag in right hand denomination above date in exergue sea behind with lighthouse on left and ship on right.",
        "bottomContent": "Queen Victoria",
        "price": "$20.00",
        "ebay": ""
      },
      {
        "id": 179,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "farthing",
        "typeid": 9,
        "name": "Queen Victoria",
        "monarchtype": "Victoria",
        "year": 1901,
        "tagline": "farthing",
        "title": "1901 Queen Victoria farthing",
        "description": "Victoria was Queen of the United Kingdom of Great Britain and Ireland from 20 June 1837 until her death in 1901. Her reign of 63 years and 216 days which was longer than those of any of her predecessors is known as the Victorian era.",
        "frontImg": "1901f.jpg",
        "frontTitle": "VICTORIA",
        "frontDescription": "Older diademed and veiled portrait (Old Head) of Queen Victoria left legend around designer's initials below bust.",
        "backImg": "1901b.jpg",
        "backTitle": "BRITANNIA",
        "backDescription": "Seated figure of Britannia right trident in left hand shield with Union flag in right hand denomination above sea behind date in exergue.",
        "bottomContent": "Queen Victoria",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 180,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "farthing",
        "typeid": 9,
        "name": "King Edward VII",
        "monarchtype": "Edward7",
        "year": 1907,
        "tagline": "farthing",
        "title": "1907 King Edward VII  farthing",
        "description": "The second child and eldest son of Queen Victoria and Prince Albert of Saxe-Coburg and Gotha Edward nicknamed \"Bertie\" was related to royalty throughout Europe.",
        "frontImg": "1907f.jpg",
        "frontTitle": "EDWARDVS VII",
        "frontDescription": "Uncrowned portrait of King Edward VII facing right legend around.",
        "backImg": "1907b.jpg",
        "backTitle": "BRITANNIA",
        "backDescription": "Seated figure of Britannia facing right trident in left hand shield with Union flag in right hand denomination above sea behind date in",
        "bottomContent": "King Edward VII",
        "price": "$5.00",
        "ebay": ""
      },
      {
        "id": 181,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "farthing",
        "typeid": 9,
        "name": "King Edward VII",
        "monarchtype": "Edward7",
        "year": 1909,
        "tagline": "farthing",
        "title": "1909 King Edward VII  farthing",
        "description": "The second child and eldest son of Queen Victoria and Prince Albert of Saxe-Coburg and Gotha Edward nicknamed \"Bertie\" was related to royalty throughout Europe.",
        "frontImg": "1909f.jpg",
        "frontTitle": "EDWARDVS VII",
        "frontDescription": "Uncrowned portrait of King Edward VII facing right legend around.",
        "backImg": "1909b.jpg",
        "backTitle": "BRITANNIA",
        "backDescription": "Seated figure of Britannia facing right trident in left hand shield with Union flag in right hand denomination above sea behind date in",
        "bottomContent": "King Edward VII",
        "price": "$5.00",
        "ebay": ""
      },
      {
        "id": 182,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "farthing",
        "typeid": 9,
        "name": "King George V",
        "monarchtype": "George5",
        "year": 1911,
        "tagline": "farthing",
        "title": "1911 King George V farthing",
        "description": "George V (George Frederick Ernest Albert; 3 June 1865 20 January 1936) was King of the United Kingdom and the British Dominions and Emperor of India from 6 May 1910 until his death in 1936.",
        "frontImg": "1911f.jpg",
        "frontTitle": "GEORGIVS V",
        "frontDescription": "Uncrowned portrait of King George V left legend around.",
        "backImg": "1911b.jpg",
        "backTitle": "BRITANNIAR",
        "backDescription": "Seated figure of Britannia facing right trident in left hand shield with Union flag in right hand denomination above sea behind date in",
        "bottomContent": "King George V",
        "price": "$5.00",
        "ebay": ""
      },
      {
        "id": 183,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "farthing",
        "typeid": 9,
        "name": "King George V",
        "monarchtype": "George5",
        "year": 1929,
        "tagline": "farthing",
        "title": "1929 King George V farthing",
        "description": "George V (George Frederick Ernest Albert; 3 June 1865 20 January 1936) was King of the United Kingdom and the British Dominions and Emperor of India from 6 May 1910 until his death in 1936.",
        "frontImg": "1929f.jpg",
        "frontTitle": "GEORGIVS V",
        "frontDescription": "Uncrowned portrait of King George V left legend around.",
        "backImg": "1929b.jpg",
        "backTitle": "BRITANNIAR",
        "backDescription": "Seated figure of Britannia facing right trident in left hand shield with Union flag in right hand denomination above sea behind date in",
        "bottomContent": "King George V",
        "price": "$5.00",
        "ebay": ""
      },
      {
        "id": 184,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "farthing",
        "typeid": 9,
        "name": "King George V",
        "monarchtype": "George5",
        "year": 1935,
        "tagline": "farthing",
        "title": "1935 King George V farthing",
        "description": "George V (George Frederick Ernest Albert; 3 June 1865 20 January 1936) was King of the United Kingdom and the British Dominions and Emperor of India from 6 May 1910 until his death in 1936.",
        "frontImg": "1935f.jpg",
        "frontTitle": "GEORGIVS V",
        "frontDescription": "Uncrowned portrait of King George V left legend around.",
        "backImg": "1935b.jpg",
        "backTitle": "BRITANNIAR",
        "backDescription": "Seated figure of Britannia facing right trident in left hand shield with Union flag in right hand denomination above sea behind date in",
        "bottomContent": "King George V",
        "price": "$5.00",
        "ebay": ""
      },
      {
        "id": 185,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "farthing",
        "typeid": 9,
        "name": "King George VI",
        "monarchtype": "George6",
        "year": 1940,
        "tagline": "farthing",
        "title": "1940 King George VI farthing",
        "description": "George VI was King of the United Kingdom and the Dominions of the British Commonwealth from 11 December 1936 until his death on 6 February 1952.",
        "frontImg": "1940f.jpg",
        "frontTitle": "GEORGIVS VI",
        "frontDescription": "Uncrowned portrait of King George VI facing left",
        "backImg": "1940b.jpg",
        "backTitle": "BRITANNIAR",
        "backDescription": "Seated figure of Britannia facing right trident in left hand shield with Union flag in right hand denomination above sea behind date in",
        "bottomContent": "King George VI",
        "price": "$5.00",
        "ebay": ""
      },
      {
        "id": 186,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "farthing",
        "typeid": 9,
        "name": "King George VI",
        "monarchtype": "George6",
        "year": 1942,
        "tagline": "farthing",
        "title": "1942 King George VI farthing",
        "description": "George VI was King of the United Kingdom and the Dominions of the British Commonwealth from 11 December 1936 until his death on 6 February 1952.",
        "frontImg": "1942f.jpg",
        "frontTitle": "GEORGIVS VI",
        "frontDescription": "Uncrowned portrait of King George VI facing left",
        "backImg": "1942b.jpg",
        "backTitle": "BRITANNIAR",
        "backDescription": "Seated figure of Britannia facing right trident in left hand shield with Union flag in right hand denomination above sea behind date in",
        "bottomContent": "King George VI",
        "price": "$5.00",
        "ebay": ""
      },
      {
        "id": 187,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "farthing",
        "typeid": 9,
        "name": "King George VI",
        "monarchtype": "George6",
        "year": 1943,
        "tagline": "farthing",
        "title": "1943 King George VI farthing",
        "description": "George VI was King of the United Kingdom and the Dominions of the British Commonwealth from 11 December 1936 until his death on 6 February 1952.",
        "frontImg": "1943f.jpg",
        "frontTitle": "GEORGIVS VI",
        "frontDescription": "Uncrowned portrait of King George VI facing left",
        "backImg": "1943b.jpg",
        "backTitle": "BRITANNIAR",
        "backDescription": "Seated figure of Britannia facing right trident in left hand shield with Union flag in right hand denomination above sea behind date in",
        "bottomContent": "King George VI",
        "price": "$5.00",
        "ebay": ""
      },
      {
        "id": 188,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "farthing",
        "typeid": 9,
        "name": "King George VI",
        "monarchtype": "George6",
        "year": 1948,
        "tagline": "farthing",
        "title": "1948 King George VI farthing",
        "description": "George VI was King of the United Kingdom and the Dominions of the British Commonwealth from 11 December 1936 until his death on 6 February 1952.",
        "frontImg": "1948f.jpg",
        "frontTitle": "GEORGIVS VI",
        "frontDescription": "Uncrowned portrait of King George VI facing left",
        "backImg": "1948b.jpg",
        "backTitle": "BRITANNIAR",
        "backDescription": "Seated figure of Britannia facing right trident in left hand shield with Union flag in right hand denomination above sea behind date in",
        "bottomContent": "King George VI",
        "price": "$5.00",
        "ebay": ""
      },
      {
        "id": 189,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "farthing",
        "typeid": 9,
        "name": "King George VI",
        "monarchtype": "George6",
        "year": 1952,
        "tagline": "farthing",
        "title": "1952 King George VI farthing",
        "description": "George VI was King of the United Kingdom and the Dominions of the British Commonwealth from 11 December 1936 until his death on 6 February 1952.",
        "frontImg": "1952f.jpg",
        "frontTitle": "GEORGIVS VI",
        "frontDescription": "Uncrowned portrait of King George VI facing left",
        "backImg": "1952b.jpg",
        "backTitle": "BRITANNIAR",
        "backDescription": "Seated figure of Britannia facing right trident in left hand shield with Union flag in right hand denomination above sea behind date in",
        "bottomContent": "King George VI",
        "price": "$5.00",
        "ebay": ""
      },
      {
        "id": 190,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "farthing",
        "typeid": 9,
        "name": "Queen Elizabeth II",
        "monarchtype": "Elizabeth2",
        "year": 1954,
        "tagline": "farthing",
        "title": "1954 Queen Elizabeth II  farthing",
        "description": "Elizabeth II was Queen of the United Kingdom and other Commonwealth realms from 6 February 1952 until her death in 2022. She was queen regnant of 32 sovereign states over the course of her lifetime and remained the monarch of 15 realms by the time of her death.",
        "frontImg": "1954f.jpg",
        "frontTitle": "Queen Elizabeth II",
        "frontDescription": "Young laureate portrait of Queen Elizabeth II right",
        "backImg": "1954b.jpg",
        "backTitle": "BRITANNIAR",
        "backDescription": "Seated figure of Britannia facing right trident in left hand shield with Union flag in right hand denomination above sea behind date in",
        "bottomContent": "Queen Elizabeth II",
        "price": "$5.00",
        "ebay": ""
      },
      {
        "id": 191,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "farthing",
        "typeid": 9,
        "name": "King Edward VII",
        "monarchtype": "Edward7",
        "year": 1909,
        "tagline": "farthing",
        "title": "1909 King Edward VII  farthing",
        "description": "The second child and eldest son of Queen Victoria and Prince Albert of Saxe-Coburg and Gotha Edward nicknamed \"Bertie\" was related to royalty throughout Europe.",
        "frontImg": "1909f.jpg",
        "frontTitle": "EDWARDVS VII",
        "frontDescription": "Uncrowned portrait of King Edward VII facing right legend around.",
        "backImg": "1909b.jpg",
        "backTitle": "BRITANNIA",
        "backDescription": "Seated figure of Britannia facing right trident in left hand shield with Union flag in right hand denomination above sea behind date in",
        "bottomContent": "King Edward VII",
        "price": "$5.00",
        "ebay": ""
      },
      {
        "id": 192,
        "country": "Austria",
        "countryname": "austria",
        "type": "austria",
        "typeid": 3,
        "name": "austria",
        "monarchtype": "",
        "year": 1961,
        "tagline": "5 shilling",
        "title": "Lipizzaner",
        "description": "The Lipizzaner is a horse breed from Slovenia culturally linked to the Spanish School of Vienna.",
        "frontImg": "5sh1961f.jpg",
        "frontTitle": "REPUBLIK",
        "frontDescription": "Lipizzaner stallion with rider",
        "backImg": "5sh1961b.jpg",
        "backTitle": "austria",
        "backDescription": "The Austrian escutcheon the face value and two laurel branchesBinomial Name: Laurus nobilis",
        "bottomContent": "austria",
        "price": "$5.00",
        "ebay": ""
      },
      {
        "id": 193,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "maundy",
        "typeid": 11,
        "name": "Queen Victoria",
        "monarchtype": "Victoria",
        "year": 1869,
        "tagline": "maundy",
        "title": "1869 Queen Victoria Maundy Set",
        "description": "Victoria was Queen of the United Kingdom of Great Britain and Ireland from 20 June 1837 until her death in 1901. Her reign of 63 years and 216 days which was longer than those of any of her predecessors is known as the Victorian era.",
        "frontImg": "1869f.jpeg",
        "frontTitle": "In contemporary box",
        "frontDescription": "Uncrowned portrait (Young Head) of Queen Victoria left legend around.",
        "backImg": "1869b.jpeg",
        "backTitle": "Grace of God",
        "backDescription": "A most attractive and beautifully matched set.",
        "bottomContent": "Queen Victoria",
        "price": "$500.00",
        "ebay": ""
      },
      {
        "id": 194,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "maundy",
        "typeid": 11,
        "name": "Queen Victoria",
        "monarchtype": "Victoria",
        "year": 1892,
        "tagline": "maundy",
        "title": "1892 Queen Victoria Maundy Set",
        "description": "Victoria was Queen of the United Kingdom of Great Britain and Ireland from 20 June 1837 until her death in 1901. Her reign of 63 years and 216 days which was longer than those of any of her predecessors is known as the Victorian era.",
        "frontImg": "1892f.jpeg",
        "frontTitle": "In contemporary box",
        "frontDescription": "Crowned and veiled portrait (Jubilee Head) of Queen Victoria left legend around.",
        "backImg": "1892b.jpeg",
        "backTitle": "Grace of God",
        "backDescription": "A most attractive and beautifully matched set.",
        "bottomContent": "Queen Victoria",
        "price": "$500.00",
        "ebay": ""
      },
      {
        "id": 195,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "maundy",
        "typeid": 11,
        "name": "Queen Victoria",
        "monarchtype": "Victoria",
        "year": 1899,
        "tagline": "maundy",
        "title": "1899 Queen Victoria Maundy Set",
        "description": "Victoria was Queen of the United Kingdom of Great Britain and Ireland from 20 June 1837 until her death in 1901. Her reign of 63 years and 216 days which was longer than those of any of her predecessors is known as the Victorian era.",
        "frontImg": "1899f.jpeg",
        "frontTitle": "In contemporary box",
        "frontDescription": "Older diademed and veiled portrait (Old Head) of Queen Victoria left legend around designer's initials below bust.",
        "backImg": "1899b.jpeg",
        "backTitle": "Grace of God",
        "backDescription": "A most attractive and beautifully matched set.",
        "bottomContent": "Queen Victoria",
        "price": "$500.00",
        "ebay": ""
      },
      {
        "id": 196,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "maundy",
        "typeid": 11,
        "name": "King Edward VII",
        "monarchtype": "Edward7",
        "year": 1902,
        "tagline": "maundy",
        "title": "1902 King Edward VII Maundy Set",
        "description": "The second child and eldest son of Queen Victoria and Prince Albert of Saxe-Coburg and Gotha Edward nicknamed \"Bertie\" was related to royalty throughout Europe.",
        "frontImg": "1902f.jpeg",
        "frontTitle": "In contemporary box",
        "frontDescription": "Uncrowned portrait of King Edward VII facing right legend around.",
        "backImg": "1902b.jpeg",
        "backTitle": "Grace of God",
        "backDescription": "A most attractive and beautifully matched set.",
        "bottomContent": "King Edward VII",
        "price": "$450.00",
        "ebay": ""
      },
      {
        "id": 197,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "maundy",
        "typeid": 11,
        "name": "King George V",
        "monarchtype": "George5",
        "year": 1927,
        "tagline": "maundy",
        "title": "1927 King George V Maundy Set",
        "description": "George V (George Frederick Ernest Albert; 3 June 1865 20 January 1936) was King of the United Kingdom and the British Dominions and Emperor of India from 6 May 1910 until his death in 1936.",
        "frontImg": "1927f.jpeg",
        "frontTitle": "In contemporary box",
        "frontDescription": "Uncrowned portrait of King George V left legend around.",
        "backImg": "1927b.jpeg",
        "backTitle": "Grace of God",
        "backDescription": "A most attractive and beautifully matched set.",
        "bottomContent": "King George V",
        "price": "$450.00",
        "ebay": ""
      },
      {
        "id": 198,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "maundy",
        "typeid": 11,
        "name": "King George VI",
        "monarchtype": "George6",
        "year": 1951,
        "tagline": "maundy",
        "title": "1951 King George VI Maundy Set",
        "description": "George VI was King of the United Kingdom and the Dominions of the British Commonwealth from 11 December 1936 until his death on 6 February 1952.",
        "frontImg": "1951f.jpeg",
        "frontTitle": "In contemporary box",
        "frontDescription": "Uncrowned portrait of King George VI facing left",
        "backImg": "1951b.jpg",
        "backTitle": "Grace of God",
        "backDescription": "A most attractive and beautifully matched set.",
        "bottomContent": "King George VI",
        "price": "$450.00",
        "ebay": ""
      },
      {
        "id": 199,
        "country": "Austria",
        "countryname": "austria",
        "type": "austria",
        "typeid": 3,
        "name": "1 shilling",
        "monarchtype": "",
        "year": 1961,
        "tagline": "austria",
        "title": "austria",
        "description": "austria",
        "frontImg": "1sh1961f.jpg",
        "frontTitle": "REPUBLIK·ÖSTERREICH",
        "frontDescription": "The denomination in the center above the date with the country name above around the rim",
        "backImg": "1sh1961b.jpg",
        "backTitle": "EIN SCHILLING",
        "backDescription": "Three edelweiss flowers (Leontopodium alpinum or Leontopodium nivale ssp.) and face value in words surrounding",
        "bottomContent": "austria",
        "price": "$5.00",
        "ebay": ""
      },
      {
        "id": 200,
        "country": "Austria",
        "countryname": "austria",
        "type": "austria",
        "typeid": 3,
        "name": "10 shilling",
        "monarchtype": "",
        "year": 1958,
        "tagline": "austria",
        "title": "austria",
        "description": "austria",
        "frontImg": "10Sh1958f.jpg",
        "frontTitle": "REPUBLIK·ÖSTERREICH",
        "frontDescription": "Austrian escutcheon red and white",
        "backImg": "1sh1961b.jpg",
        "backTitle": "SCHILLING",
        "backDescription": "Woman head wearing the traditional Lower Austria hat",
        "bottomContent": "austria",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 201,
        "country": "Austria",
        "countryname": "austria",
        "type": "austria",
        "typeid": 3,
        "name": "50 Groschen",
        "monarchtype": "",
        "year": 1960,
        "tagline": "austria",
        "title": "austria",
        "description": "Vienna Austria",
        "frontImg": "196050Groschenf.jpg",
        "frontTitle": "austria",
        "frontDescription": "The Austrian escutcheon and the state title",
        "backImg": "196050Groschenb.jpg",
        "backTitle": "GROSCHEN",
        "backDescription": "The face value divides the date and a gentian flower or Stemless gentian flower (Binomial Name: Gentiana acaulis) partially inside the number '50'",
        "bottomContent": "austria",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 202,
        "country": "Belgium",
        "countryname": "belgium",
        "type": "belgium",
        "typeid": 4,
        "name": "10 Centimes",
        "monarchtype": "",
        "year": 1904,
        "tagline": "belgium",
        "title": "belgium",
        "description": "10 Centimes",
        "frontImg": "10cen1904f.jpg",
        "frontTitle": "Copper-Nickel",
        "frontDescription": "Center hole within crowned monogram large date below legend in Dutch",
        "backImg": "10cen1904b.jpg",
        "backTitle": "BELGIE",
        "backDescription": "Spray of leaves to left of center hole denomination to right",
        "bottomContent": "belgium",
        "price": "$25.00",
        "ebay": ""
      },
      {
        "id": 203,
        "country": "Belgium",
        "countryname": "belgium",
        "type": "belgium",
        "typeid": 4,
        "name": "5 Francs",
        "monarchtype": "",
        "year": 1949,
        "tagline": "belgium",
        "title": "belgium",
        "description": "5 Francs",
        "frontImg": "1949Belgium5Fraf.jpg",
        "frontTitle": "BELGIE",
        "frontDescription": "Laureate head left small diamonds flank date at left symbol at right",
        "backImg": "1949Belgium5Frab.jpg",
        "backTitle": "",
        "backDescription": "Plant divides denomination Crown at top legend in Dutch",
        "bottomContent": "belgium",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 204,
        "country": "CCCP",
        "countryname": "cccp",
        "type": "cccp",
        "typeid": 5,
        "name": "1 Kopeck",
        "monarchtype": "",
        "year": 1940,
        "tagline": "cccp",
        "title": "cccp",
        "description": "1 Kopeck",
        "frontImg": "1kon1940f.jpg",
        "frontTitle": "",
        "frontDescription": "The coat of arms of the Soviet Union.",
        "backImg": "1kon1940b.jpg",
        "backTitle": "Rouble",
        "backDescription": "Value and date within wheat sprigs.",
        "bottomContent": "cccp",
        "price": "$15.00",
        "ebay": ""
      },
      {
        "id": 205,
        "country": "CCCP",
        "countryname": "cccp",
        "type": "cccp",
        "typeid": 5,
        "name": "2 Kopeck",
        "monarchtype": "",
        "year": 1939,
        "tagline": "cccp",
        "title": "cccp",
        "description": "2 Kopeck",
        "frontImg": "2kon1939f.jpg",
        "frontTitle": "",
        "frontDescription": "The coat of arms of the Soviet Union.",
        "backImg": "2kon1939b.jpg",
        "backTitle": "Rouble",
        "backDescription": "Value and date within wheat sprigs.",
        "bottomContent": "cccp",
        "price": "$15.00",
        "ebay": ""
      },
      {
        "id": 206,
        "country": "CCCP",
        "countryname": "cccp",
        "type": "cccp",
        "typeid": 5,
        "name": "3 Kopeck",
        "monarchtype": "",
        "year": 1940,
        "tagline": "cccp",
        "title": "cccp",
        "description": "3 Kopeck",
        "frontImg": "3kon1940f.jpg",
        "frontTitle": "",
        "frontDescription": "The coat of arms of the Soviet Union.",
        "backImg": "3kon1940b.jpg",
        "backTitle": "Rouble",
        "backDescription": "Value and date within wheat sprigs.",
        "bottomContent": "cccp",
        "price": "$20.00",
        "ebay": ""
      },
      {
        "id": 207,
        "country": "CCCP",
        "countryname": "cccp",
        "type": "cccp",
        "typeid": 5,
        "name": "5 Kopeck",
        "monarchtype": "",
        "year": 1940,
        "tagline": "cccp",
        "title": "cccp",
        "description": "5 Kopeck",
        "frontImg": "5kon1940f.jpg",
        "frontTitle": "",
        "frontDescription": "The coat of arms of the Soviet Union.",
        "backImg": "5kon1940b.jpg",
        "backTitle": "Rouble",
        "backDescription": "Value and date within wheat sprigs.",
        "bottomContent": "cccp",
        "price": "$20.00",
        "ebay": ""
      },
      {
        "id": 208,
        "country": "Denmark",
        "countryname": "denmark",
        "type": "denmark",
        "typeid": 6,
        "name": "1 Krone",
        "monarchtype": "",
        "year": 1948,
        "tagline": "denmark",
        "title": "denmark",
        "description": "Head of King Gustaf V facing left. The tip of neck-cut divides mintmarks at sides",
        "frontImg": "19481Kronef.jpg",
        "frontTitle": "Aluminum-Bronze",
        "frontDescription": "Head right titles mint mark initials N-S",
        "backImg": "19481Kroneb.jpg",
        "backTitle": "Frederik IX",
        "backDescription": "Crowned royal arms divide date value above",
        "bottomContent": "denmark",
        "price": "$15.00",
        "ebay": ""
      },
      {
        "id": 209,
        "country": "Norway",
        "countryname": "norway",
        "type": "norway",
        "typeid": 20,
        "name": "1 Krone",
        "monarchtype": "",
        "year": 1951,
        "tagline": "norway",
        "title": "norway",
        "description": "Haakon VII was King of Norway from November 1905 until his death in September 1957. Originally a Danish prince he was born in Copenhagen as the son of the future Frederick VIII of Denmark and Louise of Sweden.",
        "frontImg": "19511Kf.jpg",
        "frontTitle": "1 Krone",
        "frontDescription": "Crowned H7 monogram",
        "backImg": "19511Kb.jpg",
        "backTitle": "Haakon VII",
        "backDescription": "Crowned Norwegian coat of arms with the Norwegian lion",
        "bottomContent": "norway",
        "price": "$40.00",
        "ebay": ""
      },
      {
        "id": 210,
        "country": "Norway",
        "countryname": "norway",
        "type": "norway",
        "typeid": 20,
        "name": "50 Ore",
        "monarchtype": "",
        "year": 1953,
        "tagline": "norway",
        "title": "norway",
        "description": "Haakon VII was King of Norway from November 1905 until his death in September 1957. Originally a Danish prince he was born in Copenhagen as the son of the future Frederick VIII of Denmark and Louise of Sweden.",
        "frontImg": "195350oref.jpg",
        "frontTitle": "50 Ore",
        "frontDescription": "Crowned H7 monogram",
        "backImg": "195350oreb.jpg",
        "backTitle": "Haakon VII",
        "backDescription": "Den norske løve.",
        "bottomContent": "norway",
        "price": "$25.00",
        "ebay": ""
      },
      {
        "id": 211,
        "country": "Norway",
        "countryname": "norway",
        "type": "norway",
        "typeid": 20,
        "name": "25 Ore",
        "monarchtype": "",
        "year": 1953,
        "tagline": "norway",
        "title": "norway",
        "description": "Haakon VII was King of Norway from November 1905 until his death in September 1957. Originally a Danish prince he was born in Copenhagen as the son of the future Frederick VIII of Denmark and Louise of Sweden.",
        "frontImg": "195325oref.jpg",
        "frontTitle": "25 Ore",
        "frontDescription": "Crowned monogram divides date",
        "backImg": "195325oreb.jpg",
        "backTitle": "Haakon VII",
        "backDescription": "Two ornamental roses",
        "bottomContent": "norway",
        "price": "$30.00",
        "ebay": ""
      },
      {
        "id": 212,
        "country": "Norway",
        "countryname": "norway",
        "type": "norway",
        "typeid": 20,
        "name": "10 Ore",
        "monarchtype": "",
        "year": 1952,
        "tagline": "norway",
        "title": "norway",
        "description": "Haakon VII was King of Norway from November 1905 until his death in September 1957. Originally a Danish prince he was born in Copenhagen as the son of the future Frederick VIII of Denmark and Louise of Sweden.",
        "frontImg": "195210oref.jpg",
        "frontTitle": "10 Ore",
        "frontDescription": "Crowned monogram divides date",
        "backImg": "195210oreb.jpg",
        "backTitle": "Haakon VII",
        "backDescription": "Two ornamental roses",
        "bottomContent": "norway",
        "price": "$15.00",
        "ebay": ""
      },
      {
        "id": 213,
        "country": "Norway",
        "countryname": "norway",
        "type": "norway",
        "typeid": 20,
        "name": "2 Ore",
        "monarchtype": "",
        "year": 1953,
        "tagline": "norway",
        "title": "norway",
        "description": "Haakon VII was King of Norway from November 1905 until his death in September 1957. Originally a Danish prince he was born in Copenhagen as the son of the future Frederick VIII of Denmark and Louise of Sweden.",
        "frontImg": "19532oref.jpg",
        "frontTitle": "2 Ore",
        "frontDescription": "Crowned monogram divides date",
        "backImg": "19532oreb.jpg",
        "backTitle": "Haakon VII",
        "backDescription": "Two ornamental roses",
        "bottomContent": "norway",
        "price": "$5.00",
        "ebay": ""
      },
      {
        "id": 214,
        "country": "Norway",
        "countryname": "norway",
        "type": "norway",
        "typeid": 20,
        "name": "1 Ore",
        "monarchtype": "",
        "year": 1953,
        "tagline": "norway",
        "title": "norway",
        "description": "Haakon VII was King of Norway from November 1905 until his death in September 1957. Originally a Danish prince he was born in Copenhagen as the son of the future Frederick VIII of Denmark and Louise of Sweden.",
        "frontImg": "19531oref.jpg",
        "frontTitle": "1 Ore",
        "frontDescription": "Crowned monogram divides date",
        "backImg": "19531oreb.jpg",
        "backTitle": "Haakon VII",
        "backDescription": "Two ornamental roses",
        "bottomContent": "norway",
        "price": "$5.00",
        "ebay": ""
      },
      {
        "id": 215,
        "country": "Norway",
        "countryname": "norway",
        "type": "norway",
        "typeid": 20,
        "name": "1 Krone",
        "monarchtype": "",
        "year": 1925,
        "tagline": "norway",
        "title": "norway",
        "description": "Haakon VII was King of Norway from November 1905 until his death in September 1957. Originally a Danish prince he was born in Copenhagen as the son of the future Frederick VIII of Denmark and Louise of Sweden.",
        "frontImg": "19251kf.jpg",
        "frontTitle": "1 Krone",
        "frontDescription": "Cross consisting of four crowned H7 monograms",
        "backImg": "19251kb.jpg",
        "backTitle": "Haakon VII",
        "backDescription": "Crowned collar chain of the Royal Norwegian Order of Saint Olav around a hole.",
        "bottomContent": "norway",
        "price": "$15.00",
        "ebay": ""
      },
      {
        "id": 216,
        "country": "Norway",
        "countryname": "norway",
        "type": "norway",
        "typeid": 20,
        "name": "50 Ore",
        "monarchtype": "",
        "year": 1941,
        "tagline": "norway",
        "title": "norway",
        "description": "Haakon VII was King of Norway from November 1905 until his death in September 1957. Originally a Danish prince he was born in Copenhagen as the son of the future Frederick VIII of Denmark and Louise of Sweden.",
        "frontImg": "194150of.jpg",
        "frontTitle": "50 Ore",
        "frontDescription": "Central hole. Each quarter contains a 7 within a stylized H and crown on top.",
        "backImg": "194150ob.jpg",
        "backTitle": "Haakon VII",
        "backDescription": "Central hole. Date below with value at bottom an ornamental rose either side crown at top.",
        "bottomContent": "norway",
        "price": "$15.00",
        "ebay": ""
      },
      {
        "id": 217,
        "country": "Norway",
        "countryname": "norway",
        "type": "norway",
        "typeid": 20,
        "name": "25 Ore",
        "monarchtype": "",
        "year": 1940,
        "tagline": "norway",
        "title": "norway",
        "description": "Haakon VII was King of Norway from November 1905 until his death in September 1957. Originally a Danish prince he was born in Copenhagen as the son of the future Frederick VIII of Denmark and Louise of Sweden.",
        "frontImg": "194025of.jpg",
        "frontTitle": "25 Ore",
        "frontDescription": "Crowned cross with monogram in each quarter hole in centre",
        "backImg": "194025ob.jpg",
        "backTitle": "Haakon VII",
        "backDescription": "Value and date below two ornamental roses flank centre hole crown above",
        "bottomContent": "norway",
        "price": "$15.00",
        "ebay": ""
      },
      {
        "id": 218,
        "country": "Norway",
        "countryname": "norway",
        "type": "norway",
        "typeid": 20,
        "name": "10 Ore",
        "monarchtype": "",
        "year": 1940,
        "tagline": "norway",
        "title": "norway",
        "description": "Haakon VII was King of Norway from November 1905 until his death in September 1957. Originally a Danish prince he was born in Copenhagen as the son of the future Frederick VIII of Denmark and Louise of Sweden.",
        "frontImg": "194010of.jpg",
        "frontTitle": "10 Ore",
        "frontDescription": "Crown above hole",
        "backImg": "194010ob.jpg",
        "backTitle": "Haakon VII",
        "backDescription": "All for Norway",
        "bottomContent": "norway",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 219,
        "country": "Norway",
        "countryname": "norway",
        "type": "norway",
        "typeid": 20,
        "name": "2 Ore",
        "monarchtype": "",
        "year": 1937,
        "tagline": "norway",
        "title": "norway",
        "description": "Haakon VII was King of Norway from November 1905 until his death in September 1957. Originally a Danish prince he was born in Copenhagen as the son of the future Frederick VIII of Denmark and Louise of Sweden.",
        "frontImg": "19372of.jpg",
        "frontTitle": "2 Ore",
        "frontDescription": "Crowned Haakon VII monogram in circle",
        "backImg": "19372ob.jpg",
        "backTitle": "Haakon VII",
        "backDescription": "Kingdom of Norway",
        "bottomContent": "norway",
        "price": "$5.00",
        "ebay": ""
      },
      {
        "id": 220,
        "country": "Poland",
        "countryname": "poland",
        "type": "poland",
        "typeid": 15,
        "name": "5 Groszys",
        "monarchtype": "",
        "year": 1923,
        "tagline": "poland",
        "title": "poland",
        "description": "Polands frontiers were settled in 1922 and internationally recognized in 1923",
        "frontImg": "19235cf.jpg",
        "frontTitle": "RZECZPOSPOLITA",
        "frontDescription": "Coat of Arms of Poland",
        "backImg": "19235cb.jpg",
        "backTitle": "Second Zloty",
        "backDescription": "A Stylized flower on either side of Denomination",
        "bottomContent": "poland",
        "price": "$5.00",
        "ebay": ""
      },
      {
        "id": 221,
        "country": "France",
        "countryname": "france",
        "type": "france",
        "typeid": 9,
        "name": "100 Franc",
        "monarchtype": "",
        "year": 1954,
        "tagline": "france",
        "title": "1954 100 Franc",
        "description": "France in Western Europe encompasses medieval cities alpine villages and Mediterranean beaches. Paris its capital is famed for its fashion houses classical art museums including the Louvre and monuments like the Eiffel Tower.",
        "frontImg": "1954100ff.jpg",
        "frontTitle": "FRANÇAISE",
        "frontDescription": "The bust of Liberty holding a torch facing right",
        "backImg": "1954100fb.jpg",
        "backTitle": "LIBERTE EGALITE",
        "backDescription": "Value and date with mintmark on left.",
        "bottomContent": "france",
        "price": "$30.00",
        "ebay": ""
      },
      {
        "id": 222,
        "country": "France",
        "countryname": "france",
        "type": "france",
        "typeid": 9,
        "name": "10 Francs",
        "monarchtype": "",
        "year": 1952,
        "tagline": "france",
        "title": "1952 10 Francs",
        "description": "France in Western Europe encompasses medieval cities alpine villages and Mediterranean beaches. Paris its capital is famed for its fashion houses classical art museums including the Louvre and monuments like the Eiffel Tower.",
        "frontImg": "195210ff.jpg",
        "frontTitle": "FRANÇAISE",
        "frontDescription": "Female head facing left",
        "backImg": "195210fb.jpg",
        "backTitle": "10 Francs",
        "backDescription": "Rooster facing right laurel branch value year of production pattern around edge.",
        "bottomContent": "france",
        "price": "$35.00",
        "ebay": ""
      },
      {
        "id": 223,
        "country": "France",
        "countryname": "france",
        "type": "france",
        "typeid": 9,
        "name": "10 Francs",
        "monarchtype": "",
        "year": 1949,
        "tagline": "france",
        "title": "1949 10 Francs",
        "description": "France in Western Europe encompasses medieval cities alpine villages and Mediterranean beaches. Paris its capital is famed for its fashion houses classical art museums including the Louvre and monuments like the Eiffel Tower.",
        "frontImg": "194910ff.jpg",
        "frontTitle": "FRANÇAISE",
        "frontDescription": "The head of Republic right with a phrygian cap which brushes by the denticles.",
        "backImg": "194910fb.jpg",
        "backTitle": "10 Francs",
        "backDescription": "Denomination above date inscription below grain columns flank.",
        "bottomContent": "france",
        "price": "$20.00",
        "ebay": ""
      },
      {
        "id": 224,
        "country": "France",
        "countryname": "france",
        "type": "france",
        "typeid": 9,
        "name": "1 Francs",
        "monarchtype": "",
        "year": 1936,
        "tagline": "france",
        "title": "1936 1 Francs",
        "description": "France in Western Europe encompasses medieval cities alpine villages and Mediterranean beaches. Paris its capital is famed for its fashion houses classical art museums including the Louvre and monuments like the Eiffel Tower.",
        "frontImg": "19361ff.jpg",
        "frontTitle": "FRANÇAISE",
        "frontDescription": "Marianne's head left wearing a phrygian cap decorated with a composite wreath made of olive tree leaves oak and wheat.",
        "backImg": "19361fb.jpg",
        "backTitle": "1 Francs",
        "backDescription": "Value encompassed on either side by Cornucopia (or horn of plenty overflowing with produce flowers or nuts).",
        "bottomContent": "france",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 225,
        "country": "France",
        "countryname": "france",
        "type": "france",
        "typeid": 9,
        "name": "50 CENTIMES",
        "monarchtype": "",
        "year": 1923,
        "tagline": "france",
        "title": "1923 50 CENTIMES",
        "description": "France in Western Europe encompasses medieval cities alpine villages and Mediterranean beaches. Paris its capital is famed for its fashion houses classical art museums including the Louvre and monuments like the Eiffel Tower.",
        "frontImg": "192350cf.jpg",
        "frontTitle": "CENTIMES",
        "frontDescription": "Mercury the god of commerce (god of abundance and commercial success",
        "backImg": "192350cb.jpg",
        "backTitle": "COMMERCE",
        "backDescription": "Denomination in center lettering surrounding",
        "bottomContent": "france",
        "price": "$20.00",
        "ebay": ""
      },
      {
        "id": 226,
        "country": "France",
        "countryname": "france",
        "type": "france",
        "typeid": 9,
        "name": "2 Francs",
        "monarchtype": "",
        "year": 1923,
        "tagline": "france",
        "title": "1923 2 Francs",
        "description": "France in Western Europe encompasses medieval cities alpine villages and Mediterranean beaches. Paris its capital is famed for its fashion houses classical art museums including the Louvre and monuments like the Eiffel Tower.",
        "frontImg": "19232fcf.jpg",
        "frontTitle": "FRANÇAISE",
        "frontDescription": "Mercury the god of commerce (god of abundance and commercial success)",
        "backImg": "19232fcb.jpg",
        "backTitle": "COMMERCE",
        "backDescription": "Denomination in center lettering surrounding",
        "bottomContent": "france",
        "price": "$40.00",
        "ebay": ""
      },
      {
        "id": 227,
        "country": "France",
        "countryname": "france",
        "type": "france",
        "typeid": 9,
        "name": "10 Centimes",
        "monarchtype": "",
        "year": 1925,
        "tagline": "france",
        "title": "1925 10 Centimes",
        "description": "France in Western Europe encompasses medieval cities alpine villages and Mediterranean beaches. Paris its capital is famed for its fashion houses classical art museums including the Louvre and monuments like the Eiffel Tower.",
        "frontImg": "192510cf.jpg",
        "frontTitle": "FRANÇAISE",
        "frontDescription": "A wreath and the engraver's name at the bottom cap at the top with the letter R to the left of the central hole letter F to right the base of letters intertwined below",
        "backImg": "192510cb.jpg",
        "backTitle": "EM LINDAUER",
        "backDescription": "A plant below the hole date at the bottom. Lettering above the hole value divided by hole date at the bottom and the privy mark on the left below the 1",
        "bottomContent": "france",
        "price": "$40.00",
        "ebay": ""
      },
      {
        "id": 228,
        "country": "Spain",
        "countryname": "spain",
        "type": "spain",
        "typeid": 16,
        "name": "5 Pesetas",
        "monarchtype": "",
        "year": 1957,
        "tagline": "spain",
        "title": "1957 5 Pesetas",
        "description": "Francisco Franco Bahamonde was a Spanish military general who led the Nationalist forces in overthrowing the Second Spanish Republic during the Spanish Civil War and thereafter ruled over Spain from 1939 to 1975 as a dictator assuming the title Caudillo.",
        "frontImg": "19575pf.jpg",
        "frontTitle": "FRANCO",
        "frontDescription": "The portrait of Francisco Franco facing right with the date of the start of the series below",
        "backImg": "19575pb.jpg",
        "backTitle": "Royal Mint of Spain",
        "backDescription": "The Spanish coat of arms with the eagle of Saint John and the year in the star to the left with the denomination at the top",
        "bottomContent": "spain",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 229,
        "country": "Sweden",
        "countryname": "sweden",
        "type": "sweden",
        "typeid": 21,
        "name": "1 Krona",
        "monarchtype": "",
        "year": 1955,
        "tagline": "sweden",
        "title": "1955 1 sweden Krona",
        "description": "Gustaf VI Adolf was King of Sweden from 29 October 1950 until his death in 1973. He was the eldest son of Gustaf V and his wife Victoria of Baden. Before Gustaf Adolf ascended the throne he had been crown prince for nearly 43 years during his father's reign.",
        "frontImg": "19551krf.jpg",
        "frontTitle": "GUSTAF VI",
        "frontDescription": "Stylized portrait of King facing left",
        "backImg": "19551krb.jpg",
        "backTitle": "King of Sweden",
        "backDescription": "Crowned coat of arms divides date",
        "bottomContent": "sweden",
        "price": "$5.00",
        "ebay": ""
      },
      {
        "id": 230,
        "country": "Sweden",
        "countryname": "sweden",
        "type": "sweden",
        "typeid": 21,
        "name": "50 ÖRE",
        "monarchtype": "",
        "year": 1957,
        "tagline": "sweden",
        "title": "1957 50 sweden ÖRE",
        "description": "Gustaf VI Adolf was King of Sweden from 29 October 1950 until his death in 1973. He was the eldest son of Gustaf V and his wife Victoria of Baden. Before Gustaf Adolf ascended the throne he had been crown prince for nearly 43 years during his father's reign.",
        "frontImg": "195750of.jpg",
        "frontTitle": "GUSTAF VI",
        "frontDescription": "Big Crown",
        "backImg": "195750ob.jpg",
        "backTitle": "King of Sweden",
        "backDescription": "Value with date below and name of the country above. At sides of value there are: the mintmark at left and at right there is the first letter in the surname of the Governor of the Riksbank.",
        "bottomContent": "sweden",
        "price": "$5.00",
        "ebay": ""
      },
      {
        "id": 231,
        "country": "Sweden",
        "countryname": "sweden",
        "type": "sweden",
        "typeid": 21,
        "name": "2 ÖRE",
        "monarchtype": "",
        "year": 1919,
        "tagline": "sweden",
        "title": "1919 2 sweden ÖRE",
        "description": "Gustaf V was King of Sweden from 8 December 1907 until his death in 1950. He was the eldest son of King Oscar II of Sweden and Sophia of Nassau a half-sister of Adolphe Grand Duke of Luxembourg.",
        "frontImg": "19192of.jpg",
        "frontTitle": "GUSTAF V",
        "frontDescription": "Crowned double monogram of King Gustaf V of Sweden divides date. King's motto around rim.",
        "backImg": "19192ob.jpg",
        "backTitle": "With the People for the Motherland",
        "backDescription": "The Three crowns of Sweden divides value in letters at base of lower crown. Above the crowns there is the value in number and below the mintmark of Stockholm mint.",
        "bottomContent": "sweden",
        "price": "$5.00",
        "ebay": ""
      },
      {
        "id": 232,
        "country": "Switzerland",
        "countryname": "switzerland",
        "type": "switzerland",
        "typeid": 17,
        "name": "5 Rappen",
        "monarchtype": "",
        "year": 1954,
        "tagline": "switzerland",
        "title": "1954 5 Rappen",
        "description": "5 rappen 1954 ; Period Confoederatio Helvetica 1917 - 1967 ; Coin type Circulation coins ; Denomination 5 rappen ; Currency rate 0.05 CHF = 0.05 USD ; Year 1954.",
        "frontImg": "19545rf.jpg",
        "frontTitle": "5 Rappen",
        "frontDescription": "CONFOEDERATIO HELVETICA",
        "backImg": "19545rb.jpg",
        "backTitle": "",
        "backDescription": "Value within wreath",
        "bottomContent": "switzerland",
        "price": "$75.00",
        "ebay": ""
      },
      {
        "id": 233,
        "country": "Yugoslavia",
        "countryname": "yugoslavia",
        "type": "yugoslavia",
        "typeid": 18,
        "name": "1 Dinar",
        "monarchtype": "",
        "year": 1951,
        "tagline": "yugoslavia",
        "title": "1951 1 Dinar",
        "description": "Federal Peoples Republic of Yugoslavia",
        "frontImg": "19531df.jpg",
        "frontTitle": "1 Dinar",
        "frontDescription": "The name of the country above the old arms of Yugoslavi",
        "backImg": "19531db.jpg",
        "backTitle": "Republic of Yugoslavia",
        "backDescription": "Seven stars above value the date on the left and right sides.",
        "bottomContent": "yugoslavia",
        "price": "$5.00",
        "ebay": ""
      },
      {
        "id": 234,
        "country": "Yugoslavia",
        "countryname": "yugoslavia",
        "type": "yugoslavia",
        "typeid": 18,
        "name": "5 Dinar",
        "monarchtype": "",
        "year": 1953,
        "tagline": "yugoslavia",
        "title": "1953 5 Dinar",
        "description": "Federal Peoples Republic of Yugoslavia",
        "frontImg": "19535df.jpg",
        "frontTitle": "1 Dinar",
        "frontDescription": "The name of the country above the old arms of Yugoslavi",
        "backImg": "19535db.jpg",
        "backTitle": "Republic of Yugoslavia",
        "backDescription": "Seven stars above value the date on the left and right sides.",
        "bottomContent": "yugoslavia",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 235,
        "country": "Switzerland",
        "countryname": "switzerland",
        "type": "switzerland",
        "typeid": 17,
        "name": "1 Franc",
        "monarchtype": "",
        "year": 1945,
        "tagline": "switzerland",
        "title": "1945 1 fr helvetia",
        "description": "1 Franc",
        "frontImg": "19451frf.jpg",
        "frontTitle": "Helvetia",
        "frontDescription": "Standing Helvetia with lance and shield within star border",
        "backImg": "19451frb.jpg",
        "backTitle": "Switzerland Franc",
        "backDescription": "Value date within wreath",
        "bottomContent": "switzerland",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 236,
        "country": "Switzerland",
        "countryname": "switzerland",
        "type": "switzerland",
        "typeid": 17,
        "name": "1/2 Franc",
        "monarchtype": "",
        "year": 1952,
        "tagline": "switzerland",
        "title": "1952 1/2 fr helvetia",
        "description": "1/2 Franc",
        "frontImg": "195212frf.jpg",
        "frontTitle": "Helvetia",
        "frontDescription": "Standing Helvetia with lance and shield within star border",
        "backImg": "195212frb.jpg",
        "backTitle": "Switzerland Franc",
        "backDescription": "Value date within wreath",
        "bottomContent": "switzerland",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 237,
        "country": "Switzerland",
        "countryname": "switzerland",
        "type": "switzerland",
        "typeid": 17,
        "name": "20 rappen",
        "monarchtype": "",
        "year": 1951,
        "tagline": "switzerland",
        "title": "1951 20 rappen",
        "description": "20 rappen",
        "frontImg": "195120rapf.jpg",
        "frontTitle": "Helvetia",
        "frontDescription": "Standing Helvetia with lance and shield within star border",
        "backImg": "195120rapb.jpg",
        "backTitle": "Switzerland Franc",
        "backDescription": "Value date within wreath",
        "bottomContent": "switzerland",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 238,
        "country": "Switzerland",
        "countryname": "switzerland",
        "type": "switzerland",
        "typeid": 17,
        "name": "10 rappen",
        "monarchtype": "",
        "year": 1946,
        "tagline": "switzerland",
        "title": "1946 10 rappen",
        "description": "10 rappen",
        "frontImg": "194610rapf.jpg",
        "frontTitle": "Helvetia",
        "frontDescription": "Standing Helvetia with lance and shield within star border",
        "backImg": "194610rapb.jpg",
        "backTitle": "Switzerland Franc",
        "backDescription": "Value date within wreath",
        "bottomContent": "switzerland",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 239,
        "country": "Switzerland",
        "countryname": "switzerland",
        "type": "switzerland",
        "typeid": 17,
        "name": "2 rappen",
        "monarchtype": "",
        "year": 1953,
        "tagline": "switzerland",
        "title": "1953 2 rappen",
        "description": "2 rappen",
        "frontImg": "19532rapf.jpg",
        "frontTitle": "Helvetia",
        "frontDescription": "Standing Helvetia with lance and shield within star border",
        "backImg": "19532rapb.jpg",
        "backTitle": "Switzerland Franc",
        "backDescription": "Value date within wreath",
        "bottomContent": "switzerland",
        "price": "$5.00",
        "ebay": ""
      },
      {
        "id": 240,
        "country": "Switzerland",
        "countryname": "switzerland",
        "type": "switzerland",
        "typeid": 17,
        "name": "1 rappen",
        "monarchtype": "",
        "year": 1954,
        "tagline": "switzerland",
        "title": "1954 1 rappen",
        "description": "1 rappen",
        "frontImg": "19541rapf.jpg",
        "frontTitle": "Helvetia",
        "frontDescription": "Standing Helvetia with lance and shield within star border",
        "backImg": "19541rapb.jpg",
        "backTitle": "Switzerland Franc",
        "backDescription": "Value date within wreath",
        "bottomContent": "switzerland",
        "price": "$5.00",
        "ebay": ""
      },
      {
        "id": 241,
        "country": "Switzerland",
        "countryname": "switzerland",
        "type": "switzerland",
        "typeid": 17,
        "name": "2 rappen",
        "monarchtype": "",
        "year": 1943,
        "tagline": "switzerland",
        "title": "1943 2 rappen",
        "description": "2 rappen",
        "frontImg": "19432rapf.jpg",
        "frontTitle": "Helvetia",
        "frontDescription": "Coat of arms of Switzerland on ornate shield.",
        "backImg": "19432rapb.jpg",
        "backTitle": "Switzerland Franc",
        "backDescription": "Wreath with two laurel branches tied with a ribbon below.",
        "bottomContent": "switzerland",
        "price": "$20.00",
        "ebay": ""
      },
      {
        "id": 242,
        "country": "Switzerland",
        "countryname": "switzerland",
        "type": "switzerland",
        "typeid": 17,
        "name": "1 rappen",
        "monarchtype": "",
        "year": 1934,
        "tagline": "switzerland",
        "title": "1934 1 rappen",
        "description": "1 rappen",
        "frontImg": "19341rapf.jpg",
        "frontTitle": "Helvetia",
        "frontDescription": "Coat of arms of Switzerland on ornate shield.",
        "backImg": "19341rapb.jpg",
        "backTitle": "Switzerland Franc",
        "backDescription": "Wreath with two laurel branches tied with a ribbon below.",
        "bottomContent": "switzerland",
        "price": "$30.00",
        "ebay": ""
      },
      {
        "id": 243,
        "country": "Switzerland",
        "countryname": "switzerland",
        "type": "switzerland",
        "typeid": 17,
        "name": "1 rappen",
        "monarchtype": "",
        "year": 1934,
        "tagline": "switzerland",
        "title": "1894 1 rappen",
        "description": "1 rappen",
        "frontImg": "18941rapf.jpg",
        "frontTitle": "Helvetia",
        "frontDescription": "Shield within sprigs date below",
        "backImg": "18941rapb.jpg",
        "backTitle": "Switzerland Franc",
        "backDescription": "Value within wreath",
        "bottomContent": "switzerland",
        "price": "$100.00",
        "ebay": ""
      },
      {
        "id": 244,
        "country": "Switzerland",
        "countryname": "switzerland",
        "type": "switzerland",
        "typeid": 17,
        "name": "2 Pfennigs",
        "monarchtype": "",
        "year": 1926,
        "tagline": "switzerland",
        "title": "1926 2 Pfennigs",
        "description": "2 Pfennigs",
        "frontImg": "19262Pff.jpg",
        "frontTitle": "Helvetia",
        "frontDescription": "Shield within sprigs date below",
        "backImg": "19262Pfb.jpg",
        "backTitle": "Switzerland Franc",
        "backDescription": "Value within wreath",
        "bottomContent": "switzerland",
        "price": "$20.00",
        "ebay": ""
      },
      {
        "id": 245,
        "country": "Switzerland",
        "countryname": "switzerland",
        "type": "switzerland",
        "typeid": 17,
        "name": "1/2 Franc",
        "monarchtype": "",
        "year": 1954,
        "tagline": "switzerland",
        "title": "1954 5 fr helvetia",
        "description": "In 1967 the price of silver soared steeply reaching 260 francs per kilogram in August and 350 francs per kilogram in March 1968. At this time the melt value of the 1\u001a2 1 and 2 franc pieces was circa 46% higher than their nominal value.",
        "frontImg": "19545frf.jpg",
        "frontTitle": "Helvetia",
        "frontDescription": "Bust of curly-haired herdsman facing righ",
        "backImg": "19545frb.jpg",
        "backTitle": "Confoederatio Helvetica",
        "backDescription": "Coat of arms of Switzerland on square shield.",
        "bottomContent": "switzerland",
        "price": "$20.00",
        "ebay": ""
      },
      {
        "id": 246,
        "country": "Switzerland",
        "countryname": "switzerland",
        "type": "switzerland",
        "typeid": 17,
        "name": "2 Franc",
        "monarchtype": "",
        "year": 1948,
        "tagline": "switzerland",
        "title": "1948 2 fr helvetia",
        "description": "In 1967 the price of silver soared steeply reaching 260 francs per kilogram in August and 350 francs per kilogram in March 1968. At this time the melt value of the 1/2 franc pieces was circa 0.73 francs. Switzerland started to experience a severe shortage of cash especially in the north where people were illegally exporting coins to Germany to be melted.",
        "frontImg": "19482frf.jpg",
        "frontTitle": "Helvetia",
        "frontDescription": "Full-length figure of Helvetia standing facing left wearing toga",
        "backImg": "19482frb.jpg",
        "backTitle": "Switzerland Franc",
        "backDescription": "Wreath formed of oak and alpine rose branches tied with a ribbon below.",
        "bottomContent": "switzerland",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 247,
        "country": "Denmark",
        "countryname": "denmark",
        "type": "denmark",
        "typeid": 6,
        "name": "25 Ore",
        "monarchtype": "",
        "year": 1954,
        "tagline": "denmark",
        "title": "1954 25 Ore",
        "description": "Head of King Gustaf V facing left. The tip of neck-cut divides mintmarks at sides",
        "frontImg": "195425oref.jpg",
        "frontTitle": "Copper-Nickel",
        "frontDescription": "Crowned F IX R monogram divides value oak and beech branches below",
        "backImg": "195425oreb.jpg",
        "backTitle": "Frederik IX",
        "backDescription": "Value country name mint mark initials N-S",
        "bottomContent": "denmark",
        "price": "$15.00",
        "ebay": ""
      },
      {
        "id": 248,
        "country": "Italy",
        "countryname": "italy",
        "type": "italy",
        "typeid": 11,
        "name": "20 Centesimi",
        "monarchtype": "",
        "year": 1918,
        "tagline": "italy",
        "title": "1918 20 Centesimi",
        "description": "Victor Emmanuel III born Vittorio Emanuele Ferdinando Maria Gennaro di Savoia was King of Italy from 29 July 1900 until his abdication on 9 May 1946. A member of the House of Savoy he also reigned as Emperor of Ethiopia and King of the Albanians.",
        "frontImg": "191820cf.jpg",
        "frontTitle": "Victor Emmanuel III",
        "frontDescription": "Crowned Savoia Shield between twisted branches of laurel and oak the issuer name above and the engraver's initials below",
        "backImg": "191820cb.jpg",
        "backTitle": "Kingdom of Italy",
        "backDescription": "Value and date of the coin within a hexagon surrounded by a laurel wreath and the mintmark below the date",
        "bottomContent": "italy",
        "price": "$20.00",
        "ebay": ""
      },
      {
        "id": 249,
        "country": "Italy",
        "countryname": "italy",
        "type": "italy",
        "typeid": 11,
        "name": "10 Centesimi",
        "monarchtype": "",
        "year": 1921,
        "tagline": "italy",
        "title": "1921 10 Centesimi",
        "description": "Victor Emmanuel III born Vittorio Emanuele Ferdinando Maria Gennaro di Savoia was King of Italy from 29 July 1900 until his abdication on 9 May 1946. A member of the House of Savoy he also reigned as Emperor of Ethiopia and King of the Albanians.",
        "frontImg": "192110cf.jpg",
        "frontTitle": "Emanuele III",
        "frontDescription": "The portrait of King Vittorio Emanuele III facing left with the name of the author below the neck with lettering around the outside",
        "backImg": "192110cb.jpg",
        "backTitle": "Centesimi",
        "backDescription": "A honeybee on a flower with the value on the bottom right the date on the bottom and the mintmark to the left",
        "bottomContent": "italy",
        "price": "$20.00",
        "ebay": ""
      },
      {
        "id": 250,
        "country": "Italy",
        "countryname": "italy",
        "type": "italy",
        "typeid": 11,
        "name": "5 Centesimi",
        "monarchtype": "",
        "year": 1927,
        "tagline": "italy",
        "title": "1927 5 Centesimi",
        "description": "Victor Emmanuel III born Vittorio Emanuele Ferdinando Maria Gennaro di Savoia was King of Italy from 29 July 1900 until his abdication on 9 May 1946. A member of the House of Savoy he also reigned as Emperor of Ethiopia and King of the Albanians.",
        "frontImg": "19275cf.jpg",
        "frontTitle": "Emanuele III",
        "frontDescription": "Head of king Victor Emmanuel III facing left lettering around edge.",
        "backImg": "19275cb.jpg",
        "backTitle": "Centesimi",
        "backDescription": "Large central ear of wheat divides value with the date to the lower right and the mintmark to the bottom right of the wheat.",
        "bottomContent": "italy",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 251,
        "country": "Italy",
        "countryname": "italy",
        "type": "italy",
        "typeid": 11,
        "name": "20 Centesimi",
        "monarchtype": "",
        "year": 1894,
        "tagline": "italy",
        "title": "1894 20 Centesimi",
        "description": "Umberto I (Italian: Umberto Ranieri Carlo Emanuele Giovanni Maria Ferdinando Eugenio di Savoia; 14 March 1844 \u001a 29 July 1900) was King of Italy from 9 January 1878 until his assassination in 1900. His reign saw Italy's expansion into the Horn of Africa as well as the creation of the Triple Alliance among Italy Germany and Austria-Hungary.",
        "frontImg": "189420cf.jpg",
        "frontTitle": "Umberto I",
        "frontDescription": "Royal crown in the centre with the date below all encircled by a wreath made by a branch of laurel and one of oak the Star of Italy above and the Mintmark below the wreath",
        "backImg": "189420cb.jpg",
        "backTitle": "Centesimi",
        "backDescription": "Denomination inside a central beaded circle and lettering around outside of circle",
        "bottomContent": "italy",
        "price": "$90.00",
        "ebay": ""
      },
      {
        "id": 252,
        "country": "Italy",
        "countryname": "italy",
        "type": "italy",
        "typeid": 11,
        "name": "1 Lira",
        "monarchtype": "",
        "year": 1863,
        "tagline": "italy",
        "title": "1863 1 Lira",
        "description": "Victor Emmanuel II was King of Sardinia from 23 March 1849 until 17 March 1861 when he assumed the title of King of Italy and became the first king of an independent united Italy since the 6th century a title he held until his death in 1878.",
        "frontImg": "18631lf.jpg",
        "frontTitle": "Victor Emmanuel III",
        "frontDescription": "Head of King Vittorio Emanuele II right with the name of the engraver below the neck",
        "backImg": "18631lb.jpg",
        "backTitle": "Lira",
        "backDescription": "Savoia coat of arms decorated with the Collare dell'Annunziata both within a laurel wreath.Below value of the coin and mintmark.",
        "bottomContent": "italy",
        "price": "$20.00",
        "ebay": ""
      },
      {
        "id": 253,
        "country": "Italy",
        "countryname": "italy",
        "type": "italy",
        "typeid": 11,
        "name": "5 Centesimi",
        "monarchtype": "",
        "year": 1862,
        "tagline": "italy",
        "title": "1862 5 Centesimi",
        "description": "Victor Emmanuel II was King of Sardinia from 23 March 1849 until 17 March 1861 when he assumed the title of King of Italy and became the first king of an independent united Italy since the 6th century a title he held until his death in 1878.",
        "frontImg": "18625cf.jpg",
        "frontTitle": "Victor Emmanuel III",
        "frontDescription": "Head of King Vittorio Emanuele II left and the name of the engraver below the neck",
        "backImg": "18625cb.jpg",
        "backTitle": "Centesimi",
        "backDescription": "Value and date within two branches one of laurel and the other of oak with the star of Italy above and the mintmark below",
        "bottomContent": "italy",
        "price": "$20.00",
        "ebay": ""
      },
      {
        "id": 254,
        "country": "Italy",
        "countryname": "italy",
        "type": "italy",
        "typeid": 11,
        "name": "50 Lira",
        "monarchtype": "",
        "year": 1954,
        "tagline": "italy",
        "title": "1954 50 Lira",
        "description": "Republic of Italy 1954 50 Lira",
        "frontImg": "195450lf.jpg",
        "frontTitle": "1954 50 Lira",
        "frontDescription": "Woman head with oak wreath in hair facing right. Designer and engraver names below.",
        "backImg": "195450lb.jpg",
        "backTitle": "Lira",
        "backDescription": "Representation of the god Vulcan beating iron on an anvil. Date left denomination right",
        "bottomContent": "italy",
        "price": "$30.00",
        "ebay": ""
      },
      {
        "id": 255,
        "country": "Italy",
        "countryname": "italy",
        "type": "italy",
        "typeid": 11,
        "name": "10 Lira",
        "monarchtype": "",
        "year": 1952,
        "tagline": "italy",
        "title": "1952 italy 10 lire",
        "description": "1952 italy 10 lire",
        "frontImg": "195210lf.jpg",
        "frontTitle": "Giampaoli",
        "frontDescription": "Plough lettering above date below",
        "backImg": "195210lb.jpg",
        "backTitle": "Lira",
        "backDescription": "Two ears of wheat dividing by value",
        "bottomContent": "italy",
        "price": "$5.00",
        "ebay": ""
      },
      {
        "id": 256,
        "country": "Italy",
        "countryname": "italy",
        "type": "italy",
        "typeid": 11,
        "name": "5 Lira",
        "monarchtype": "",
        "year": 1953,
        "tagline": "italy",
        "title": "1953 italy 5 lire",
        "description": "1953 italy 5 lire",
        "frontImg": "19535lf.jpg",
        "frontTitle": "Republic of Italy",
        "frontDescription": "Rudder",
        "backImg": "19535lb.jpg",
        "backTitle": "Lira",
        "backDescription": "Dolphin and value",
        "bottomContent": "italy",
        "price": "$5.00",
        "ebay": ""
      },
      {
        "id": 257,
        "country": "Italy",
        "countryname": "italy",
        "type": "italy",
        "typeid": 11,
        "name": "50 Centesimi",
        "monarchtype": "",
        "year": 1942,
        "tagline": "italy",
        "title": "1942 50 Centesimi",
        "description": "Victor Emmanuel III born Vittorio Emanuele Ferdinando Maria Gennaro di Savoia was King of Italy from 29 July 1900 until his abdication on 9 May 1946. A member of the House of Savoy he also reigned as Emperor of Ethiopia and King of the Albanians.",
        "frontImg": "194250cf.jpg",
        "frontTitle": "Victor Emmanuel III",
        "frontDescription": "Head of King Vittorio Emanuele III facing right and the name of the engraver below the neck",
        "backImg": "194250cb.jpg",
        "backTitle": "Italia",
        "backDescription": "Side view of an eagle with raised wings holding a fasces with an axe in its claws date and year of Fascist Era on the left near the tail. Below the fasces in exergue the Savoy shield divides the value and mintmark at sides.",
        "bottomContent": "italy",
        "price": "$5.00",
        "ebay": ""
      },
      {
        "id": 258,
        "country": "Italy",
        "countryname": "italy",
        "type": "italy",
        "typeid": 11,
        "name": "100 Lira",
        "monarchtype": "",
        "year": 1959,
        "tagline": "italy",
        "title": "1959 100 Lira",
        "description": "1959 100 Lira",
        "frontImg": "1959100lf.jpg",
        "frontTitle": "100 Lira",
        "frontDescription": "Columbus ships",
        "backImg": "1959100lb.jpg",
        "backTitle": "Giampaoli",
        "backDescription": "Bust left within wreath",
        "bottomContent": "italy",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 259,
        "country": "Italy",
        "countryname": "italy",
        "type": "italy",
        "typeid": 11,
        "name": "100 Lira",
        "monarchtype": "",
        "year": 1955,
        "tagline": "italy",
        "title": "1955 100 Lira",
        "description": "1955 100 Lira",
        "frontImg": "1955100lf.jpg",
        "frontTitle": "100 Lira",
        "frontDescription": "Female head with laurel wreath facing left dividing the country name on both sides. Names of the designer and engraver below.",
        "backImg": "1955100lb.jpg",
        "backTitle": "Giampaoli",
        "backDescription": "Goddess Minerva facing left reaching an olive tree with her raised right hand while in the left holds a long spear. Value at left of olive trunk; mintmark at right of the Goddess. Date below in exergu",
        "bottomContent": "italy",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 260,
        "country": "Italy",
        "countryname": "italy",
        "type": "italy",
        "typeid": 11,
        "name": "2 Lira",
        "monarchtype": "",
        "year": 1939,
        "tagline": "italy",
        "title": "1939 2 Lira",
        "description": "1939 2 Lira",
        "frontImg": "19392lf.jpg",
        "frontTitle": "2 Lira",
        "frontDescription": "Head of King Vittorio Emanuele III facing right.Below the neck the name of the engraver",
        "backImg": "19392lb.jpg",
        "backTitle": "Romagnoli",
        "backDescription": "Eagle in profile with outstretched wings and head left has fasces with axe in its claws. The eagle is encircled by a laurel wreath made of two branches. Where the branches meet together at base is the Shield of Savoy which divides the value below. The date is on the left of the value while the mint mark and the year in the Fascist Era are at right.",
        "bottomContent": "italy",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 261,
        "country": "Italy",
        "countryname": "italy",
        "type": "italy",
        "typeid": 11,
        "name": "10 Centesimi",
        "monarchtype": "",
        "year": 1867,
        "tagline": "italy",
        "title": "1867 10 Centesimi",
        "description": "Victor Emmanuel II was King of Sardinia from 23 March 1849 until 17 March 1861 when he assumed the title of King of Italy and became the first king of an independent united Italy since the 6th century a title he held until his death in 1878.",
        "frontImg": "186710cf.jpg",
        "frontTitle": "10 Centesimi",
        "frontDescription": "Head of King Vittorio Emanuele II left and the name of the engraver below the neck",
        "backImg": "186710cb.jpg",
        "backTitle": "Victor Emmanuel II",
        "backDescription": "Value and date within two branches one of laurel and the other of oak and the star of Italy above and the sign of the mint below",
        "bottomContent": "italy",
        "price": "$50.00",
        "ebay": ""
      },
      {
        "id": 262,
        "country": "Austria",
        "countryname": "austria",
        "type": "austria",
        "typeid": 3,
        "name": "1 shilling",
        "monarchtype": "",
        "year": 1947,
        "tagline": "austria",
        "title": "1947 1 shilling",
        "description": "1947 1 shilling",
        "frontImg": "1sh1947f.jpg",
        "frontTitle": "REPUBLIK·ÖSTERREICH",
        "frontDescription": "Austria coat of arms (an eagle carries the Austrian escutcheon)",
        "backImg": "1sh1947b.jpg",
        "backTitle": "EIN SCHILLING",
        "backDescription": "A sower the face value and the state title",
        "bottomContent": "austria",
        "price": "$5.00",
        "ebay": ""
      },
      {
        "id": 263,
        "country": "Austria",
        "countryname": "austria",
        "type": "austria",
        "typeid": 3,
        "name": "50 Groschen",
        "monarchtype": "",
        "year": 1947,
        "tagline": "austria",
        "title": "1947 50 Groschen",
        "description": "1947 50 Groschen",
        "frontImg": "194750gf.jpg",
        "frontTitle": "REPUBLIK·ÖSTERREICH",
        "frontDescription": "Austria coat of arms (an eagle carries the Austrian escutcheon)",
        "backImg": "194750gb.jpg",
        "backTitle": "50 Groschen",
        "backDescription": "Austrian escutcheon and four ears",
        "bottomContent": "austria",
        "price": "$5.00",
        "ebay": ""
      },
      {
        "id": 264,
        "country": "Austria",
        "countryname": "austria",
        "type": "austria",
        "typeid": 3,
        "name": "20 Groschen",
        "monarchtype": "",
        "year": 1954,
        "tagline": "austria",
        "title": "1954 20 Groschen",
        "description": "1954 20 Groschen",
        "frontImg": "195420gf.jpg",
        "frontTitle": "REPUBLIK·ÖSTERREICH",
        "frontDescription": "Name of the country value at center above the date",
        "backImg": "195420gb.jpg",
        "backTitle": "20 Groschen",
        "backDescription": "Austrian escutcheon and four ears",
        "bottomContent": "austria",
        "price": "$5.00",
        "ebay": ""
      },
      {
        "id": 265,
        "country": "Austria",
        "countryname": "austria",
        "type": "austria",
        "typeid": 3,
        "name": "10 Groschen",
        "monarchtype": "",
        "year": 1952,
        "tagline": "austria",
        "title": "1952 10 Groschen",
        "description": "1952 10 Groschen",
        "frontImg": "195210gf.jpg",
        "frontTitle": "REPUBLIK·ÖSTERREICH",
        "frontDescription": "The Austrian coat of arms (the imperial eagle carrying the Austrian shield on its breast) with broken chains which signify Austria as an independent country and the country name as a stylized inscription and denomination in the scalloped rim.",
        "backImg": "195210gb.jpg",
        "backTitle": "10 Groschen",
        "backDescription": "The face value above the year in a scalloped rim.",
        "bottomContent": "austria",
        "price": "$5.00",
        "ebay": ""
      },
      {
        "id": 266,
        "country": "Austria",
        "countryname": "austria",
        "type": "austria",
        "typeid": 3,
        "name": "2 Groschen",
        "monarchtype": "",
        "year": 1952,
        "tagline": "austria",
        "title": "1952 2 Groschen",
        "description": "1952 2 Groschen",
        "frontImg": "19522gf.jpg",
        "frontTitle": "REPUBLIK·ÖSTERREICH",
        "frontDescription": "Austria coat of arms (an eagle carries the Austrian escutcheon)",
        "backImg": "19522gb.jpg",
        "backTitle": "2 Groschen",
        "backDescription": "enomination in circle of dots name of the state around date below.",
        "bottomContent": "austria",
        "price": "$5.00",
        "ebay": ""
      },
      {
        "id": 267,
        "country": "Austria",
        "countryname": "austria",
        "type": "austria",
        "typeid": 3,
        "name": "1 shilling",
        "monarchtype": "",
        "year": 1925,
        "tagline": "austria",
        "title": "1925 1 shilling",
        "description": "1925 1 shilling",
        "frontImg": "19251sf.jpg",
        "frontTitle": "REPUBLIK·ÖSTERREICH",
        "frontDescription": "Parliament building in Vienna",
        "backImg": "19251sb.jpg",
        "backTitle": "2 Groschen",
        "backDescription": "Coat of arms on spray of edelweiss",
        "bottomContent": "austria",
        "price": "$40.00",
        "ebay": ""
      },
      {
        "id": 268,
        "country": "Austria",
        "countryname": "austria",
        "type": "austria",
        "typeid": 3,
        "name": "10 Groschen",
        "monarchtype": "",
        "year": 1924,
        "tagline": "austria",
        "title": "1924 10 Groschen",
        "description": "1924 10 Groschen",
        "frontImg": "192410gf.jpg",
        "frontTitle": "REPUBLIK·ÖSTERREICH",
        "frontDescription": "A woman of the region of Tyrol facing right wearing a hat with mountains in the background",
        "backImg": "192410gb.jpg",
        "backTitle": "10 Groschen",
        "backDescription": "The value above year within a circular floral wreath of gentian flowers alternating with edelweiss flowers",
        "bottomContent": "austria",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 269,
        "country": "Austria",
        "countryname": "austria",
        "type": "austria",
        "typeid": 3,
        "name": "200 Kronen",
        "monarchtype": "",
        "year": 1924,
        "tagline": "austria",
        "title": "1924 200 Kronen",
        "description": "1924 200 Kronen",
        "frontImg": "1924200kf.jpg",
        "frontTitle": "REPUBLIK·ÖSTERREICH",
        "frontDescription": "Cross potent (also known as a crutch cross is a form of heraldic cross)",
        "backImg": "1924200kb.jpg",
        "backTitle": "Häuslerr",
        "backDescription": "2oo",
        "bottomContent": "austria",
        "price": "$5.00",
        "ebay": ""
      },
      {
        "id": 270,
        "country": "Austria",
        "countryname": "austria",
        "type": "austria",
        "typeid": 3,
        "name": "20 Hellers",
        "monarchtype": "",
        "year": 1916,
        "tagline": "austria",
        "title": "1916 20 Hellers",
        "description": "1916 20 Hellers",
        "frontImg": "191620hf.jpg",
        "frontTitle": "REPUBLIK·ÖSTERREICH",
        "frontDescription": "Crowned double-headed eagle with Austrian shield on breast.",
        "backImg": "191620hb.jpg",
        "backTitle": "Schwartz",
        "backDescription": "Value within wreath date below",
        "bottomContent": "austria",
        "price": "$5.00",
        "ebay": ""
      },
      {
        "id": 271,
        "country": "Austria",
        "countryname": "austria",
        "type": "austria",
        "typeid": 3,
        "name": "10 Hellers",
        "monarchtype": "",
        "year": 1916,
        "tagline": "austria",
        "title": "1916 10 Hellers",
        "description": "1916 10 Hellers",
        "frontImg": "191610hf.jpg",
        "frontTitle": "REPUBLIK·ÖSTERREICH",
        "frontDescription": "Habsburg coat of arms",
        "backImg": "191610hb.jpg",
        "backTitle": "Schwartz",
        "backDescription": "Value within wreath date below",
        "bottomContent": "austria",
        "price": "$5.00",
        "ebay": ""
      },
      {
        "id": 272,
        "country": "Austria",
        "countryname": "austria",
        "type": "austria",
        "typeid": 3,
        "name": "2 Hellers",
        "monarchtype": "",
        "year": 1894,
        "tagline": "austria",
        "title": "1894 2 Hellers",
        "description": "1894 2 Hellers",
        "frontImg": "18942hf.jpg",
        "frontTitle": "REPUBLIK·ÖSTERREICH",
        "frontDescription": "The 1867\u001a1915 coat of arms of Austria-Hungary a double-headed imperial eagle with Habsburg-Lorraine shield on breast.",
        "backImg": "18942hb.jpg",
        "backTitle": "Schwartz",
        "backDescription": "Value within wreath date below",
        "bottomContent": "austria",
        "price": "$5.00",
        "ebay": ""
      },
      {
        "id": 273,
        "country": "Austria",
        "countryname": "austria",
        "type": "austria",
        "typeid": 3,
        "name": "1 Hellers",
        "monarchtype": "",
        "year": 1895,
        "tagline": "austria",
        "title": "1895 1 Hellers",
        "description": "1895 1 Hellers",
        "frontImg": "18951hf.jpg",
        "frontTitle": "REPUBLIK·ÖSTERREICH",
        "frontDescription": "The double headed imperial eagle with Habsburg-Lorraine shield on breast.",
        "backImg": "18951hb.jpg",
        "backTitle": "Neudeck",
        "backDescription": "Value above sprays date below within curved stylised shield",
        "bottomContent": "austria",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 274,
        "country": "Austria",
        "countryname": "austria",
        "type": "austria",
        "typeid": 3,
        "name": "20 Kreuzers",
        "monarchtype": "",
        "year": 1869,
        "tagline": "austria",
        "title": "1869 20 Kreuzers",
        "description": "Franz Joseph I or Francis Joseph I (German: Franz Joseph Karl [f\u001aants \u001ajo\u001az\u001af \u001aka\u001al]; Hungarian: Ferenc József Károly [\u001af\u001ar\u001ant\u001as \u001ajo\u001a\u001a\u001af \u001aka\u001aroj]; 18 August 1830 \u001a 21 November 1916) was Emperor of Austria King of Hungary and the ruler of the other states of the Habsburg monarchy from 2 December 1848 until his death in 1916.[1] In the early part of his reign his realms and territories were referred to as the Austrian Empire but were reconstituted as the dual monarchy of the Austro-Hungarian Empire in 1867. From 1 May 1850 to 24 August 1866 he was also president of the German Confederation.",
        "frontImg": "186920kf.jpg",
        "frontTitle": "20 Kreuzers",
        "frontDescription": "Head to the right legend around (starts 7h).",
        "backImg": "186920kb.jpg",
        "backTitle": "Francis Joseph I",
        "backDescription": "Crowned imperial eagle value at centre legend around (starts 1h) for Hungariae Bohemiae Galiciae Lodomeriae Illyriae Rex Archidux Austriae.",
        "bottomContent": "austria",
        "price": "$30.00",
        "ebay": ""
      },
      {
        "id": 275,
        "country": "Austria",
        "countryname": "austria",
        "type": "austria",
        "typeid": 3,
        "name": "10 Kreuzers",
        "monarchtype": "",
        "year": 1872,
        "tagline": "austria",
        "title": "1872 10 Kreuzers",
        "description": "Franz Joseph I or Francis Joseph I (German: Franz Joseph Karl [f\u001aants \u001ajo\u001az\u001af \u001aka\u001al]; Hungarian: Ferenc József Károly [\u001af\u001ar\u001ant\u001as \u001ajo\u001a\u001a\u001af \u001aka\u001aroj]; 18 August 1830 \u001a 21 November 1916) was Emperor of Austria King of Hungary and the ruler of the other states of the Habsburg monarchy from 2 December 1848 until his death in 1916.[1] In the early part of his reign his realms and territories were referred to as the Austrian Empire but were reconstituted as the dual monarchy of the Austro-Hungarian Empire in 1867. From 1 May 1850 to 24 August 1866 he was also president of the German Confederation.",
        "frontImg": "187210kf.jpg",
        "frontTitle": "20 Kreuzers",
        "frontDescription": "Laureate head facing right. Legend around (starts 8h)",
        "backImg": "187210kb.jpg",
        "backTitle": "Francis Joseph I",
        "backDescription": "Crowned double headed imperial eagle with value on breast. Legend around (starts 1h) date at end of legend.",
        "bottomContent": "austria",
        "price": "$15.00",
        "ebay": ""
      },
      {
        "id": 276,
        "country": "Austria",
        "countryname": "austria",
        "type": "austria",
        "typeid": 3,
        "name": "5 Kreuzers",
        "monarchtype": "",
        "year": 1858,
        "tagline": "austria",
        "title": "1858 5 Kreuzers",
        "description": "Franz Joseph I or Francis Joseph I (German: Franz Joseph Karl [f\u001aants \u001ajo\u001az\u001af \u001aka\u001al]; Hungarian: Ferenc József Károly [\u001af\u001ar\u001ant\u001as \u001ajo\u001a\u001a\u001af \u001aka\u001aroj]; 18 August 1830 \u001a 21 November 1916) was Emperor of Austria King of Hungary and the ruler of the other states of the Habsburg monarchy from 2 December 1848 until his death in 1916.[1] In the early part of his reign his realms and territories were referred to as the Austrian Empire but were reconstituted as the dual monarchy of the Austro-Hungarian Empire in 1867. From 1 May 1850 to 24 August 1866 he was also president of the German Confederation.",
        "frontImg": "18585kf.jpg",
        "frontTitle": "20 Kreuzers",
        "frontDescription": "The laureate bust of King Franz Joseph I facing right",
        "backImg": "18585kb.jpg",
        "backTitle": "Francis Joseph I",
        "backDescription": "Crowned denomination above date",
        "bottomContent": "austria",
        "price": "$25.00",
        "ebay": ""
      },
      {
        "id": 277,
        "country": "Austria",
        "countryname": "austria",
        "type": "austria",
        "typeid": 3,
        "name": "1 Kreuzers",
        "monarchtype": "",
        "year": 1858,
        "tagline": "austria",
        "title": "1851 1 Kreuzers",
        "description": "Franz Joseph I or Francis Joseph I (German: Franz Joseph Karl [f\u001aants \u001ajo\u001az\u001af \u001aka\u001al]; Hungarian: Ferenc József Károly [\u001af\u001ar\u001ant\u001as \u001ajo\u001a\u001a\u001af \u001aka\u001aroj]; 18 August 1830 \u001a 21 November 1916) was Emperor of Austria King of Hungary and the ruler of the other states of the Habsburg monarchy from 2 December 1848 until his death in 1916.[1] In the early part of his reign his realms and territories were referred to as the Austrian Empire but were reconstituted as the dual monarchy of the Austro-Hungarian Empire in 1867. From 1 May 1850 to 24 August 1866 he was also president of the German Confederation.",
        "frontImg": "18511kf.jpg",
        "frontTitle": "1 Kreuzers",
        "frontDescription": "The double-headed eagle lettering around outside within a beaded border",
        "backImg": "18511kb.jpg",
        "backTitle": "Gross",
        "backDescription": "The value above dividing line year and mintmark below",
        "bottomContent": "austria",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 278,
        "country": "Ireland",
        "countryname": "ireland",
        "type": "ireland",
        "typeid": 22,
        "name": "1 shilling",
        "monarchtype": "",
        "year": 1926,
        "tagline": "ireland",
        "title": "1926 1 shilling",
        "description": "The Irish Free State (1922\u001a1937) was established as an independent Dominion within the British Empire.",
        "frontImg": "19261sf.jpg",
        "frontTitle": "saorstátéireann",
        "frontDescription": "Date divided by Cláirseach (or Gaelic harp).",
        "backImg": "19261sb.jpg",
        "backTitle": "Irish Free State",
        "backDescription": "Bull facing right. Denomination above and in exergue.",
        "bottomContent": "ireland",
        "price": "$75.00",
        "ebay": ""
      },
      {
        "id": 279,
        "country": "Ireland",
        "countryname": "ireland",
        "type": "ireland",
        "typeid": 22,
        "name": "1 Reul",
        "monarchtype": "",
        "year": 1923,
        "tagline": "ireland",
        "title": "1923 1 Reul",
        "description": "The Irish Free State (1922\u001a1937) was established as an independent Dominion within the British Empire.",
        "frontImg": "19231rf.jpg",
        "frontTitle": "saorstátéireann",
        "frontDescription": "Date divided by Cláirseach (or Gaelic harp).",
        "backImg": "19231rb.jpg",
        "backTitle": "Irish Free State",
        "backDescription": "Irish Wolfhound facing left",
        "bottomContent": "ireland",
        "price": "$20.00",
        "ebay": ""
      },
      {
        "id": 280,
        "country": "Ireland",
        "countryname": "ireland",
        "type": "ireland",
        "typeid": 22,
        "name": "½ Reul",
        "monarchtype": "",
        "year": 1934,
        "tagline": "ireland",
        "title": "1934 ½ Reul",
        "description": "The Irish Free State (1922\u001a1937) was established as an independent Dominion within the British Empire.",
        "frontImg": "193451rf.jpg",
        "frontTitle": "saorstátéireann",
        "frontDescription": "Date divided by Cláirseach (or Gaelic harp).",
        "backImg": "193451rb.jpg",
        "backTitle": "Irish Free State",
        "backDescription": "Seated hare facing left",
        "bottomContent": "ireland",
        "price": "$5.00",
        "ebay": ""
      },
      {
        "id": 281,
        "country": "Ireland",
        "countryname": "ireland",
        "type": "ireland",
        "typeid": 22,
        "name": "1 shilling",
        "monarchtype": "",
        "year": 1951,
        "tagline": "ireland",
        "title": "1951 1 shilling",
        "description": "The Irish Free State (1922\u001a1937) was established as an independent Dominion within the British Empire.",
        "frontImg": "19511sf.jpg",
        "frontTitle": "saorstátéireann",
        "frontDescription": "Date divided by Cláirseach (or Gaelic harp).",
        "backImg": "19511sb.jpg",
        "backTitle": "Irish Free State",
        "backDescription": "Bull facing right. Denomination above and in exergue.",
        "bottomContent": "ireland",
        "price": "$30.00",
        "ebay": ""
      },
      {
        "id": 282,
        "country": "Ireland",
        "countryname": "ireland",
        "type": "ireland",
        "typeid": 22,
        "name": "1 Reul",
        "monarchtype": "",
        "year": 1948,
        "tagline": "ireland",
        "title": "1948 1 Reul",
        "description": "The Irish Free State (1922\u001a1937) was established as an independent Dominion within the British Empire.",
        "frontImg": "19481rf.jpg",
        "frontTitle": "saorstátéireann",
        "frontDescription": "Date divided by Cláirseach (or Gaelic harp).",
        "backImg": "19481rb.jpg",
        "backTitle": "Irish Free State",
        "backDescription": "Irish Wolfhound facing left",
        "bottomContent": "ireland",
        "price": "$5.00",
        "ebay": ""
      },
      {
        "id": 283,
        "country": "Ireland",
        "countryname": "ireland",
        "type": "ireland",
        "typeid": 22,
        "name": "½ Reul",
        "monarchtype": "",
        "year": 1934,
        "tagline": "ireland",
        "title": "1934 ½ Reul",
        "description": "The Irish Free State (1922\u001a1937) was established as an independent Dominion within the British Empire.",
        "frontImg": "1934251rf.jpg",
        "frontTitle": "saorstátéireann",
        "frontDescription": "Date divided by Cláirseach (or Gaelic harp).",
        "backImg": "1934251rb.jpg",
        "backTitle": "Irish Free State",
        "backDescription": "Seated hare facing left",
        "bottomContent": "ireland",
        "price": "$5.00",
        "ebay": ""
      },
      {
        "id": 284,
        "country": "Ireland",
        "countryname": "ireland",
        "type": "ireland",
        "typeid": 22,
        "name": "1 Reul",
        "monarchtype": "",
        "year": 1928,
        "tagline": "ireland",
        "title": "1928 1 Reul",
        "description": "The Irish Free State (1922\u001a1937) was established as an independent Dominion within the British Empire.",
        "frontImg": "19282s6df.jpg",
        "frontTitle": "saorstátéireann",
        "frontDescription": "Date divided by Cláirseach (or Gaelic harp).",
        "backImg": "19282s6db.jpg",
        "backTitle": "Irish Free State",
        "backDescription": "An Irish Hunter horse facing left with the denomination below",
        "bottomContent": "ireland",
        "price": "$200.00",
        "ebay": ""
      },
      {
        "id": 285,
        "country": "Ireland",
        "countryname": "ireland",
        "type": "ireland",
        "typeid": 22,
        "name": "1 Reul",
        "monarchtype": "",
        "year": 1928,
        "tagline": "ireland",
        "title": "1928 2 shillings (florin)",
        "description": "The Irish Free State (1922\u001a1937) was established as an independent Dominion within the British Empire.",
        "frontImg": "19282sf.jpg",
        "frontTitle": "saorstátéireann",
        "frontDescription": "Date divided by Cláirseach (or Gaelic harp).",
        "backImg": "19282sb.jpg",
        "backTitle": "Irish Free State",
        "backDescription": "Salmon and value",
        "bottomContent": "ireland",
        "price": "$30.00",
        "ebay": ""
      },
      {
        "id": 286,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "maundy",
        "typeid": 11,
        "name": "King Charles II",
        "monarchtype": "Charles2",
        "year": "1660-1669",
        "tagline": "maundy",
        "title": "1660-1669 2 Pence",
        "description": "This coin was issued specifically as Maundy money as part of the Royal Maundy ceremony. The King would have handed these out personally to the elderly at a Church in London at the Maundy service.",
        "frontImg": "1660692pf.jpg",
        "frontTitle": "CAROLVS·II",
        "frontDescription": "Crowned bust of King Charles II left to bottom edge of coin single-arched crown no inner circle denomination behind head to right legend above outer toothed border both sides.",
        "backImg": "1660692pb.jpg",
        "backTitle": "Maundy",
        "backDescription": "Quartered shield of arms over cross fourchée legend around initial mark crown on reverse only legend commences upper right.",
        "bottomContent": "King Charles II",
        "price": "$500.00",
        "ebay": ""
      },
      {
        "id": 287,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "maundy",
        "typeid": 11,
        "name": "King Charles II",
        "monarchtype": "Charles2",
        "year": 1672,
        "tagline": "maundy",
        "title": "1672 1 Pence",
        "description": "This coin was issued specifically as Maundy money as part of the Royal Maundy ceremony. The King would have handed these out personally to the elderly at a Church in London at the Maundy service.",
        "frontImg": "16721pf.jpg",
        "frontTitle": "CAROLVS·II",
        "frontDescription": "Laureate and draped bust of King Charles II right legend around.",
        "backImg": "16721pb.jpg",
        "backTitle": "Maundy",
        "backDescription": "Crowned C dividing date with mark of value above and legend around.",
        "bottomContent": "King Charles II",
        "price": "$500.00",
        "ebay": ""
      },
      {
        "id": 288,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "maundy",
        "typeid": 11,
        "name": "King Charles II",
        "monarchtype": "Charles2",
        "year": 1679,
        "tagline": "maundy",
        "title": "1679 4 Pence",
        "description": "This coin was issued specifically as Maundy money as part of the Royal Maundy ceremony. The King would have handed these out personally to the elderly at a Church in London at the Maundy service.",
        "frontImg": "16794pf.jpg",
        "frontTitle": "CAROLVS·II",
        "frontDescription": "Laureate and draped portrait of King Charles II right legend around.",
        "backImg": "16794pb.jpg",
        "backTitle": "Maundy",
        "backDescription": "Four central interlinked C's with national emblems in angles (English rose Scottish thistle French fleur de lis Irish harp) with crown above dividing date and legend around.",
        "bottomContent": "King Charles II",
        "price": "$500.00",
        "ebay": ""
      },
      {
        "id": 289,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "maundy",
        "typeid": 11,
        "name": "King Charles II",
        "monarchtype": "Charles2",
        "year": 1683,
        "tagline": "maundy",
        "title": "1683 2 Pence",
        "description": "This coin was issued specifically as Maundy money as part of the Royal Maundy ceremony. The King would have handed these out personally to the elderly at a Church in London at the Maundy service.",
        "frontImg": "16832pf.jpg",
        "frontTitle": "CAROLVS·II",
        "frontDescription": "Laureate and draped portrait of King Charles II right legend around.",
        "backImg": "16832pb.jpg",
        "backTitle": "Maundy",
        "backDescription": "Crown above interlinked C's dividing date legend around.",
        "bottomContent": "King Charles II",
        "price": "$500.00",
        "ebay": ""
      },
      {
        "id": 290,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "maundy",
        "typeid": 11,
        "name": "King Charles II",
        "monarchtype": "Charles2",
        "year": 1684,
        "tagline": "maundy",
        "title": "1684 3 Pence",
        "description": "This coin was issued specifically as Maundy money as part of the Royal Maundy ceremony. The King would have handed these out personally to the elderly at a Church in London at the Maundy service.",
        "frontImg": "16843pf.jpg",
        "frontTitle": "CAROLVS·II",
        "frontDescription": "Laureate and draped portrait of King Charles II right legend around.",
        "backImg": "16843pb.jpg",
        "backTitle": "Maundy",
        "backDescription": "Three interlocked C's crown above dividing date legend around.",
        "bottomContent": "King Charles II",
        "price": "$500.00",
        "ebay": ""
      },
      {
        "id": 291,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "maundy",
        "typeid": 11,
        "name": "King James II",
        "monarchtype": "James2",
        "year": 1685,
        "tagline": "maundy",
        "title": "1685 3 Pence",
        "description": "This coin was issued specifically as Maundy money as part of the Royal Maundy ceremony. The King would have handed these out personally to the elderly at a Church in London at the Maundy service.",
        "frontImg": "16853pf.jpg",
        "frontTitle": "IACOBVS·II",
        "frontDescription": "Laureate portrait of King James II left legend around.",
        "backImg": "16853pb.jpg",
        "backTitle": "Maundy",
        "backDescription": "Central denomination below crown dividing date legend around.",
        "bottomContent": "King James II",
        "price": "$250.00",
        "ebay": ""
      },
      {
        "id": 292,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "maundy",
        "typeid": 11,
        "name": "King James II",
        "monarchtype": "James2",
        "year": 1686,
        "tagline": "maundy",
        "title": "1686 4 Pence",
        "description": "This coin was issued specifically as Maundy money as part of the Royal Maundy ceremony. The King would have handed these out personally to the elderly at a Church in London at the Maundy service.",
        "frontImg": "16864pf.jpg",
        "frontTitle": "IACOBVS·II",
        "frontDescription": "Laureate portrait of King James II left legend around.",
        "backImg": "16864pb.jpg",
        "backTitle": "Maundy",
        "backDescription": "Central denomination below crown dividing date legend around.",
        "bottomContent": "King James II",
        "price": "$300.00",
        "ebay": ""
      },
      {
        "id": 293,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "maundy",
        "typeid": 11,
        "name": "King James II",
        "monarchtype": "James2",
        "year": 1686,
        "tagline": "maundy",
        "title": "1686 2 Pence",
        "description": "This coin was issued specifically as Maundy money as part of the Royal Maundy ceremony. The King would have handed these out personally to the elderly at a Church in London at the Maundy service.",
        "frontImg": "16862pf.jpg",
        "frontTitle": "IACOBVS·II",
        "frontDescription": "Laureate portrait of King James II left legend around.",
        "backImg": "16862pb.jpg",
        "backTitle": "Maundy",
        "backDescription": "Central denomination below crown dividing date legend around.",
        "bottomContent": "King James II",
        "price": "$300.00",
        "ebay": ""
      },
      {
        "id": 294,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "maundy",
        "typeid": 11,
        "name": "William and Mary",
        "monarchtype": "WilliamAndMary",
        "year": 1689,
        "tagline": "maundy",
        "title": "1689 William and Mary 3 Pence",
        "description": "This coin was issued specifically as Maundy money as part of the Royal Maundy ceremony. The King would have handed these out personally to the elderly at a Church in London at the Maundy service.",
        "frontImg": "16893pf.jpg",
        "frontTitle": "William III",
        "frontDescription": "First conjoined laureate busts of King William and Queen Mary legend around.",
        "backImg": "16893pb.jpg",
        "backTitle": "Mary II",
        "backDescription": "Central denomination below crown dividing date legend around.",
        "bottomContent": "William and Mary",
        "price": "$500.00",
        "ebay": ""
      },
      {
        "id": 295,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "maundy",
        "typeid": 11,
        "name": "William and Mary",
        "monarchtype": "WilliamAndMary",
        "year": 1689,
        "tagline": "maundy",
        "title": "1689 William and Mary 4 Pence",
        "description": "This coin was issued specifically as Maundy money as part of the Royal Maundy ceremony. The King would have handed these out personally to the elderly at a Church in London at the Maundy service.",
        "frontImg": "16894pf.jpg",
        "frontTitle": "William III",
        "frontDescription": "First conjoined laureate busts of King William and Queen Mary legend around.",
        "backImg": "16894pb.jpg",
        "backTitle": "Mary II",
        "backDescription": "Central denomination below crown dividing date legend around.",
        "bottomContent": "William and Mary",
        "price": "$500.00",
        "ebay": ""
      },
      {
        "id": 296,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "maundy",
        "typeid": 11,
        "name": "King William III",
        "monarchtype": "William3",
        "year": 1700,
        "tagline": "maundy",
        "title": "1700 4 Pence",
        "description": "This coin was issued specifically as Maundy money as part of the Royal Maundy ceremony. The King would have handed these out personally to the elderly at a Church in London at the Maundy service.",
        "frontImg": "17004pf.jpg",
        "frontTitle": "GVLIELMVS· III·DEI·GRA·",
        "frontDescription": "Laureate and draped bust of King William III right",
        "backImg": "17004pb.jpg",
        "backTitle": "King of Great Britain France and Ireland",
        "backDescription": "Central crowned denomination with divided date above legend around.",
        "bottomContent": "King William III",
        "price": "$500.00",
        "ebay": ""
      },
      {
        "id": 297,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "maundy",
        "typeid": 11,
        "name": "Queen Anne",
        "monarchtype": "Anne",
        "year": 1704,
        "tagline": "maundy",
        "title": "1704 4 Pence",
        "description": "This coin was issued specifically as Maundy money as part of the Royal Maundy ceremony. The King would have handed these out personally to the elderly at a Church in London at the Maundy service.",
        "frontImg": "17044pf.jpg",
        "frontTitle": "Maundy",
        "frontDescription": "Uncrowned draped bust of Queen Anne left legend around",
        "backImg": "17044pb.jpg",
        "backTitle": "Queen of Great Britain France and Ireland",
        "backDescription": "Central denomination below crown dividing date legend around.",
        "bottomContent": "Queen Anne",
        "price": "$250.00",
        "ebay": ""
      },
      {
        "id": 298,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "maundy",
        "typeid": 11,
        "name": "Queen Anne",
        "monarchtype": "Anne",
        "year": 1707,
        "tagline": "maundy",
        "title": "1707 2 Pence",
        "description": "This coin was issued specifically as Maundy money as part of the Royal Maundy ceremony. The King would have handed these out personally to the elderly at a Church in London at the Maundy service.",
        "frontImg": "17072pf.jpg",
        "frontTitle": "1st bust",
        "frontDescription": "First draped bust of Queen Anne left legend around.",
        "backImg": "17072pb.jpg",
        "backTitle": "Maundy",
        "backDescription": "Central denomination below crown dividing date legend around.",
        "bottomContent": "Queen Anne",
        "price": "$350.00",
        "ebay": ""
      },
      {
        "id": 299,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "maundy",
        "typeid": 11,
        "name": "King George I",
        "monarchtype": "George1",
        "year": 1721,
        "tagline": "maundy",
        "title": "1721 2 Pence",
        "description": "This coin was issued specifically as Maundy money as part of the Royal Maundy ceremony. The King would have handed these out personally to the elderly at a Church in London at the Maundy service.",
        "frontImg": "17212pf.jpg",
        "frontTitle": "GEORGIVS DEI GRA",
        "frontDescription": "Laureate and draped bust of King George I right legend around.",
        "backImg": "17212pb.jpg",
        "backTitle": "Maundy",
        "backDescription": "Central denomination below crown dividing date legend around.",
        "bottomContent": "King George I",
        "price": "$150.00",
        "ebay": ""
      },
      {
        "id": 300,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "maundy",
        "typeid": 11,
        "name": "King George II",
        "monarchtype": "George2",
        "year": 1732,
        "tagline": "maundy",
        "title": "1732 4 Pence",
        "description": "This coin was issued specifically as Maundy money as part of the Royal Maundy ceremony. The King would have handed these out personally to the elderly at a Church in London at the Maundy service.",
        "frontImg": "17324pf.jpg",
        "frontTitle": "GEORGIVS·II·DEI GRATIA·",
        "frontDescription": "Young laureate and draped bust of King George II left legend around.",
        "backImg": "17324pb.jpg",
        "backTitle": "Maundy",
        "backDescription": "Central denomination below crown dividing date legend around.",
        "bottomContent": "King George II",
        "price": "$150.00",
        "ebay": ""
      },
      {
        "id": 301,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "maundy",
        "typeid": 11,
        "name": "King George II",
        "monarchtype": "George2",
        "year": 1746,
        "tagline": "maundy",
        "title": "1746 3 Pence",
        "description": "This coin was issued specifically as Maundy money as part of the Royal Maundy ceremony. The King would have handed these out personally to the elderly at a Church in London at the Maundy service.",
        "frontImg": "17463pf.jpg",
        "frontTitle": "GEORGIVS·II· DEI·GRATIA·",
        "frontDescription": "Laureate and draped bust of King George II left legend around",
        "backImg": "17463pb.jpg",
        "backTitle": "Maundy",
        "backDescription": "Central denomination below crown dividing date legend around.",
        "bottomContent": "King George II",
        "price": "$350.00",
        "ebay": ""
      },
      {
        "id": 302,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "maundy",
        "typeid": 11,
        "name": "King George II",
        "monarchtype": "George2",
        "year": 1752,
        "tagline": "maundy",
        "title": "1752 1 Pence",
        "description": "This coin was issued specifically as Maundy money as part of the Royal Maundy ceremony. The King would have handed these out personally to the elderly at a Church in London at the Maundy service.",
        "frontImg": "17521pf.jpg",
        "frontTitle": "GEORGIVS·II· DEI·GRATIA·",
        "frontDescription": "Laureate and draped bust of King George II left legend around",
        "backImg": "17521pb.jpg",
        "backTitle": "Maundy",
        "backDescription": "Central denomination below crown dividing date legend around.",
        "bottomContent": "King George II",
        "price": "$350.00",
        "ebay": ""
      },
      {
        "id": 303,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "maundy",
        "typeid": 11,
        "name": "King George II",
        "monarchtype": "George2",
        "year": 1755,
        "tagline": "maundy",
        "title": "1755 1 Pence",
        "description": "This coin was issued specifically as Maundy money as part of the Royal Maundy ceremony. The King would have handed these out personally to the elderly at a Church in London at the Maundy service.",
        "frontImg": "17551pf.jpg",
        "frontTitle": "GEORGIVS·II· DEI·GRATIA·",
        "frontDescription": "Laureate and draped bust of King George II left legend around",
        "backImg": "17551pb.jpg",
        "backTitle": "Maundy",
        "backDescription": "Central denomination below crown dividing date legend around.",
        "bottomContent": "King George II",
        "price": "$350.00",
        "ebay": ""
      },
      {
        "id": 304,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "maundy",
        "typeid": 11,
        "name": "King George II",
        "monarchtype": "George2",
        "year": 1756,
        "tagline": "maundy",
        "title": "1756 2 Pence",
        "description": "This coin was issued specifically as Maundy money as part of the Royal Maundy ceremony. The King would have handed these out personally to the elderly at a Church in London at the Maundy service.",
        "frontImg": "17562pf.jpg",
        "frontTitle": "GEORGIVS·II· DEI·GRATIA·",
        "frontDescription": "Laureate and draped bust of King George II left legend around",
        "backImg": "17562pb.jpg",
        "backTitle": "Maundy",
        "backDescription": "Central denomination below crown dividing date legend around.",
        "bottomContent": "King George II",
        "price": "$350.00",
        "ebay": ""
      },
      {
        "id": 305,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "maundy",
        "typeid": 11,
        "name": "King George III",
        "monarchtype": "George3",
        "year": 1762,
        "tagline": "maundy",
        "title": "1762 3 Pence",
        "description": "This coin was issued specifically as Maundy money as part of the Royal Maundy ceremony. The King would have handed these out personally to the elderly at a Church in London at the Maundy service.",
        "frontImg": "17623pf.jpg",
        "frontTitle": "GEORGIVS·III·DEI·GRATIA·",
        "frontDescription": "Young laureate and draped bust of King George III right legend around.",
        "backImg": "17623pb.jpg",
        "backTitle": "Maundy",
        "backDescription": "Central denomination below crown dividing date legend around.",
        "bottomContent": "King George III",
        "price": "$500.00",
        "ebay": ""
      },
      {
        "id": 306,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "maundy",
        "typeid": 11,
        "name": "King George III",
        "monarchtype": "George3",
        "year": 1776,
        "tagline": "maundy",
        "title": "1776 1 Pence",
        "description": "This coin was issued specifically as Maundy money as part of the Royal Maundy ceremony. The King would have handed these out personally to the elderly at a Church in London at the Maundy service.",
        "frontImg": "17761pf.jpg",
        "frontTitle": "GEORGIVS·III·DEI·GRATIA·",
        "frontDescription": "Young laureate and draped bust of King George III right legend around.",
        "backImg": "17761pb.jpg",
        "backTitle": "Maundy",
        "backDescription": "Central denomination below crown dividing date legend around.",
        "bottomContent": "King George III",
        "price": "$500.00",
        "ebay": ""
      },
      {
        "id": 307,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "maundy",
        "typeid": 11,
        "name": "King George III",
        "monarchtype": "George3",
        "year": 1776,
        "tagline": "maundy",
        "title": "1776 2 Pence",
        "description": "This coin was issued specifically as Maundy money as part of the Royal Maundy ceremony. The King would have handed these out personally to the elderly at a Church in London at the Maundy service.",
        "frontImg": "17762pf.jpg",
        "frontTitle": "GEORGIVS·III·DEI·GRATIA·",
        "frontDescription": "Young laureate and draped bust of King George III right legend around.",
        "backImg": "17762pb.jpg",
        "backTitle": "Maundy",
        "backDescription": "Central denomination below crown dividing date legend around.",
        "bottomContent": "King George III",
        "price": "$500.00",
        "ebay": ""
      },
      {
        "id": 308,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "maundy",
        "typeid": 11,
        "name": "King George III",
        "monarchtype": "George3",
        "year": 1795,
        "tagline": "maundy",
        "title": "1795 3 Pence",
        "description": "This coin was issued specifically as Maundy money as part of the Royal Maundy ceremony. The King would have handed these out personally to the elderly at a Church in London at the Maundy service.",
        "frontImg": "17953pf.jpg",
        "frontTitle": "GEORGIVS·III·DEI·GRATIA·",
        "frontDescription": "Young laureate and draped bust of King George III right legend around.",
        "backImg": "17953pb.jpg",
        "backTitle": "Maundy",
        "backDescription": "Central denomination below crown dividing date legend around.",
        "bottomContent": "King George III",
        "price": "$500.00",
        "ebay": ""
      },
      {
        "id": 309,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "maundy",
        "typeid": 11,
        "name": "King George III",
        "monarchtype": "George3",
        "year": 1800,
        "tagline": "maundy",
        "title": "1800 2 Pence",
        "description": "This coin was issued specifically as Maundy money as part of the Royal Maundy ceremony. The King would have handed these out personally to the elderly at a Church in London at the Maundy service.",
        "frontImg": "18001p2f.jpg",
        "frontTitle": "GEORGIVS·III·DEI·GRATIA·",
        "frontDescription": "Young laureate and draped bust of King George III right legend around.",
        "backImg": "18001p2b.jpg",
        "backTitle": "Maundy",
        "backDescription": "Central denomination below crown dividing date legend around.",
        "bottomContent": "King George III",
        "price": "$500.00",
        "ebay": ""
      },
      {
        "id": 310,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "maundy",
        "typeid": 11,
        "name": "King George III",
        "monarchtype": "George3",
        "year": 1800,
        "tagline": "maundy",
        "title": "1800 1 Pence",
        "description": "This coin was issued specifically as Maundy money as part of the Royal Maundy ceremony. The King would have handed these out personally to the elderly at a Church in London at the Maundy service.",
        "frontImg": "18001pf.jpg",
        "frontTitle": "GEORGIVS·III·DEI·GRATIA·",
        "frontDescription": "Young laureate and draped bust of King George III right legend around.",
        "backImg": "18001pb.jpg",
        "backTitle": "Maundy",
        "backDescription": "Central denomination below crown dividing date legend around.",
        "bottomContent": "King George III",
        "price": "$500.00",
        "ebay": ""
      },
      {
        "id": 311,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "maundy",
        "typeid": 11,
        "name": "King George III",
        "monarchtype": "George3",
        "year": 1800,
        "tagline": "maundy",
        "title": "1800 2 Pence",
        "description": "This coin was issued specifically as Maundy money as part of the Royal Maundy ceremony. The King would have handed these out personally to the elderly at a Church in London at the Maundy service.",
        "frontImg": "18002pf.jpg",
        "frontTitle": "GEORGIVS·III·DEI·GRATIA·",
        "frontDescription": "Young laureate and draped bust of King George III right legend around.",
        "backImg": "18002pb.jpg",
        "backTitle": "Maundy",
        "backDescription": "Central denomination below crown dividing date legend around.",
        "bottomContent": "King George III",
        "price": "$500.00",
        "ebay": ""
      },
      {
        "id": 312,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "maundy",
        "typeid": 11,
        "name": "King George III",
        "monarchtype": "George3",
        "year": 1817,
        "tagline": "maundy",
        "title": "1817 1 Pence",
        "description": "This coin was issued specifically as Maundy money as part of the Royal Maundy ceremony. The King would have handed these out personally to the elderly at a Church in London at the Maundy service.",
        "frontImg": "18171pf.jpg",
        "frontTitle": "GEORGIVS·III·DEI·GRATIA·",
        "frontDescription": "Young laureate and draped bust of King George III right legend around.",
        "backImg": "18171pb.jpg",
        "backTitle": "Maundy",
        "backDescription": "Central denomination below crown dividing date legend around.",
        "bottomContent": "King George III",
        "price": "$500.00",
        "ebay": ""
      },
      {
        "id": 313,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "maundy",
        "typeid": 11,
        "name": "King George IIII",
        "monarchtype": "George4",
        "year": 1827,
        "tagline": "maundy",
        "title": "1827 2 Pence",
        "description": "This coin was issued specifically as Maundy money as part of the Royal Maundy ceremony. The King would have handed these out personally to the elderly at a Church in London at the Maundy service.",
        "frontImg": "18272pf.jpg",
        "frontTitle": "GEORGIUS IIII D.G.",
        "frontDescription": "Laureate bust of King George IV left legend around.",
        "backImg": "18272pb.jpg",
        "backTitle": "Maundy",
        "backDescription": "Crowned denomination dividing date within oak wreath.",
        "bottomContent": "King George IIII",
        "price": "$500.00",
        "ebay": ""
      },
      {
        "id": 314,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "maundy",
        "typeid": 11,
        "name": "King William IV",
        "monarchtype": "William4",
        "year": 1831,
        "tagline": "maundy",
        "title": "1831 4 Pence",
        "description": "This coin was issued specifically as Maundy money as part of the Royal Maundy ceremony. The King would have handed these out personally to the elderly at a Church in London at the Maundy service.",
        "frontImg": "18314pf.jpg",
        "frontTitle": "GULIELMUS IIII",
        "frontDescription": "Uncrowned portrait of King William IV right legend around.",
        "backImg": "18314pb.jpg",
        "backTitle": "Maundy",
        "backDescription": "Crowned denomination dividing date within oak wreath.",
        "bottomContent": "King William IV",
        "price": "$100.00",
        "ebay": ""
      },
      {
        "id": 315,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "maundy",
        "typeid": 11,
        "name": "King William IV",
        "monarchtype": "William4",
        "year": 1833,
        "tagline": "maundy",
        "title": "1833 1 Pence",
        "description": "This coin was issued specifically as Maundy money as part of the Royal Maundy ceremony. The King would have handed these out personally to the elderly at a Church in London at the Maundy service.",
        "frontImg": "18331pf.jpg",
        "frontTitle": "GULIELMUS IIII",
        "frontDescription": "Uncrowned portrait of King William IV facing right legend around.",
        "backImg": "18331pb.jpg",
        "backTitle": "Maundy",
        "backDescription": "Crowned denomination dividing date within oak wreath.",
        "bottomContent": "King William IV",
        "price": "$500.00",
        "ebay": ""
      },
      {
        "id": 316,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "maundy",
        "typeid": 11,
        "name": "King William IV",
        "monarchtype": "William4",
        "year": 1836,
        "tagline": "maundy",
        "title": "1836 2 Pence",
        "description": "This coin was issued specifically as Maundy money as part of the Royal Maundy ceremony. The King would have handed these out personally to the elderly at a Church in London at the Maundy service.",
        "frontImg": "18362pf.jpg",
        "frontTitle": "GULIELMUS IIII",
        "frontDescription": "Uncrowned portrait of King William IV facing right legend around.",
        "backImg": "18362pb.jpg",
        "backTitle": "Maundy",
        "backDescription": "Crowned denomination dividing date within oak wreath.",
        "bottomContent": "King William IV",
        "price": "$500.00",
        "ebay": ""
      },
      {
        "id": 317,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "maundy",
        "typeid": 11,
        "name": "Queen Victoria",
        "monarchtype": "Victoria",
        "year": 1850,
        "tagline": "maundy",
        "title": "1850 1 Pence",
        "description": "This coin was issued specifically as Maundy money as part of the Royal Maundy ceremony. The King would have handed these out personally to the elderly at a Church in London at the Maundy service.",
        "frontImg": "18501pf.jpg",
        "frontTitle": "VICTORIA D:G:",
        "frontDescription": "Uncrowned portrait ('Young Head') of Queen Victoria left legend around.",
        "backImg": "18501pb.jpg",
        "backTitle": "Maundy",
        "backDescription": "Crowned denomination divides date within oak wreath.",
        "bottomContent": "Queen Victoria",
        "price": "$500.00",
        "ebay": ""
      },
      {
        "id": 318,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "maundy",
        "typeid": 11,
        "name": "King Edward VII",
        "monarchtype": "Edward7",
        "year": 1902,
        "tagline": "maundy",
        "title": "1902 3 Pence",
        "description": "This coin was issued specifically as Maundy money as part of the Royal Maundy ceremony. The King would have handed these out personally to the elderly at a Church in London at the Maundy service.",
        "frontImg": "19023pf.jpg",
        "frontTitle": "EDWARDVS VII",
        "frontDescription": "Uncrowned portrait of King Edward VII right legend around.",
        "backImg": "19023pb.jpg",
        "backTitle": "Maundy",
        "backDescription": "Edward the Seventh by the Grace of God King of all the Britains Defender of the Faith Emperor of India",
        "bottomContent": "King Edward VII",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 319,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "maundy",
        "typeid": 11,
        "name": "King George V",
        "monarchtype": "George5",
        "year": 1919,
        "tagline": "maundy",
        "title": "1919 3 Pence",
        "description": "This coin was issued specifically as Maundy money as part of the Royal Maundy ceremony. The King would have handed these out personally to the elderly at a Church in London at the Maundy service.",
        "frontImg": "19193pf.jpg",
        "frontTitle": "GEORGIVS V",
        "frontDescription": "Uncrowned portrait of King George V left legend around.",
        "backImg": "19193pb.jpg",
        "backTitle": "Maundy",
        "backDescription": "Crowned denomination dividing date within oak wreath.",
        "bottomContent": "King George V",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 320,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "penny",
        "typeid": 11,
        "name": "King Edward VIII",
        "monarchtype": "Edward8",
        "year": 1936,
        "tagline": "maundy",
        "title": "1936 West Africa One Penny",
        "description": "West Africa One Penny",
        "frontImg": "19361pf.jpg",
        "frontTitle": "EDWARDVS VIII",
        "frontDescription": "Crown above center hole denomination around hole in English in Arabic beneath",
        "backImg": "19361pb.jpg",
        "backTitle": "Penny",
        "backDescription": "Hexagram date beneath",
        "bottomContent": "King Edward VIII",
        "price": "$40.00",
        "ebay": ""
      },
      {
        "id": 321,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "crowns",
        "typeid": 1,
        "name": "King Charles III",
        "monarchtype": "Charles3",
        "year": 2023,
        "tagline": "Crown",
        "title": "King Charles III",
        "description": "Charles III is King of the United Kingdom and the 14 other Commonwealth realms. Charles was born in Buckingham Palace during the reign of his maternal grandfather King George VI and became heir apparent when his mother Queen Elizabeth II acceded to the throne in 1952.",
        "frontImg": "20232f.jpg",
        "frontTitle": "Charles III",
        "frontDescription": "Struck to celebrate the coronation of His Majesty The King.",
        "backImg": "20232b.jpg",
        "backTitle": "Coronation",
        "backDescription": "The Royal Arms",
        "bottomContent": "The Royal Arms",
        "price": "$50.00",
        "ebay": ""
      },
      {
        "id": 322,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "uktokens",
        "typeid": 12,
        "name": "Wilkinson",
        "monarchtype": "Wilkinson",
        "year": 1787,
        "tagline": "uktokens",
        "title": "1787 Halfpenny",
        "description": "Wilkinsons Iron Master & Furnaces Halfpenny 1787",
        "frontImg": "1787irf.jpg",
        "frontTitle": "IRON MASTER",
        "frontDescription": "Bust of John Wilkinson to right with legend",
        "backImg": "1787irb.jpg",
        "backTitle": "Halfpenny",
        "backDescription": "The interior of a smithy showing a large drop hammer and a workman holding a piece of metal on an anvil in front of an open furnace with the date",
        "bottomContent": "Halfpenny",
        "price": "$20.00",
        "ebay": ""
      },
      {
        "id": 323,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "uktokens",
        "typeid": 12,
        "name": "Anglesey",
        "monarchtype": "Anglesey",
        "year": 1788,
        "tagline": "uktokens",
        "title": "1788 anglesey mines halfpenny",
        "description": "Parys Mines Company / Druid Series",
        "frontImg": "1788hpf.jpg",
        "frontTitle": "Anglesey",
        "frontDescription": "Druid head left within wreath",
        "backImg": "1788hpb.jpg",
        "backTitle": "Handsworth",
        "backDescription": "Large central monogram; date above lettering around",
        "bottomContent": "Halfpenny",
        "price": "$25.00",
        "ebay": ""
      },
      {
        "id": 324,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "uktokens",
        "typeid": 12,
        "name": "Lancashire",
        "monarchtype": "Lancashire",
        "year": 1791,
        "tagline": "uktokens",
        "title": "1791 Lancashire",
        "description": "Lancaster / John of Gaunt",
        "frontImg": "1791hpf.jpg",
        "frontTitle": "Lancashire",
        "frontDescription": "Bust of John of Gaunt in robes and ducal coronet left surrounded by legend with star below. Toothed border",
        "backImg": "1791hpb.jpg",
        "backTitle": "Copper",
        "backDescription": "Shield of arms of the Borough of Lancaster (gules a lion passant guardant or on a chief azure a fleur-de-lys of the second). Surrounded by legend  date below. Toothed border",
        "bottomContent": "Halfpenny",
        "price": "$70.00",
        "ebay": ""
      },
      {
        "id": 325,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "uktokens",
        "typeid": 12,
        "name": "Kent",
        "monarchtype": "Kent",
        "year": 1794,
        "tagline": "uktokens",
        "title": "1794 Kent",
        "description": "Feversham / J. Crow",
        "frontImg": "1794hpf.jpg",
        "frontTitle": "Kent",
        "frontDescription": "A ancient sloop sailor left blowing a horn one on the right two busy furling the sail. The design is based on the seal of the Barons of the Cinque Ports.",
        "backImg": "1794hpb.jpg",
        "backTitle": "Conder tokens",
        "backDescription": "Arms combining the 3 Plantagenet lions and the 3 ships of the Cinque Ports.",
        "bottomContent": "Halfpenny",
        "price": "$70.00",
        "ebay": ""
      },
      {
        "id": 326,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "uktokens",
        "typeid": 12,
        "name": "King George III",
        "monarchtype": "George3",
        "year": 1797,
        "tagline": "uktokens",
        "title": "1797 Cartwheel",
        "description": "1 Penny - George III 2nd Issue artwheel",
        "frontImg": "1797cartf.jpg",
        "frontTitle": "Pound sterling",
        "frontDescription": "Laureate and draped bust of King George III right legend around on raised rim.",
        "backImg": "1797cartb.jpg",
        "backTitle": "Cartwheel",
        "backDescription": "Seated figure of Britannia left  with trident and shield in left hand  olive branch in raised right hand  shield bearing Union flag resting on left  sea behind with ship on left  mint name below shield  legend above and date below on raised rim.",
        "bottomContent": "penny",
        "price": "$150.00",
        "ebay": ""
      },
      {
        "id": 327,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "uktokens",
        "typeid": 12,
        "name": "Queen Victoria",
        "monarchtype": "Victoria",
        "year": 1859,
        "tagline": "uktokens",
        "title": "1859 1 Penny Rocke G. and W. H. Melbourne Victoria",
        "description": "Australian States",
        "frontImg": "1859engf.jpg",
        "frontTitle": "Australian States",
        "frontDescription": "Lion centre text around",
        "backImg": "1859engb.jpg",
        "backTitle": "Copper",
        "backDescription": "Seated lady holding scales Boat botton left.",
        "bottomContent": "Penny",
        "price": "$20.00",
        "ebay": ""
      },
      {
        "id": 328,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "uktokens",
        "typeid": 12,
        "name": "Queen Victoria",
        "monarchtype": "Victoria",
        "year": 1897,
        "tagline": "uktokens",
        "title": "1897 diamond jubilee medal",
        "description": "1897 diamond jubilee medal",
        "frontImg": "1897djf.jpg",
        "frontTitle": "",
        "frontDescription": "The busts of 4 British monarchs surrounded by legend",
        "backImg": "1897djb.jpg",
        "backTitle": "",
        "backDescription": "Four shields surrounded by legend",
        "bottomContent": "Souvenir medals",
        "price": "$50.00",
        "ebay": ""
      },
      {
        "id": 329,
        "country": "United States",
        "countryname": "unitedstates",
        "type": "ustokens",
        "typeid": 13,
        "name": "Texas",
        "monarchtype": "Texas",
        "year": 1845,
        "tagline": "ustokens",
        "title": "1845 Texas Penny",
        "description": "1845 PENNY TEXAS COMMEMORATIVE TOKEN THE ALAMO",
        "frontImg": "1845tpf.jpg",
        "frontTitle": "",
        "frontDescription": "Copper Commemorative",
        "backImg": "1845tpb.jpg",
        "backTitle": "Lone Star Mint",
        "backDescription": "",
        "bottomContent": "THE ALAMO",
        "price": "$20.00",
        "ebay": ""
      },
      {
        "id": 330,
        "country": "United States",
        "countryname": "unitedstates",
        "type": "ustokens",
        "typeid": 13,
        "name": "France",
        "monarchtype": "France",
        "year": 1931,
        "tagline": "ustokens",
        "title": "1931 Exposition Coloniale Internationale",
        "description": "1931 Paris International Colonial Exhibition",
        "frontImg": "1931amf.jpg",
        "frontTitle": "",
        "frontDescription": "An Cherokee Chief Head Facing Left",
        "backImg": "1931amb.jpg",
        "backTitle": "",
        "backDescription": "The United States Pavilion and the reproduction of George Washington's house in Mount Vernon",
        "bottomContent": "Token",
        "price": "$120.00",
        "ebay": ""
      },
      {
        "id": 331,
        "country": "Germany",
        "countryname": "germany",
        "type": "germany",
        "typeid": 23,
        "name": "German Democratic Republic",
        "monarchtype": "germany",
        "year": 1952,
        "tagline": "germany",
        "title": "1952 1 Pfennig",
        "description": "1952 1 Pfennig Mark  German Democratic Republic",
        "frontImg": "19521pf.jpg",
        "frontTitle": "Pfennig",
        "frontDescription": "Hammer and compass on two wheat stalks symbolizing the alliance of the working class with peasants and intelligent workers. The date below.",
        "backImg": "19521pb.jpg",
        "backTitle": "DEUTSCHLAND",
        "backDescription": "The face value under DEUTSCHLAND and the mintmark under PFENNIG.",
        "bottomContent": "germany",
        "price": "$20.00",
        "ebay": ""
      },
      {
        "id": 332,
        "country": "Germany",
        "countryname": "germany",
        "type": "germany",
        "typeid": 23,
        "name": "German Democratic Republic",
        "monarchtype": "germany",
        "year": 1948,
        "tagline": "germany",
        "title": "1948 5 Pfennig",
        "description": "1948 5 Pfennig Mark  German Democratic Republic",
        "frontImg": "19485pf.jpg",
        "frontTitle": "Pfennig",
        "frontDescription": "An ear of wheat up front and a gear wheel in the back symbolizing the alliance between the industrial worker and farmer in accordance with the emblem of the Two-Year Plan.",
        "backImg": "19485pb.jpg",
        "backTitle": "DEUTSCHLAND",
        "backDescription": "The face value under DEUTSCHLAND and the mintmark under PFENNIG.",
        "bottomContent": "germany",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 333,
        "country": "Germany",
        "countryname": "germany",
        "type": "germany",
        "typeid": 23,
        "name": "German Democratic Republic",
        "monarchtype": "germany",
        "year": 1956,
        "tagline": "germany",
        "title": "1956 deutsche Mark",
        "description": "1956 deutsche Mark  German Democratic Republic",
        "frontImg": "19561pf.jpg",
        "frontTitle": "Pfennig",
        "frontDescription": "State emblem (hammer and a compass surrounded by wreath of ears with a ribbon displaying the flag colors: black red and gold).",
        "backImg": "19561pb.jpg",
        "backTitle": "DEUTSCHLAND",
        "backDescription": "Value flanked by oak leaves mintmark above.",
        "bottomContent": "germany",
        "price": "$5.00",
        "ebay": ""
      },
      {
        "id": 334,
        "country": "Germany",
        "countryname": "germany",
        "type": "germany",
        "typeid": 23,
        "name": "German Democratic Republic",
        "monarchtype": "germany",
        "year": 1950,
        "tagline": "germany",
        "title": "1950 deutsche Mark",
        "description": "1950 deutsche Mark  German Democratic Republic",
        "frontImg": "19501mf.jpg",
        "frontTitle": "Pfennig",
        "frontDescription": "Eagle",
        "backImg": "19501mb.jpg",
        "backTitle": "DEUTSCHLAND",
        "backDescription": "Denomination flanked by oak leaves date below",
        "bottomContent": "germany",
        "price": "$5.00",
        "ebay": ""
      },
      {
        "id": 335,
        "country": "Germany",
        "countryname": "germany",
        "type": "germany",
        "typeid": 23,
        "name": "German Democratic Republic",
        "monarchtype": "germany",
        "year": 1949,
        "tagline": "germany",
        "title": "1949 50 pfennig",
        "description": "1949 50 pfennig  German Democratic Republic",
        "frontImg": "19491pf.jpg",
        "frontTitle": "Pfennig",
        "frontDescription": "A woman planting an oak symbol of Germanys rebirth after the war",
        "backImg": "19491pb.jpg",
        "backTitle": "DEUTSCHLAND",
        "backDescription": "The mintmark below the denomination",
        "bottomContent": "germany",
        "price": "$50.00",
        "ebay": ""
      },
      {
        "id": 336,
        "country": "Germany",
        "countryname": "germany",
        "type": "germany",
        "typeid": 23,
        "name": "German Democratic Republic",
        "monarchtype": "germany",
        "year": 1950,
        "tagline": "germany",
        "title": "1950 10 pfennig",
        "description": "1950 10 pfennig  German Democratic Republic",
        "frontImg": "195010pf.jpg",
        "frontTitle": "Pfennig",
        "frontDescription": "An oak seedling above the date",
        "backImg": "195010pb.jpg",
        "backTitle": "DEUTSCHLAND",
        "backDescription": "The mintmark in between two rye ears above the denomination",
        "bottomContent": "germany",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 337,
        "country": "Germany",
        "countryname": "germany",
        "type": "germany",
        "typeid": 23,
        "name": "German Democratic Republic",
        "monarchtype": "germany",
        "year": 1950,
        "tagline": "germany",
        "title": "1950 5 pfennig",
        "description": "1950 5 pfennig  German Democratic Republic",
        "frontImg": "19505pf.jpg",
        "frontTitle": "Pfennig",
        "frontDescription": "An oak seedling above the date",
        "backImg": "19505pb.jpg",
        "backTitle": "DEUTSCHLAND",
        "backDescription": "The mintmark in between two rye ears above the denomination",
        "bottomContent": "germany",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 338,
        "country": "Germany",
        "countryname": "germany",
        "type": "germany",
        "typeid": 23,
        "name": "German Democratic Republic",
        "monarchtype": "germany",
        "year": 1950,
        "tagline": "germany",
        "title": "1950 2 pfennig",
        "description": "1950 2 pfennig  German Democratic Republic",
        "frontImg": "19502pf.jpg",
        "frontTitle": "Pfennig",
        "frontDescription": "An oak seedling above the date",
        "backImg": "19502pb.jpg",
        "backTitle": "DEUTSCHLAND",
        "backDescription": "The mintmark in between two rye ears above the denomination",
        "bottomContent": "germany",
        "price": "$5.00",
        "ebay": ""
      },
      {
        "id": 339,
        "country": "Germany",
        "countryname": "germany",
        "type": "germany",
        "typeid": 23,
        "name": "German Democratic Republic",
        "monarchtype": "germany",
        "year": 1950,
        "tagline": "germany",
        "title": "1950 1 pfennig",
        "description": "1950 1 pfennig  German Democratic Republic",
        "frontImg": "19501pf.jpg",
        "frontTitle": "Pfennig",
        "frontDescription": "An oak seedling above the date",
        "backImg": "19501pb.jpg",
        "backTitle": "DEUTSCHLAND",
        "backDescription": "The mintmark in between two rye ears above the denomination",
        "bottomContent": "germany",
        "price": "$5.00",
        "ebay": ""
      },
      {
        "id": 340,
        "country": "Germany",
        "countryname": "germany",
        "type": "germany",
        "typeid": 23,
        "name": "German Third Reich",
        "monarchtype": "germany",
        "year": 1939,
        "tagline": "germany",
        "title": "1939 2 Reichsmark",
        "description": "1939 2 Reichsmark",
        "frontImg": "19392rmf.jpg",
        "frontTitle": "Mark",
        "frontDescription": "An eagle holding a wreath of oak leaves above the swastika in the middle of the wreath denomination on the sides.",
        "backImg": "19392rmb.jpg",
        "backTitle": "DEUTSCHLAND",
        "backDescription": "A large portrait of Paul von Hindenburg facing right",
        "bottomContent": "germany",
        "price": "$70.00",
        "ebay": ""
      },
      {
        "id": 341,
        "country": "Germany",
        "countryname": "germany",
        "type": "germany",
        "typeid": 23,
        "name": "German Third Reich",
        "monarchtype": "germany",
        "year": 1943,
        "tagline": "germany",
        "title": "1943 50 Reichspfennig",
        "description": "1939 50 Reichspfennig",
        "frontImg": "194350pf.jpg",
        "frontTitle": "Reichspfennig",
        "frontDescription": "An eagle on top of a swastika",
        "backImg": "194350pb.jpg",
        "backTitle": "DEUTSCHLAND",
        "backDescription": "The face value and two oak leaves and the mintmark below between the leaves",
        "bottomContent": "germany",
        "price": "$50.00",
        "ebay": ""
      },
      {
        "id": 342,
        "country": "Germany",
        "countryname": "germany",
        "type": "germany",
        "typeid": 23,
        "name": "German Third Reich",
        "monarchtype": "germany",
        "year": 1941,
        "tagline": "germany",
        "title": "1941 10 Reichspfennig",
        "description": "1941 10 Reichspfennig",
        "frontImg": "194110rpf.jpg",
        "frontTitle": "Reichspfennig",
        "frontDescription": "An eagle on top of a swastika",
        "backImg": "194110rpb.jpg",
        "backTitle": "DEUTSCHLAND",
        "backDescription": "The face value and two oak leaves and the mintmark below between the leaves",
        "bottomContent": "germany",
        "price": "$5.00",
        "ebay": ""
      },
      {
        "id": 343,
        "country": "Germany",
        "countryname": "germany",
        "type": "germany",
        "typeid": 23,
        "name": "German Third Reich",
        "monarchtype": "germany",
        "year": 1941,
        "tagline": "germany",
        "title": "1941 5 Reichspfennig",
        "description": "1941 5 Reichspfennig",
        "frontImg": "19415rpf.jpg",
        "frontTitle": "Reichspfennig",
        "frontDescription": "An eagle on top of a swastika",
        "backImg": "19415rpb.jpg",
        "backTitle": "DEUTSCHLAND",
        "backDescription": "The face value and two oak leaves and the mintmark below between the leaves",
        "bottomContent": "germany",
        "price": "$5.00",
        "ebay": ""
      },
      {
        "id": 344,
        "country": "Germany",
        "countryname": "germany",
        "type": "germany",
        "typeid": 23,
        "name": "German Third Reich",
        "monarchtype": "germany",
        "year": 1941,
        "tagline": "germany",
        "title": "1941 1 Reichspfennig",
        "description": "1941 1 Reichspfennig",
        "frontImg": "19411rpf.jpg",
        "frontTitle": "Reichspfennig",
        "frontDescription": "An eagle on top of a swastika",
        "backImg": "19411rpb.jpg",
        "backTitle": "DEUTSCHLAND",
        "backDescription": "The face value and two oak leaves and the mintmark below between the leaves",
        "bottomContent": "germany",
        "price": "$5.00",
        "ebay": ""
      },
      {
        "id": 345,
        "country": "Germany",
        "countryname": "germany",
        "type": "germany",
        "typeid": 23,
        "name": "German Third Reich",
        "monarchtype": "germany",
        "year": 1925,
        "tagline": "germany",
        "title": "1925 1 Reichsmark",
        "description": "1925 1 Reichsmark",
        "frontImg": "19251rmf.jpg",
        "frontTitle": "Reichspfennig",
        "frontDescription": "Eagle above date",
        "backImg": "19251rmb.jpg",
        "backTitle": "DEUTSCHLAND",
        "backDescription": "Denomination within wreath",
        "bottomContent": "germany",
        "price": "$100.00",
        "ebay": ""
      },
      {
        "id": 346,
        "country": "Germany",
        "countryname": "germany",
        "type": "germany",
        "typeid": 23,
        "name": "German Weimar Republic",
        "monarchtype": "germany",
        "year": 1921,
        "tagline": "germany",
        "title": "1921 50 Pfennig",
        "description": "1921 50 Pfennig Weimar Republic This aluminum coin from the times of Weimar Republic struck at the Berlin Mint.",
        "frontImg": "192150pf.jpg",
        "frontTitle": "Pfennig",
        "frontDescription": "Eagle above date",
        "backImg": "192150pb.jpg",
        "backTitle": "DEUTSCHLAND",
        "backDescription": "Denomination within wreath",
        "bottomContent": "germany",
        "price": "$5.00",
        "ebay": ""
      },
      {
        "id": 347,
        "country": "Germany",
        "countryname": "germany",
        "type": "germany",
        "typeid": 23,
        "name": "German Third Reich",
        "monarchtype": "germany",
        "year": 1928,
        "tagline": "germany",
        "title": "1928 50 Reichspfennig",
        "description": "1928 50 Reichspfennig",
        "frontImg": "192850rpf.jpg",
        "frontTitle": "Reichspfennig",
        "frontDescription": "An Imperial eagle and oak leaves.",
        "backImg": "192850rpb.jpg",
        "backTitle": "DEUTSCHLAND",
        "backDescription": "The face value surrounded with oak and leaves and acorns. Mintmark below 50.",
        "bottomContent": "germany",
        "price": "$20.00",
        "ebay": ""
      },
      {
        "id": 348,
        "country": "Germany",
        "countryname": "germany",
        "type": "germany",
        "typeid": 23,
        "name": "German Third Reich",
        "monarchtype": "germany",
        "year": 1935,
        "tagline": "germany",
        "title": "1935 10 Reichspfennig",
        "description": "1935 10 Reichspfennig Aluminium bronze",
        "frontImg": "193510rpf.jpg",
        "frontTitle": "GERMAN EMPIRE",
        "frontDescription": "Face value in a square with 4 oak leaves",
        "backImg": "193510rpb.jpg",
        "backTitle": "DEUTSCHLAND",
        "backDescription": "Wheat ears drawing a pyramid",
        "bottomContent": "germany",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 349,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "uktokens",
        "typeid": 12,
        "name": "Queen Victoria",
        "monarchtype": "Victoria",
        "year": 1843,
        "tagline": "uktokens",
        "title": "1843 new brunswick half penny token",
        "description": "1843 new brunswick half penny token Canadian provinces",
        "frontImg": "1843hpf.jpg",
        "frontTitle": "New Brunswick",
        "frontDescription": "Left profile of Victoria.",
        "backImg": "1843hpb.jpg",
        "backTitle": "Copper",
        "backDescription": "H.M.S New Brunswick sailing left.",
        "bottomContent": "Halfpenny",
        "price": "$25.00",
        "ebay": ""
      },
      {
        "id": 350,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "ukhammered",
        "typeid": 14,
        "name": "King Henry VIII",
        "monarchtype": "henry8",
        "year": "1525\u001a1544",
        "tagline": "ukhammered",
        "title": "1525\u001a1544",
        "description": "Henry VIII AR groat. Second coinage House of Tudor Henry VIII (1509-47) Second coinage (1526-44) Silver half groat London mint.",
        "frontImg": "1554gf.jpg",
        "frontTitle": "Groat",
        "frontDescription": "Crowned and draped bust of King Henry VIII facing right legend around.",
        "backImg": "1554gb.jpg",
        "backTitle": "House of Tudor",
        "backDescription": "Quartered shield of arms of England and France over long cross fourchée saltire in each fork legend around.",
        "bottomContent": "Groat",
        "price": "$350.00",
        "ebay": ""
      },
      {
        "id": 351,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "ukhammered",
        "typeid": 14,
        "name": "King Henry III",
        "monarchtype": "henry3",
        "year": "1216-1272",
        "tagline": "ukhammered",
        "title": "1216-1272 Long Cross",
        "description": "Henry III (h\u001aNRICVS R\u001aX T\u001aRCI) Long Cross type",
        "frontImg": "1272pf.jpg",
        "frontTitle": "H\u001aNRICVS R\u001aX T\u001aRCI",
        "frontDescription": "Legend around crowned facing portrait without sceptre.",
        "backImg": "1272pb.jpg",
        "backTitle": "Long Cross",
        "backDescription": "King Henry the Third",
        "bottomContent": "Penny",
        "price": "$50.00",
        "ebay": ""
      },
      {
        "id": 352,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "uktokens",
        "typeid": 12,
        "name": "King George III",
        "monarchtype": "George3",
        "year": 1793,
        "tagline": "uktokens",
        "title": "1793 ½ Penny",
        "description": "1793 ½ Penny Lancashire - Manchester / I Fielding",
        "frontImg": "1793hpf.jpg",
        "frontTitle": "Griffin",
        "frontDescription": "Arms of the Worshipful Company of Grocers legend around date below.",
        "backImg": "1793hpb.jpg",
        "backTitle": "Conder tokens",
        "backDescription": "East India Company's bale mark legend around.",
        "bottomContent": "Halfpenny",
        "price": "$50.00",
        "ebay": ""
      },
      {
        "id": 353,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "uktokens",
        "typeid": 12,
        "name": "Queen Victoria",
        "monarchtype": "Victoria",
        "year": 1837,
        "tagline": "uktokens",
        "title": "1837 Cumberland Jack Token - Victoria To Hanover",
        "description": "The tokens & counters that bear the words To Hanover (commonly known as Cumberland Jacks) are usually found with Victoria's portrait on the obverse and a figure riding a horse on the reverse (in the style of St George slaying the dragon). They were produced as satirical counters.The history behind these pieces is that when Victoria was crowned as Queen she was unable to fulfil the role of King of Hanover under Salic Law due to the fact that she was a female. Her unpopular Uncle (Ernest Augustus the Duke of Cumberland) who was the oldest male heir to the throne was sent off to Hanover instead to fulfil the role and the counters/tokens were produced to commemorate (celebrate) his departure. Such was his unpopularity that many of the tokens on closer inspection actually depict a monkey on horseback which was intended to portray the Duke.They were produced around the time of Victorias ascension to the throne in 1837 and continued to be produced for the next few decades. They were finally made illegal in 1883.",
        "frontImg": "1837tf.jpg",
        "frontTitle": "Copper",
        "frontDescription": "Portrait left",
        "backImg": "1837tb.jpg",
        "backTitle": "Conder tokens",
        "backDescription": "TO HANOVER Small date.",
        "bottomContent": "The token",
        "price": "$50.00",
        "ebay": ""
      },
      {
        "id": 354,
        "country": "India",
        "countryname": "india",
        "type": "india",
        "typeid": 24,
        "name": "Rupee",
        "monarchtype": "india",
        "year": 1947,
        "tagline": "india",
        "title": "1947 HALF RUPEE Bombay",
        "description": "Eight Annas (= 1/2 rupee) Mint mark dot or diamond below date for Bombay mint",
        "frontImg": "194712rf.jpg",
        "frontTitle": "Rupee (1770-1947)",
        "frontDescription": "Crowned head of King George VI facing left",
        "backImg": "194712rb.jpg",
        "backTitle": "HALF RUPEE",
        "backDescription": "Indian tiger (Panthera tigris) facing left legend and date below. Surrounding this is the value in three languages English Devnagari (Aadha Rupya) and Persian (Hasht Aanay)",
        "bottomContent": "india",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 355,
        "country": "India",
        "countryname": "india",
        "type": "india",
        "typeid": 24,
        "name": "Rupee",
        "monarchtype": "india",
        "year": 1947,
        "tagline": "india",
        "title": "1947 HALF RUPEE No Mint mark",
        "description": "Eight Annas (= 1/2 rupee) No Mint mark Calcutta",
        "frontImg": "194712rnf.jpg",
        "frontTitle": "Rupee (1770-1947)",
        "frontDescription": "Crowned head of King George VI facing left",
        "backImg": "194712rnb.jpg",
        "backTitle": "HALF RUPEE",
        "backDescription": "Indian tiger (Panthera tigris) facing left legend and date below. Surrounding this is the value in three languages English Devnagari (Aadha Rupya) and Persian (Hasht Aanay)",
        "bottomContent": "india",
        "price": "$20.00",
        "ebay": ""
      },
      {
        "id": 356,
        "country": "India",
        "countryname": "india",
        "type": "india",
        "typeid": 24,
        "name": "Rupee",
        "monarchtype": "india",
        "year": 1945,
        "tagline": "india",
        "title": "1945 ¼ Rupee - George VI",
        "description": "¼ Rupee",
        "frontImg": "194514rf.jpg",
        "frontTitle": "George VI",
        "frontDescription": "Crowned head of George VI facing left English legend toothed rim",
        "backImg": "194514rb.jpg",
        "backTitle": "1/4 RUPEE",
        "backDescription": "Date divides legend in English & Urdu (Chaar Aana) within a circle with the outside having a wreath made of roses a thistle shamrock leaves and lotus flowers all within a toothed border",
        "bottomContent": "india",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 357,
        "country": "India",
        "countryname": "india",
        "type": "india",
        "typeid": 24,
        "name": "Rupee",
        "monarchtype": "india",
        "year": 1940,
        "tagline": "india",
        "title": "1940 2 Annas",
        "description": "1940 2 Annas",
        "frontImg": "19402af.jpg",
        "frontTitle": "George VI",
        "frontDescription": "Crowned bust of King George VI left English legend around",
        "backImg": "19402ab.jpg",
        "backTitle": "2 Annas",
        "backDescription": "Denomination and date within floral design. Two annas in 4 languages Urdu Bengali Nagari and Telugu",
        "bottomContent": "india",
        "price": "$30.00",
        "ebay": ""
      },
      {
        "id": 358,
        "country": "India",
        "countryname": "india",
        "type": "india",
        "typeid": 24,
        "name": "Rupee",
        "monarchtype": "india",
        "year": 1939,
        "tagline": "india",
        "title": "1939 1 Annas",
        "description": "1939 1 Annas",
        "frontImg": "19391af.jpg",
        "frontTitle": "George VI",
        "frontDescription": "Crowned bust of King George VI left English legend around",
        "backImg": "19391ab.jpg",
        "backTitle": "1 Annas",
        "backDescription": "Denomination and date within floral design. Value  in 4 languages Urdu Bengali Devanagari and Telugu",
        "bottomContent": "india",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 359,
        "country": "India",
        "countryname": "india",
        "type": "india",
        "typeid": 24,
        "name": "Rupee",
        "monarchtype": "india",
        "year": 1946,
        "tagline": "india",
        "title": "1946 1 Annas",
        "description": "1946 1 Annas",
        "frontImg": "19461af.jpg",
        "frontTitle": "George VI",
        "frontDescription": "Crowned bust of King George VI left English legend around",
        "backImg": "19461ab.jpg",
        "backTitle": "1 Annas",
        "backDescription": "Denomination and date within floral design. Value  in 4 languages Urdu Bengali Devanagari and Telugu",
        "bottomContent": "india",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 360,
        "country": "India",
        "countryname": "india",
        "type": "india",
        "typeid": 24,
        "name": "Rupee",
        "monarchtype": "india",
        "year": 1918,
        "tagline": "india",
        "title": "1918 2 Annas",
        "description": "1918 2 Annas",
        "frontImg": "19182af.jpg",
        "frontTitle": "George V",
        "frontDescription": "Crowned bust of King George V left English legend within circle date outside",
        "backImg": "19182ab.jpg",
        "backTitle": "2 Annas",
        "backDescription": "Denomination. Value  in 4 languages Urdu Bengali Devanagari and Telugu",
        "bottomContent": "india",
        "price": "$30.00",
        "ebay": ""
      },
      {
        "id": 361,
        "country": "India",
        "countryname": "india",
        "type": "india",
        "typeid": 24,
        "name": "Rupee",
        "monarchtype": "india",
        "year": 1936,
        "tagline": "india",
        "title": "1936 1 Annas",
        "description": "1936 1 Annas",
        "frontImg": "19361af.jpg",
        "frontTitle": "George V",
        "frontDescription": "Crowned bust of King George V left English legend within circle date outside",
        "backImg": "19361ab.jpg",
        "backTitle": "1 Annas",
        "backDescription": "Denomination. date below within floral pattern Value  in 4 languages Urdu Bengali Devanagari and Telugu",
        "bottomContent": "india",
        "price": "$50.00",
        "ebay": ""
      },
      {
        "id": 362,
        "country": "India",
        "countryname": "india",
        "type": "india",
        "typeid": 24,
        "name": "Rupee",
        "monarchtype": "india",
        "year": 1936,
        "tagline": "india",
        "title": "1936 1 Annas",
        "description": "1936 1 Annas",
        "frontImg": "193612af.jpg",
        "frontTitle": "George V",
        "frontDescription": "Crowned bust of King George V left English legend within circle date outside",
        "backImg": "193612ab.jpg",
        "backTitle": "1 Annas",
        "backDescription": "Denomination. date below within floral pattern Value  in 4 languages Urdu Bengali Devanagari and Telugu",
        "bottomContent": "india",
        "price": "$50.00",
        "ebay": ""
      },
      {
        "id": 363,
        "country": "India",
        "countryname": "india",
        "type": "india",
        "typeid": 24,
        "name": "Rupee",
        "monarchtype": "india",
        "year": 1954,
        "tagline": "india",
        "title": "1954 1 Pice",
        "description": "1954 1 Pice",
        "frontImg": "19541pf.jpg",
        "frontTitle": "Bronze",
        "frontDescription": "Ashoka Lion Capital",
        "backImg": "19541pb.jpg",
        "backTitle": "1 Pice",
        "backDescription": "Prancing horse facing left",
        "bottomContent": "india",
        "price": "$20.00",
        "ebay": ""
      },
      {
        "id": 364,
        "country": "Germany",
        "countryname": "germany",
        "type": "germany",
        "typeid": 23,
        "name": "German  Weimar Republic",
        "monarchtype": "germany",
        "year": 1923,
        "tagline": "germany",
        "title": "1923 500 Mark",
        "description": "1923 500 Mark",
        "frontImg": "1923500mf.jpg",
        "frontTitle": "GERMAN EMPIRE",
        "frontDescription": "An eagle facing left and the German motto around the rim",
        "backImg": "1923500mb.jpg",
        "backTitle": "Aluminium",
        "backDescription": "The value above the date",
        "bottomContent": "germany",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 365,
        "country": "Germany",
        "countryname": "germany",
        "type": "germany",
        "typeid": 23,
        "name": "German  Weimar Republic",
        "monarchtype": "germany",
        "year": 1923,
        "tagline": "germany",
        "title": "1923 200 Mark",
        "description": "1923 200 Mark A Berlin Germany (1280-date)",
        "frontImg": "1923200mf.jpg",
        "frontTitle": "GERMAN EMPIRE",
        "frontDescription": "An eagle facing left and the German motto around the rim",
        "backImg": "1923200mb.jpg",
        "backTitle": "Aluminium",
        "backDescription": "The value above the date",
        "bottomContent": "germany",
        "price": "$5.00",
        "ebay": ""
      },
      {
        "id": 366,
        "country": "Germany",
        "countryname": "germany",
        "type": "germany",
        "typeid": 23,
        "name": "German  Weimar Republic",
        "monarchtype": "germany",
        "year": 1923,
        "tagline": "germany",
        "title": "1923 200 Mark",
        "description": "1923 200 Mark D Munich Germany (1158-date)",
        "frontImg": "1923200mdf.jpg",
        "frontTitle": "GERMAN EMPIRE",
        "frontDescription": "An eagle facing left and the German motto around the rim",
        "backImg": "1923200mdb.jpg",
        "backTitle": "Aluminium",
        "backDescription": "The value above the date",
        "bottomContent": "germany",
        "price": "$5.00",
        "ebay": ""
      },
      {
        "id": 367,
        "country": "Germany",
        "countryname": "germany",
        "type": "germany",
        "typeid": 23,
        "name": "German  Weimar Republic",
        "monarchtype": "germany",
        "year": 1904,
        "tagline": "germany",
        "title": "1904 1 Mark",
        "description": "1904 1 Mark - Wilhelm II type 2 - small shield",
        "frontImg": "19041mf.jpg",
        "frontTitle": "GERMAN EMPIRE",
        "frontDescription": "Crowned imperial eagle with shield on breast",
        "backImg": "19041mb.jpg",
        "backTitle": "Silver",
        "backDescription": "Denomination within wreath",
        "bottomContent": "germany",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 368,
        "country": "Germany",
        "countryname": "germany",
        "type": "germany",
        "typeid": 23,
        "name": "German  Weimar Republic",
        "monarchtype": "germany",
        "year": 1915,
        "tagline": "germany",
        "title": "1915 1/2 Mark",
        "description": "1915 ½ Mark - Wilhelm II type 2 - small shield",
        "frontImg": "191512mf.jpg",
        "frontTitle": "GERMAN EMPIRE",
        "frontDescription": "An Imperial eagle and the mintmark at the bottom of the bow",
        "backImg": "191512mb.jpg",
        "backTitle": "Silver",
        "backDescription": "Denomination within wreath",
        "bottomContent": "germany",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 369,
        "country": "Germany",
        "countryname": "germany",
        "type": "germany",
        "typeid": 23,
        "name": "German  Weimar Republic",
        "monarchtype": "germany",
        "year": 1903,
        "tagline": "germany",
        "title": "1903 1 Pfennig",
        "description": "1903 1 Pfennig - Wilhelm II type 2 - small shield",
        "frontImg": "19031pf.jpg",
        "frontTitle": "GERMAN EMPIRE",
        "frontDescription": "Coat of Arms Imperial eagle Type 2 with small shield.",
        "backImg": "19031pb.jpg",
        "backTitle": "Pfennig",
        "backDescription": "The value in the centre",
        "bottomContent": "germany",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 370,
        "country": "Germany",
        "countryname": "germany",
        "type": "germany",
        "typeid": 23,
        "name": "German  Weimar Republic",
        "monarchtype": "germany",
        "year": 1875,
        "tagline": "germany",
        "title": "1875 20 Pfennig",
        "description": "1875 20 Pfennig 20 Pfennig - Wilhelm I type 1 - large shield",
        "frontImg": "187520pf.jpg",
        "frontTitle": "GERMAN EMPIRE",
        "frontDescription": "Imperial eagle mintmarks below.",
        "backImg": "187520pb.jpg",
        "backTitle": "Pfennig",
        "backDescription": "Value and date",
        "bottomContent": "germany",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 371,
        "country": "Germany",
        "countryname": "germany",
        "type": "germany",
        "typeid": 23,
        "name": "German  Weimar Republic",
        "monarchtype": "germany",
        "year": 1873,
        "tagline": "germany",
        "title": "1873 10 Pfennig",
        "description": "1873 10 Pfennig - Wilhelm I type 1 - large shield",
        "frontImg": "187310pf.jpg",
        "frontTitle": "GERMAN EMPIRE",
        "frontDescription": "Coat of Arms (Imperial eagle with big shield).NOTE: Mintmarks on either side of tail.",
        "backImg": "187310pb.jpg",
        "backTitle": "Pfennig",
        "backDescription": "Value and date",
        "bottomContent": "germany",
        "price": "$10.00",
        "ebay": ""
      },
      {
        "id": 372,
        "country": "Germany",
        "countryname": "germany",
        "type": "germany",
        "typeid": 23,
        "name": "German  Weimar Republic",
        "monarchtype": "germany",
        "year": 1874,
        "tagline": "germany",
        "title": "1874 2 Pfennig",
        "description": "1874 2 Pfennig - Wilhelm I type 1 - large shield",
        "frontImg": "18742pf.jpg",
        "frontTitle": "GERMAN EMPIRE",
        "frontDescription": "The Imperial German coat of arms (Imperial eagle with a big shield on breast)",
        "backImg": "18742pb.jpg",
        "backTitle": "Pfennig",
        "backDescription": "Value and date",
        "bottomContent": "germany",
        "price": "$30.00",
        "ebay": ""
      },
      {
        "id": 373,
        "country": "Germany",
        "countryname": "germany",
        "type": "germany",
        "typeid": 23,
        "name": "German Kingdom of Württemberg",
        "monarchtype": "germany",
        "year": 1863,
        "tagline": "germany",
        "title": "1863 ½ Kreuzer - William I",
        "description": "1863 ½ Kreuzer - William I",
        "frontImg": "186312kf.jpg",
        "frontTitle": "GERMAN EMPIRE",
        "frontDescription": "Crowned arms within oak branches",
        "backImg": "186312kb.jpg",
        "backTitle": "Kreuzer",
        "backDescription": "Value and date",
        "bottomContent": "germany",
        "price": "$30.00",
        "ebay": ""
      },
      {
        "id": 374,
        "country": "Germany",
        "countryname": "germany",
        "type": "germany",
        "typeid": 23,
        "name": "German Frederick I",
        "monarchtype": "germany",
        "year": 1856,
        "tagline": "germany",
        "title": "1856 1 Kreuzer",
        "description": "1856 1 Kreuzer Frederick I  Grand Duchy of Baden",
        "frontImg": "18561kf.jpg",
        "frontTitle": "Frederick I",
        "frontDescription": "Frederick I (Friedrich I) (1852-1856)",
        "backImg": "18561kb.jpg",
        "backTitle": "Kreuzer",
        "backDescription": "Denomination above date.",
        "bottomContent": "germany",
        "price": "$20.00",
        "ebay": ""
      },
      {
        "id": 375,
        "country": "Germany",
        "countryname": "germany",
        "type": "germany",
        "typeid": 23,
        "name": "German Duchy of Brunswick",
        "monarchtype": "germany",
        "year": 1856,
        "tagline": "germany",
        "title": "1856 1 Pfennig",
        "description": "1856 1 Pfennig -  William VIII (1830-1884)",
        "frontImg": "18561pf.jpg",
        "frontTitle": "Duchy of Brunswick",
        "frontDescription": "Rearing horse left",
        "backImg": "18561pb.jpg",
        "backTitle": "Pfennig",
        "backDescription": "Denomination above date.",
        "bottomContent": "germany",
        "price": "$20.00",
        "ebay": ""
      },
      {
        "id": 376,
        "country": "Germany",
        "countryname": "germany",
        "type": "germany",
        "typeid": 23,
        "name": "German Free City (1372-1866)",
        "monarchtype": "germany",
        "year": 1821,
        "tagline": "germany",
        "title": "1821 1 Heller",
        "description": "1821 1 Heller  Free imperial city of Frankfurt",
        "frontImg": "1821ihf.jpg",
        "frontTitle": "Free City",
        "frontDescription": "Spread Eagle wearing crown",
        "backImg": "1821ihb.jpg",
        "backTitle": "Heller",
        "backDescription": "Denomination date",
        "bottomContent": "germany",
        "price": "$30.00",
        "ebay": ""
      },
      {
        "id": 377,
        "country": "Germany",
        "countryname": "germany",
        "type": "germany",
        "typeid": 23,
        "name": "German Kingdom of Hannover",
        "monarchtype": "germany",
        "year": 1858,
        "tagline": "germany",
        "title": "1858 1 Groschen",
        "description": "1858 1 Groschen  George V (1851-1866)",
        "frontImg": "18581gf.jpg",
        "frontTitle": "George V (1851-1866)",
        "frontDescription": "Rearing horse left",
        "backImg": "18581gb.jpg",
        "backTitle": "Groschen",
        "backDescription": "Denomination and date",
        "bottomContent": "germany",
        "price": "$30.00",
        "ebay": ""
      },
      {
        "id": 378,
        "country": "Germany",
        "countryname": "germany",
        "type": "germany",
        "typeid": 23,
        "name": "German City of Osnabrück",
        "monarchtype": "germany",
        "year": 1805,
        "tagline": "germany",
        "title": "1805 3 Pfennig",
        "description": "1805 3 Pfennig",
        "frontImg": "18053pf.jpg",
        "frontTitle": "Free city",
        "frontDescription": "6 spoked wheel top surrounded by letters.",
        "backImg": "18053pb.jpg",
        "backTitle": "PFENNING",
        "backDescription": "Denomination and date in 3-line inscription.",
        "bottomContent": "germany",
        "price": "$80.00",
        "ebay": ""
      },
      {
        "id": 379,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "uktokens",
        "typeid": 12,
        "name": "Queen Victoria",
        "monarchtype": "Victoria",
        "year": 1837,
        "tagline": "uktokens",
        "title": "1837 TOKEN TO HANOVER 1849 VICTORIA HEAD",
        "description": "The tokens & counters that bear the words To Hanover (commonly known as Cumberland Jacks) are usually found with Victoria's portrait on the obverse and a figure riding a horse on the reverse (in the style of St George slaying the dragon). They were produced as satirical counters.The history behind these pieces is that when Victoria was crowned as Queen she was unable to fulfil the role of King of Hanover under Salic Law due to the fact that she was a female. Her unpopular Uncle (Ernest Augustus the Duke of Cumberland) who was the oldest male heir to the throne was sent off to Hanover instead to fulfil the role and the counters/tokens were produced to commemorate (celebrate) his departure. Such was his unpopularity that many of the tokens on closer inspection actually depict a monkey on horseback which was intended to portray the Duke.They were produced around the time of Victorias ascension to the throne in 1837 and continued to be produced for the next few decades. They were finally made illegal in 1883.",
        "frontImg": "1849tf.jpg",
        "frontTitle": "Copper",
        "frontDescription": "Portrait left Date",
        "backImg": "1849tb.jpg",
        "backTitle": "Conder tokens",
        "backDescription": "TO HANOVER Small date.",
        "bottomContent": "The token",
        "price": "$15.00",
        "ebay": ""
      },
      {
        "id": 380,
        "country": "United Kingdom",
        "countryname": "unitedkingdom",
        "type": "uktokens",
        "typeid": 12,
        "name": "Queen Victoria",
        "monarchtype": "BIRMINGHAM",
        "year": 1812,
        "tagline": "uktokens",
        "title": "1812 1 Penny Birmingham - Union Copper Company",
        "description": "Davis Warwickshire No: 67",
        "frontImg": "1812tf.jpg",
        "frontTitle": "Copper 27.1 g",
        "frontDescription": "Clasped hands above date in centre circle. Lettering around beaded border",
        "backImg": "1812tb.jpg",
        "backTitle": "Conder tokens",
        "backDescription": "Legend on three lines in centre circle. Lettering around beaded border",
        "bottomContent": "The token",
        "price": "$45.00",
        "ebay": ""
      },
      {
        "id": 381,
        "country": "United States",
        "countryname": "unitedstates",
        "type": "morgan",
        "typeid": 10,
        "name": "Morgan Dollars",
        "monarchtype": "unitedstates",
        "year": 1886,
        "tagline": "Morgan Dollars",
        "title": "1886 Morgan Dollars",
        "description": "This 1886 Philadelphia Mint Morgan dollar is ranked number 91 with a mintage of 19963886. It also holds the place of 25th out of 29 from Morgan dollars made at the Philadelphia Mint.",
        "frontImg": "1886f.jpg",
        "frontTitle": "Philadelphia",
        "frontDescription": "The bust of Lady Liberty",
        "backImg": "1886b.jpg",
        "backTitle": "Liberty",
        "backDescription": "Morgans depiction of the eagle is extremely intricate and the its feathers can be seen in full detail.",
        "bottomContent": "Morgans depiction of the eagle is extremely intricate and the its feathers can be seen in full detail.",
        "price": "$170.00",
        "ebay": ""
      },
      {
        "id": 382,
        "country": "United States",
        "countryname": "unitedstates",
        "type": "morgan",
        "typeid": 10,
        "name": "Morgan Dollars",
        "monarchtype": "unitedstates",
        "year": 1886,
        "tagline": "Morgan Dollars",
        "title": "1886 Morgan Dollars",
        "description": "This 1886 Philadelphia Mint Morgan dollar is ranked number 91 with a mintage of 19963886. It also holds the place of 25th out of 29 from Morgan dollars made at the Philadelphia Mint.",
        "frontImg": "18862f.jpg",
        "frontTitle": "Philadelphia",
        "frontDescription": "The bust of Lady Liberty",
        "backImg": "18862b.jpg",
        "backTitle": "Liberty",
        "backDescription": "Morgans depiction of the eagle is extremely intricate and the its feathers can be seen in full detail.",
        "bottomContent": "Morgans depiction of the eagle is extremely intricate and the its feathers can be seen in full detail.",
        "price": "$170.00",
        "ebay": ""
      },
      {
        "id": 383,
        "country": "United States",
        "countryname": "unitedstates",
        "type": "morgan",
        "typeid": 10,
        "name": "Morgan Dollars",
        "monarchtype": "unitedstates",
        "year": 1878,
        "tagline": "Morgan Dollars",
        "title": "1878 CC Morgan Dollars",
        "description": "Heres the first year of the Morgan dollar series and first year of the low-mintage CC mint mark Morgans from the short-lived frontier mint in Carson City Nevada.",
        "frontImg": "1878ccf.jpg",
        "frontTitle": "Carson City",
        "frontDescription": "The bust of Lady Liberty",
        "backImg": "1878ccb.jpg",
        "backTitle": "Liberty",
        "backDescription": "Morgans depiction of the eagle is extremely intricate and the its feathers can be seen in full detail.",
        "bottomContent": "Morgans depiction of the eagle is extremely intricate and the its feathers can be seen in full detail.",
        "price": "$651.00",
        "ebay": ""
      },
      {
        "id": 384,
        "country": "United States",
        "countryname": "unitedstates",
        "type": "morgan",
        "typeid": 10,
        "name": "Morgan Dollars",
        "monarchtype": "unitedstates",
        "year": 1878,
        "tagline": "Morgan Dollars",
        "title": "1878 8tf Morgan Dollars",
        "description": "1878 Morgan Dollars : 8 Tail Feathers Early Silver Dollars Philadelphia (No Mint Mark)",
        "frontImg": "1878f.jpg",
        "frontTitle": "Philadelphia",
        "frontDescription": "The bust of Lady Liberty",
        "backImg": "1878b.jpg",
        "backTitle": "Liberty",
        "backDescription": "Morgans depiction of the eagle is extremely intricate and the its feathers can be seen in full detail.",
        "bottomContent": "Morgans depiction of the eagle is extremely intricate and the its feathers can be seen in full detail.",
        "price": "$455.00",
        "ebay": ""
      },
      {
        "id": 385,
        "country": "United States",
        "countryname": "unitedstates",
        "type": "morgan",
        "typeid": 10,
        "name": "Morgan Dollars",
        "monarchtype": "unitedstates",
        "year": 1880,
        "tagline": "Morgan Dollars",
        "title": "1880 S Morgan Dollars",
        "description": "1880 S Morgan Silver Dollar. Contains 90% Silver",
        "frontImg": "1880f.jpg",
        "frontTitle": "San Francisco",
        "frontDescription": "The bust of Lady Liberty",
        "backImg": "1880b.jpg",
        "backTitle": "Liberty",
        "backDescription": "Morgans depiction of the eagle is extremely intricate and the its feathers can be seen in full detail.",
        "bottomContent": "Morgans depiction of the eagle is extremely intricate and the its feathers can be seen in full detail.",
        "price": "$245.00",
        "ebay": ""
      },
      {
        "id": 386,
        "country": "United States",
        "countryname": "unitedstates",
        "type": "morgan",
        "typeid": 10,
        "name": "Morgan Dollars",
        "monarchtype": "unitedstates",
        "year": 1881,
        "tagline": "Morgan Dollars",
        "title": "1881 S Morgan Dollars",
        "description": "1881 S Morgan Silver Dollar. Contains 90% Silver",
        "frontImg": "18812f.jpg",
        "frontTitle": "San Francisco",
        "frontDescription": "The bust of Lady Liberty",
        "backImg": "18812b.jpg",
        "backTitle": "Liberty",
        "backDescription": "Morgans depiction of the eagle is extremely intricate and the its feathers can be seen in full detail.",
        "bottomContent": "Morgans depiction of the eagle is extremely intricate and the its feathers can be seen in full detail.",
        "price": "$90.00",
        "ebay": ""
      },
      {
        "id": 387,
        "country": "United States",
        "countryname": "unitedstates",
        "type": "morgan",
        "typeid": 10,
        "name": "Morgan Dollars",
        "monarchtype": "unitedstates",
        "year": 1885,
        "tagline": "Morgan Dollars",
        "title": "1885 Morgan Dollars",
        "description": "This 1885 Philadelphia Mint Morgan dollar.",
        "frontImg": "18852f.jpg",
        "frontTitle": "Philadelphia",
        "frontDescription": "The bust of Lady Liberty",
        "backImg": "18852b.jpg",
        "backTitle": "Liberty",
        "backDescription": "Morgans depiction of the eagle is extremely intricate and the its feathers can be seen in full detail.",
        "bottomContent": "Morgans depiction of the eagle is extremely intricate and the its feathers can be seen in full detail.",
        "price": "$140.00",
        "ebay": ""
      },
      {
        "id": 388,
        "country": "United States",
        "countryname": "unitedstates",
        "type": "morgan",
        "typeid": 10,
        "name": "Morgan Dollars",
        "monarchtype": "unitedstates",
        "year": 1883,
        "tagline": "Morgan Dollars",
        "title": "1883 O Morgan Dollars",
        "description": "This 1883 New Orleans Mint Morgan dollar.",
        "frontImg": "1883f.jpg",
        "frontTitle": "New Orleans",
        "frontDescription": "The bust of Lady Liberty",
        "backImg": "1883b.jpg",
        "backTitle": "Liberty",
        "backDescription": "Morgans depiction of the eagle is extremely intricate and the its feathers can be seen in full detail.",
        "bottomContent": "Morgans depiction of the eagle is extremely intricate and the its feathers can be seen in full detail.",
        "price": "$140.00",
        "ebay": ""
      },
      {
        "id": 389,
        "country": "United States",
        "countryname": "unitedstates",
        "type": "morgan",
        "typeid": 10,
        "name": "Morgan Dollars",
        "monarchtype": "unitedstates",
        "year": 1882,
        "tagline": "Morgan Dollars",
        "title": "1882 S Morgan Dollars",
        "description": "1881 S Morgan Silver Dollar. Contains 90% Silver",
        "frontImg": "1882f.jpg",
        "frontTitle": "San Francisco",
        "frontDescription": "The bust of Lady Liberty",
        "backImg": "1882b.jpg",
        "backTitle": "Liberty",
        "backDescription": "Morgans depiction of the eagle is extremely intricate and the its feathers can be seen in full detail.",
        "bottomContent": "Morgans depiction of the eagle is extremely intricate and the its feathers can be seen in full detail.",
        "price": "$160.00",
        "ebay": ""
      },
      {
        "id": 390,
        "country": "United States",
        "countryname": "unitedstates",
        "type": "morgan",
        "typeid": 10,
        "name": "Morgan Dollars",
        "monarchtype": "unitedstates",
        "year": 1887,
        "tagline": "Morgan Dollars",
        "title": "1887 Morgan Dollars",
        "description": "This 1887 Philadelphia Mint Morgan dollar.",
        "frontImg": "1887f.jpg",
        "frontTitle": "Philadelphia",
        "frontDescription": "The bust of Lady Liberty",
        "backImg": "1887b.jpg",
        "backTitle": "Liberty",
        "backDescription": "Morgans depiction of the eagle is extremely intricate and the its feathers can be seen in full detail.",
        "bottomContent": "Morgans depiction of the eagle is extremely intricate and the its feathers can be seen in full detail.",
        "price": "$100.00",
        "ebay": ""
      },
      {
        "id": 391,
        "country": "United States",
        "countryname": "unitedstates",
        "type": "morgan",
        "typeid": 10,
        "name": "Morgan Dollars",
        "monarchtype": "unitedstates",
        "year": 1888,
        "tagline": "Morgan Dollars",
        "title": "1888 Morgan Dollars",
        "description": "This 1888 Philadelphia Mint Morgan dollar.",
        "frontImg": "1888f.jpg",
        "frontTitle": "Philadelphia",
        "frontDescription": "The bust of Lady Liberty",
        "backImg": "1888b.jpg",
        "backTitle": "Liberty",
        "backDescription": "Morgans depiction of the eagle is extremely intricate and the its feathers can be seen in full detail.",
        "bottomContent": "Morgans depiction of the eagle is extremely intricate and the its feathers can be seen in full detail.",
        "price": "$75.00",
        "ebay": ""
      },
      {
        "id": 392,
        "country": "United States",
        "countryname": "unitedstates",
        "type": "morgan",
        "typeid": 10,
        "name": "Morgan Dollars",
        "monarchtype": "unitedstates",
        "year": 1898,
        "tagline": "Morgan Dollars",
        "title": "1898 Morgan Dollars",
        "description": "This 1898 Philadelphia Mint Morgan dollar.",
        "frontImg": "1898f.jpg",
        "frontTitle": "Philadelphia",
        "frontDescription": "The bust of Lady Liberty",
        "backImg": "1898b.jpg",
        "backTitle": "Liberty",
        "backDescription": "Morgans depiction of the eagle is extremely intricate and the its feathers can be seen in full detail.",
        "bottomContent": "Morgans depiction of the eagle is extremely intricate and the its feathers can be seen in full detail.",
        "price": "$130.00",
        "ebay": ""
      },
      {
        "id": 393,
        "country": "United States",
        "countryname": "unitedstates",
        "type": "morgan",
        "typeid": 10,
        "name": "Morgan Dollars",
        "monarchtype": "unitedstates",
        "year": 1896,
        "tagline": "Morgan Dollars",
        "title": "1896 Morgan Dollars",
        "description": "This 1896 Philadelphia Mint Morgan dollar.",
        "frontImg": "1896f.jpg",
        "frontTitle": "Philadelphia",
        "frontDescription": "The bust of Lady Liberty",
        "backImg": "1896b.jpg",
        "backTitle": "Liberty",
        "backDescription": "Morgans depiction of the eagle is extremely intricate and the its feathers can be seen in full detail.",
        "bottomContent": "Morgans depiction of the eagle is extremely intricate and the its feathers can be seen in full detail.",
        "price": "$150.00",
        "ebay": ""
      },
      {
        "id": 394,
        "country": "United States",
        "countryname": "unitedstates",
        "type": "morgan",
        "typeid": 10,
        "name": "Morgan Dollars",
        "monarchtype": "unitedstates",
        "year": 1890,
        "tagline": "Morgan Dollars",
        "title": "1890 Morgan Dollars",
        "description": "This 1890 Philadelphia Mint Morgan dollar.",
        "frontImg": "18902f.jpg",
        "frontTitle": "Philadelphia",
        "frontDescription": "The bust of Lady Liberty",
        "backImg": "18902b.jpg",
        "backTitle": "Liberty",
        "backDescription": "Morgans depiction of the eagle is extremely intricate and the its feathers can be seen in full detail.",
        "bottomContent": "Morgans depiction of the eagle is extremely intricate and the its feathers can be seen in full detail.",
        "price": "$90.00",
        "ebay": ""
      },
      {
        "id": 395,
        "country": "United States",
        "countryname": "unitedstates",
        "type": "morgan",
        "typeid": 10,
        "name": "Morgan Dollars",
        "monarchtype": "unitedstates",
        "year": 1889,
        "tagline": "Morgan Dollars",
        "title": "1889 Morgan Dollars",
        "description": "This 1889 Philadelphia Mint Morgan dollar.",
        "frontImg": "1889f.jpg",
        "frontTitle": "Philadelphia",
        "frontDescription": "The bust of Lady Liberty",
        "backImg": "1889b.jpg",
        "backTitle": "Liberty",
        "backDescription": "Morgans depiction of the eagle is extremely intricate and the its feathers can be seen in full detail.",
        "bottomContent": "Morgans depiction of the eagle is extremely intricate and the its feathers can be seen in full detail.",
        "price": "$75.00",
        "ebay": ""
      },
      {
        "id": 396,
        "country": "United States",
        "countryname": "unitedstates",
        "type": "morgan",
        "typeid": 10,
        "name": "Morgan Dollars",
        "monarchtype": "unitedstates",
        "year": 1921,
        "tagline": "Morgan Dollars",
        "title": "1921 D Morgan Dollars",
        "description": "This 1921 Denver Mint Morgan dollar.",
        "frontImg": "1921f.jpg",
        "frontTitle": "Denver",
        "frontDescription": "The bust of Lady Liberty",
        "backImg": "1921b.jpg",
        "backTitle": "Liberty",
        "backDescription": "Morgans depiction of the eagle is extremely intricate and the its feathers can be seen in full detail.",
        "bottomContent": "Morgans depiction of the eagle is extremely intricate and the its feathers can be seen in full detail.",
        "price": "$60.00",
        "ebay": ""
      },
      {
        "id": 397,
        "country": "United States",
        "countryname": "unitedstates",
        "type": "morgan",
        "typeid": 10,
        "name": "Morgan Dollars",
        "monarchtype": "unitedstates",
        "year": 1904,
        "tagline": "Morgan Dollars",
        "title": "1904 O Morgan Dollars",
        "description": "This 1904 New Orleans Mint Morgan dollar.",
        "frontImg": "1904f.jpg",
        "frontTitle": "New Orleans",
        "frontDescription": "The bust of Lady Liberty",
        "backImg": "1904b.jpg",
        "backTitle": "Liberty",
        "backDescription": "Morgans depiction of the eagle is extremely intricate and the its feathers can be seen in full detail.",
        "bottomContent": "Morgans depiction of the eagle is extremely intricate and the its feathers can be seen in full detail.",
        "price": "$110.00",
        "ebay": ""
      },
      {
        "id": 398,
        "country": "United States",
        "countryname": "unitedstates",
        "type": "morgan",
        "typeid": 10,
        "name": "Morgan Dollars",
        "monarchtype": "unitedstates",
        "year": 1902,
        "tagline": "Morgan Dollars",
        "title": "1902 O Morgan Dollars",
        "description": "This 1902 New Orleans Mint Morgan dollar.",
        "frontImg": "1902f.jpg",
        "frontTitle": "New Orleans",
        "frontDescription": "The bust of Lady Liberty",
        "backImg": "1902b.jpg",
        "backTitle": "Liberty",
        "backDescription": "Morgans depiction of the eagle is extremely intricate and the its feathers can be seen in full detail.",
        "bottomContent": "Morgans depiction of the eagle is extremely intricate and the its feathers can be seen in full detail.",
        "price": "$150.00",
        "ebay": ""
      },
      {
        "id": 399,
        "country": "United States",
        "countryname": "unitedstates",
        "type": "morgan",
        "typeid": 10,
        "name": "Morgan Dollars",
        "monarchtype": "unitedstates",
        "year": 1900,
        "tagline": "Morgan Dollars",
        "title": "1900 O Morgan Dollars",
        "description": "This 1900 New Orleans Mint Morgan dollar.",
        "frontImg": "19002f.jpg",
        "frontTitle": "New Orleans",
        "frontDescription": "The bust of Lady Liberty",
        "backImg": "19002b.jpg",
        "backTitle": "Liberty",
        "backDescription": "Morgans depiction of the eagle is extremely intricate and the its feathers can be seen in full detail.",
        "bottomContent": "Morgans depiction of the eagle is extremely intricate and the its feathers can be seen in full detail.",
        "price": "$299.00",
        "ebay": ""
      },
      {
        "id": 400,
        "country": "United States",
        "countryname": "unitedstates",
        "type": "halfdollar",
        "typeid": 13,
        "name": "Half Dollars",
        "monarchtype": "unitedstates",
        "year": 1836,
        "tagline": "Half Dollars",
        "title": "1836 Half Dollars Capped Bust Half Dollar",
        "description": "1836 Half Dollars Capped Bust Half Dollar The Coinage Act of 18 January 1837 decreased the standard weight of half dollars from 208 grains (13.48 grams KM# 58) to 206¼ grains (13.36 grams KM# 58a). The fineness was increased from .8924 silver (KM# 58) to .900 silver (KM# 58a). The silver content was unchanged. These were the last coins struck to the old standard.",
        "frontImg": "1836f.jpg",
        "frontTitle": "Half Dollars",
        "frontDescription": "Liberty cap head left 13 stars around",
        "backImg": "1836b.jpg",
        "backTitle": "50 Cents",
        "backDescription": "Eagle with arrows and olive branch in talons",
        "bottomContent": "Half Dollars",
        "price": "$140.00",
        "ebay": ""
      },
      {
        "id": 401,
        "country": "United States",
        "countryname": "unitedstates",
        "type": "halfdollar",
        "typeid": 13,
        "name": "Half Dollars",
        "monarchtype": "unitedstates",
        "year": 1867,
        "tagline": "Half Dollars",
        "title": "1867 Seated Liberty Half Dollar",
        "description": "1867 Seated Liberty Half Dollar",
        "frontImg": "1867f.jpg",
        "frontTitle": "Half Dollars",
        "frontDescription": "Seated Liberty looking right above her shoulder with a cap on a stick and a shield 13 stars around",
        "backImg": "1867b.jpg",
        "backTitle": "50 Cents",
        "backDescription": "Eagle with arrows and olive branch in talons banner with motto above",
        "bottomContent": "Half Dollars",
        "price": "$160.00",
        "ebay": ""
      },
      {
        "id": 402,
        "country": "United States",
        "countryname": "unitedstates",
        "type": "halfdollar",
        "typeid": 13,
        "name": "Half Dollars",
        "monarchtype": "unitedstates",
        "year": 1929,
        "tagline": "Half Dollars",
        "title": "1929 walking liberty half dollar",
        "description": "1929 walking liberty half dollar",
        "frontImg": "1929f.jpg",
        "frontTitle": "Half Dollars",
        "frontDescription": "Seated Liberty looking right above her shoulder with a cap on a stick and a shield 13 stars around",
        "backImg": "1929b.jpg",
        "backTitle": "50 Cents",
        "backDescription": "Eagle with arrows and olive branch in talons banner with motto above",
        "bottomContent": "Half Dollars",
        "price": "$160.00",
        "ebay": ""
      },
      {
        "id": 403,
        "country": "United States",
        "countryname": "unitedstates",
        "type": "halfdollar",
        "typeid": 13,
        "name": "Half Dollars",
        "monarchtype": "unitedstates",
        "year": 1924,
        "tagline": "Half Dollars",
        "title": "1924 huguenot commemorative half dollar",
        "description": "The 1924 Half dollar is part of a series of Classic Commemorative coins struck from 1892-1954. The specific variety is Huguenot. Struck in Philadelphia and designated as a Business (MS) strike this coin is made of 90% silver; 10% copper from a mintage of 142080 struck.",
        "frontImg": "1924f.jpg",
        "frontTitle": "Half Dollars",
        "frontDescription": "Admiral Gaspard de Coligny (COLIGNY) 1519-1572 and Wilem I Prince of Orange (WILLIAM THE SILENT) 1533-1584 Although they were not involved in the settlement they were viewed as leaders in the struggle for religious freedom.",
        "backImg": "1924b.jpg",
        "backTitle": "50 Cents",
        "backDescription": "The Nieuw Netherlandt sailing ship on the Hudson River.",
        "bottomContent": "Half Dollars",
        "price": "$130.00",
        "ebay": ""
      },
      {
        "id": 404,
        "country": "United States",
        "countryname": "unitedstates",
        "type": "halfdollar",
        "typeid": 13,
        "name": "Half Dollars",
        "monarchtype": "unitedstates",
        "year": 1922,
        "tagline": "Half Dollars",
        "title": "1922 Ulysses S. Grant half dollar",
        "description": "The 1922 Grant Memorial Half Dollar was issued for the centennial of the birth of Ulysses S. Grant. He had severed as General of the American Armies during the Civil war and served two terms as the 18th President of the United States.",
        "frontImg": "1922f.jpg",
        "frontTitle": "Half Dollars",
        "frontDescription": "Portrait of Ulysses S. Grant adapted from a Matthew Brady photograph.",
        "backImg": "1922b.jpg",
        "backTitle": "50 Cents",
        "backDescription": "The log cabin in Point Pleasant  Ohio which was where Grant was born.",
        "bottomContent": "Half Dollars",
        "price": "$130.00",
        "ebay": ""
      },
      {
        "id": 405,
        "country": "United States",
        "countryname": "unitedstates",
        "type": "halfdollar",
        "typeid": 13,
        "name": "Half Dollars",
        "monarchtype": "unitedstates",
        "year": 1926,
        "tagline": "Half Dollars",
        "title": "1926 Half Dollars Oregon Trail Memorial",
        "description": "1926 Half Dollars Oregon Trail Memorial",
        "frontImg": "1926otmf.jpg",
        "frontTitle": "Half Dollars",
        "frontDescription": "A covered wagon drawn by two oxen moving west towards the setting sun",
        "backImg": "1926otmb.jpg",
        "backTitle": "Oregon Trail",
        "backDescription": "Full figure of a Native American in headdress. He stands before a map of the United States with the Oregon Trail marked with his left hand outstretched to the east and a bow in his right hand spanning the continent.",
        "bottomContent": "Half Dollars",
        "price": "$280.00",
        "ebay": ""
      },
      {
        "id": 406,
        "country": "United States",
        "countryname": "unitedstates",
        "type": "halfdollar",
        "typeid": 13,
        "name": "Half Dollars",
        "monarchtype": "unitedstates",
        "year": 1926,
        "tagline": "Half Dollars",
        "title": "1921 Alabama Centennial Half Dollars",
        "description": "1921 Half Dollars Alabama Centennial",
        "frontImg": "1921f.jpg",
        "frontTitle": "Half Dollars",
        "frontDescription": "Portraits of William Wyatt Bibb  the first governor of Alabama and Thomas E. Kilby the current governor. Twenty-two stars are arranged to each side of the portrait.",
        "backImg": "1921b.jpg",
        "backTitle": "Alabama",
        "backDescription": "An eagle perched on a shield which is based on the Alabama State Seal.",
        "bottomContent": "Half Dollars",
        "price": "$170.00",
        "ebay": ""
      },
      {
        "id": 407,
        "country": "United States",
        "countryname": "unitedstates",
        "type": "halfdollar",
        "typeid": 13,
        "name": "Half Dollars",
        "monarchtype": "unitedstates",
        "year": 1892,
        "tagline": "Half Dollars",
        "title": "1892 Columbus Commemorative Half Dollar",
        "description": "1892 Columbus Commemorative Half Dollar This Half Dollar was struck for the World's Columbian Exposition held in Chicago combined with the 400th Anniversary of the discovery of America by Christopher Columbus.",
        "frontImg": "1892f.jpg",
        "frontTitle": "Half Dollars",
        "frontDescription": "Designer Charles E. Barber.s Miss Liberty now faces right her hair is in a Phrygian cap and a wreath of laurel encircles her head.",
        "backImg": "1892b.jpg",
        "backTitle": "50 Cents",
        "backDescription": "An adaptation of the Great Seal of the United States. It features a heraldic eagle grasping an olive branch with arrows and holding in its beak a ribbon inscribed",
        "bottomContent": "Half Dollars",
        "price": "$40.00",
        "ebay": ""
      },
      {
        "id": 408,
        "country": "United States",
        "countryname": "unitedstates",
        "type": "halfdollar",
        "typeid": 13,
        "name": "Half Dollars",
        "monarchtype": "unitedstates",
        "year": 1892,
        "tagline": "Half Dollars",
        "title": "1892 Columbus Commemorative Half Dollar",
        "description": "1892 Columbus Commemorative Half Dollar This Half Dollar was struck for the World's Columbian Exposition held in Chicago combined with the 400th Anniversary of the discovery of America by Christopher Columbus.",
        "frontImg": "18922f.jpg",
        "frontTitle": "Half Dollars",
        "frontDescription": "Designer Charles E. Barber.s Miss Liberty now faces right her hair is in a Phrygian cap and a wreath of laurel encircles her head.",
        "backImg": "18922b.jpg",
        "backTitle": "50 Cents",
        "backDescription": "An adaptation of the Great Seal of the United States. It features a heraldic eagle grasping an olive branch with arrows and holding in its beak a ribbon inscribed",
        "bottomContent": "Half Dollars",
        "price": "$40.00",
        "ebay": ""
      },
      {
        "id": 409,
        "country": "United States",
        "countryname": "unitedstates",
        "type": "halfdollar",
        "typeid": 13,
        "name": "Half Dollars",
        "monarchtype": "unitedstates",
        "year": 1925,
        "tagline": "Half Dollars",
        "title": "1925 Half Dollar Stone Mountain Memorial",
        "description": "The 1925 Stone Mountain Memorial Half Dollar was one of four different commemorative coins issued for the year.",
        "frontImg": "1925f.jpg",
        "frontTitle": "Half Dollars",
        "frontDescription": "Generals Robert E. Lee and Stonewall Jackson on horseback",
        "backImg": "1925b.jpg",
        "backTitle": "50 Cents",
        "backDescription": "An eagle poised on a mountain crag with stars visible in the background and the denomination at the bottom",
        "bottomContent": "Half Dollars",
        "price": "$70.00",
        "ebay": ""
      },
      {
        "id": 410,
        "country": "United States",
        "countryname": "unitedstates",
        "type": "halfdollar",
        "typeid": 13,
        "name": "Half Dollars",
        "monarchtype": "unitedstates",
        "year": 1925,
        "tagline": "Half Dollars",
        "title": "The 1925 California Diamond Jubilee Half Dollar",
        "description": "The 1925 California Diamond Jubilee Half Dollar is one of the collector favorites amongst early commemorative coins.",
        "frontImg": "1925calf.jpg",
        "frontTitle": "Half Dollars",
        "frontDescription": "A kneeling figure of a forty-niner as he pans for gold.",
        "backImg": "1925calb.jpg",
        "backTitle": "50 Cents",
        "backDescription": "An image of a grizzly bear which comes from the state emblem of California.",
        "bottomContent": "Half Dollars",
        "price": "$70.00",
        "ebay": ""
      },
      {
        "id": 411,
        "country": "United States",
        "countryname": "unitedstates",
        "type": "halfdollar",
        "typeid": 13,
        "name": "Half Dollars",
        "monarchtype": "unitedstates",
        "year": 1934,
        "tagline": "Half Dollars",
        "title": "The 1934 Texas Independence Half Dollar",
        "description": "The 1934 Texas Independence Half Dollar Centennial of Texas Independence",
        "frontImg": "1934txf.jpg",
        "frontTitle": "Half Dollars",
        "frontDescription": "Large American eagle facing left superimposed on a five-pointed star symbol of the state of Texas.",
        "backImg": "1934txb.jpg",
        "backTitle": "50 Cents",
        "backDescription": "Victory winged and draped holding an olive branch in her right hand and resting her left hand on what represents the Alamo.",
        "bottomContent": "Half Dollars",
        "price": "$170.00",
        "ebay": ""
      },
      {
        "id": 412,
        "country": "United States",
        "countryname": "unitedstates",
        "type": "halfdollar",
        "typeid": 13,
        "name": "Half Dollars",
        "monarchtype": "unitedstates",
        "year": 1935,
        "tagline": "Half Dollars",
        "title": "The 1935 Boone Bicentennial Half Dollar",
        "description": "The 1935 Boone Bicentennial Half Dollar The Daniel Boone Half Dollar was minted to celebrate the 200th anniversary of the birth of the famous frontiersman explorer and folk hero.",
        "frontImg": "1935bof.jpg",
        "frontTitle": "Half Dollars",
        "frontDescription": "A frontiersman holding a chart of Kentucky and a musket facing an American Indian holding a tomahawk. In the background a blockhouse stockade and a sun with rays appear.",
        "backImg": "1935bob.jpg",
        "backTitle": "50 Cents",
        "backDescription": "A portrait of Daniel Boone. Since no known portraits of him exist this is an artist's conception.",
        "bottomContent": "Half Dollars",
        "price": "$215.00",
        "ebay": ""
      },
      {
        "id": 413,
        "country": "United States",
        "countryname": "unitedstates",
        "type": "halfdollar",
        "typeid": 13,
        "name": "Half Dollars",
        "monarchtype": "unitedstates",
        "year": 1935,
        "tagline": "Half Dollars",
        "title": "The 1935 Connecticut Half Dollar",
        "description": "The 1935 Connecticut Half Dollar 300th Anniversary of the Settlement of Connecticut",
        "frontImg": "1935conf.jpg",
        "frontTitle": "Half Dollars",
        "frontDescription": "Bald Eagle facing left in the art deco style.",
        "backImg": "1935conb.jpg",
        "backTitle": "50 Cents",
        "backDescription": "Charter Oak.",
        "bottomContent": "Half Dollars",
        "price": "$375.00",
        "ebay": ""
      },
      {
        "id": 414,
        "country": "United States",
        "countryname": "unitedstates",
        "type": "halfdollar",
        "typeid": 13,
        "name": "Half Dollars",
        "monarchtype": "unitedstates",
        "year": 1936,
        "tagline": "Half Dollars",
        "title": "The 1936 San Diego California-Pacific Exposition Half Dollar",
        "description": "The 1936 San Diego California-Pacific Exposition Half Dollar",
        "frontImg": "1936sdf.jpg",
        "frontTitle": "Half Dollars",
        "frontDescription": "Seated Minerva Goddess of wisdom holding a spear in one hand and a shield with the other a cornucopia next to the shield a grizzly bear In front of the spear and a ship a gold miner and mountains in the background",
        "backImg": "1936sdb.jpg",
        "backTitle": "50 Cents",
        "backDescription": "Chapel of St. Francis and the California Tower",
        "bottomContent": "Half Dollars",
        "price": "$160.00",
        "ebay": ""
      },
      {
        "id": 415,
        "country": "United States",
        "countryname": "unitedstates",
        "type": "halfdollar",
        "typeid": 13,
        "name": "Half Dollars",
        "monarchtype": "unitedstates",
        "year": 1934,
        "tagline": "Half Dollars",
        "title": "The 1934 Texas Independence Half Dollar",
        "description": "The 1934 Texas Independence Half Dollar Centennial of Texas Independence",
        "frontImg": "1934tx2f.jpg",
        "frontTitle": "Half Dollars",
        "frontDescription": "Large American eagle facing left superimposed on a five-pointed star symbol of the state of Texas.",
        "backImg": "1934tx2b.jpg",
        "backTitle": "50 Cents",
        "backDescription": "Victory winged and draped holding an olive branch in her right hand and resting her left hand on what represents the Alamo.",
        "bottomContent": "Half Dollars",
        "price": "$250.00",
        "ebay": ""
      },
      {
        "id": 416,
        "country": "United States",
        "countryname": "unitedstates",
        "type": "halfdollar",
        "typeid": 13,
        "name": "Half Dollars",
        "monarchtype": "unitedstates",
        "year": 1946,
        "tagline": "Half Dollars",
        "title": "The 1946 Booker T. Washington Memorial Half Dollar",
        "description": "The 1946 Booker T. Washington Memorial Half Dollar The Booker T. Washington Half Dollars were minted in large numbers for the first year of issue and offered by the Booker T. Washington Birthplace Memorial Commission",
        "frontImg": "1946bof.jpg",
        "frontTitle": "Half Dollars",
        "frontDescription": "Booker T. Washington bust right",
        "backImg": "1946bob.jpg",
        "backTitle": "50 Cents",
        "backDescription": "An image of the Hall of Fame at New York University and a log cabin.",
        "bottomContent": "Half Dollars",
        "price": "$50.00",
        "ebay": ""
      },
      {
        "id": 417,
        "country": "United States",
        "countryname": "unitedstates",
        "type": "halfdollar",
        "typeid": 13,
        "name": "Half Dollars",
        "monarchtype": "unitedstates",
        "year": 1952,
        "tagline": "Half Dollars",
        "title": "The 1952 Washington-Carver Half Dollar",
        "description": "The 1952 Washington-Carver Half Dollar Honors educator and founder of Tuskegee Institute Booker T. Washington; and botanist George Washington Carver",
        "frontImg": "1952f.jpg",
        "frontTitle": "Half Dollars",
        "frontDescription": "Washington and Carver's profiles looking right with two rings of lettering encircling their portrait",
        "backImg": "1952b.jpg",
        "backTitle": "50 Cents",
        "backDescription": "Map of the continental United State (48 out of the 50) with the letters USA superimposed in capitals over said map. One ring of lettering encircling the map of the continental US.",
        "bottomContent": "Half Dollars",
        "price": "$100.00",
        "ebay": ""
      },
      {
        "id": 418,
        "country": "Iceland",
        "countryname": "iceland",
        "type": "iceland",
        "typeid": 23,
        "name": "Iceland",
        "monarchtype": "iceland",
        "year": 1966,
        "tagline": "iceland",
        "title": "1966 1 Króna",
        "description": "1966 1 Króna",
        "frontImg": "1966kf.jpg",
        "frontTitle": "Royal Mint (Tower Hill)",
        "frontDescription": "Icelandic coat of arms surrounded by the four traditional protector spirits (\"Landvættir\") of Iceland. These are Griðungur the bull, Gammur the eagle, Dreki the dragon, and Bergrisi the giant",
        "backImg": "1966kb.jpg",
        "backTitle": "KRÓNA",
        "backDescription": "Face value at the center and leaves of pubescent birch (Betula pubescens, only endemic tree of Iceland), to left and right.",
        "bottomContent": "iceland",
        "price": "$2.00",
        "ebay": ""
      },
      {
        "id": 419,
        "country": "Iceland",
        "countryname": "iceland",
        "type": "iceland",
        "typeid": 23,
        "name": "Iceland",
        "monarchtype": "iceland",
        "year": 1965,
        "tagline": "iceland",
        "title": "1965 1 Króna",
        "description": "1965 1 Króna",
        "frontImg": "1965kf.jpg",
        "frontTitle": "Royal Mint (Tower Hill)",
        "frontDescription": "Icelandic coat of arms surrounded by the four traditional protector spirits (\"Landvættir\") of Iceland. These are Griðungur the bull, Gammur the eagle, Dreki the dragon, and Bergrisi the giant",
        "backImg": "1965kb.jpg",
        "backTitle": "KRÓNA",
        "backDescription": "Face value at the center and leaves of pubescent birch (Betula pubescens, only endemic tree of Iceland), to left and right.",
        "bottomContent": "iceland",
        "price": "$2.00",
        "ebay": ""
      },
      {
        "id": 420,
        "country": "Iceland",
        "countryname": "iceland",
        "type": "iceland",
        "typeid": 23,
        "name": "Iceland",
        "monarchtype": "iceland",
        "year": 1957,
        "tagline": "iceland",
        "title": "1957 1 Króna",
        "description": "1957 1 Króna",
        "frontImg": "1957kf.jpg",
        "frontTitle": "Royal Mint (Tower Hill)",
        "frontDescription": "Icelandic coat of arms surrounded by the four traditional protector spirits (\"Landvættir\") of Iceland. These are Griðungur the bull, Gammur the eagle, Dreki the dragon, and Bergrisi the giant",
        "backImg": "1957kb.jpg",
        "backTitle": "KRÓNA",
        "backDescription": "Face value at the center and leaves of pubescent birch (Betula pubescens, only endemic tree of Iceland), to left and right.",
        "bottomContent": "iceland",
        "price": "$2.00",
        "ebay": ""
      },
      {
        "id": 421,
        "country": "Iceland",
        "countryname": "iceland",
        "type": "iceland",
        "typeid": 23,
        "name": "Iceland",
        "monarchtype": "iceland",
        "year": 1946,
        "tagline": "iceland",
        "title": "1946 1 Króna",
        "description": "1946 1 Króna",
        "frontImg": "1946kf.jpg",
        "frontTitle": "Royal Mint (Tower Hill)",
        "frontDescription": "Icelandic coat of arms surrounded by the four traditional protector spirits (\"Landvættir\") of Iceland. These are Griðungur the bull, Gammur the eagle, Dreki the dragon, and Bergrisi the giant",
        "backImg": "1946kb.jpg",
        "backTitle": "KRÓNA",
        "backDescription": "Face value at the center and leaves of pubescent birch (Betula pubescens, only endemic tree of Iceland), to left and right.",
        "bottomContent": "iceland",
        "price": "$2.00",
        "ebay": ""
      },
      {
        "id": 422,
        "country": "Iceland",
        "countryname": "iceland",
        "type": "iceland",
        "typeid": 23,
        "name": "Iceland",
        "monarchtype": "iceland",
        "year": 1959,
        "tagline": "iceland",
        "title": "1959 1 Króna",
        "description": "1959 1 Króna",
        "frontImg": "1959kf.jpg",
        "frontTitle": "Royal Mint (Tower Hill)",
        "frontDescription": "Icelandic coat of arms surrounded by the four traditional protector spirits (\"Landvættir\") of Iceland. These are Griðungur the bull, Gammur the eagle, Dreki the dragon, and Bergrisi the giant",
        "backImg": "1959kb.jpg",
        "backTitle": "KRÓNA",
        "backDescription": "Face value at the center and leaves of pubescent birch (Betula pubescens, only endemic tree of Iceland), to left and right.",
        "bottomContent": "iceland",
        "price": "$2.00",
        "ebay": ""
      },
      {
        "id": 423,
        "country": "Iceland",
        "countryname": "iceland",
        "type": "iceland",
        "typeid": 23,
        "name": "Iceland",
        "monarchtype": "iceland",
        "year": 1963,
        "tagline": "iceland",
        "title": "1963 2 Króna",
        "description": "1963 2 Króna",
        "frontImg": "19632kf.jpg",
        "frontTitle": "Royal Mint (Tower Hill)",
        "frontDescription": "Icelandic coat of arms surrounded by the four traditional protector spirits (\"Landvættir\") of Iceland. These are Griðungur the bull, Gammur the eagle, Dreki the dragon, and Bergrisi the giant",
        "backImg": "19632kb.jpg",
        "backTitle": "KRÓNA",
        "backDescription": "Face value at the center and leaves of pubescent birch (Betula pubescens, only endemic tree of Iceland), to left and right.",
        "bottomContent": "iceland",
        "price": "$2.00",
        "ebay": ""
      },
      {
        "id": 424,
        "country": "Iceland",
        "countryname": "iceland",
        "type": "iceland",
        "typeid": 23,
        "name": "Iceland",
        "monarchtype": "iceland",
        "year": 1953,
        "tagline": "iceland",
        "title": "1953 1 Eyrir",
        "description": "1953 1 Eyrir",
        "frontImg": "1953ef.jpg",
        "frontTitle": "Royal Mint (Tower Hill)",
        "frontDescription": "Icelandic coat of arms surrounded by a vegetable wreath.",
        "backImg": "1953eb.jpg",
        "backTitle": "Eyrir",
        "backDescription": "Face value at the centre and leaves of pubescent birch (Betula pubescens, the only endemic tree of Iceland), to left and right.",
        "bottomContent": "iceland",
        "price": "$2.00",
        "ebay": ""
      },
      {
        "id": 425,
        "country": "Iceland",
        "countryname": "iceland",
        "type": "iceland",
        "typeid": 23,
        "name": "Iceland",
        "monarchtype": "iceland",
        "year": 1963,
        "tagline": "iceland",
        "title": "1963 25 Aurar",
        "description": "1963 25 Aurar",
        "frontImg": "1963af.jpg",
        "frontTitle": "Royal Mint (Tower Hill)",
        "frontDescription": "Icelandic coat of arms surrounded by a floral wreath.",
        "backImg": "1963ab.jpg",
        "backTitle": "Aurar",
        "backDescription": "Face value at the centre and leaves of pubescent birch (Betula pubescens, the only endemic tree of Iceland), to left and right.",
        "bottomContent": "iceland",
        "price": "$15.00",
        "ebay": ""
      },
      {
        "id": 426,
        "country": "United States",
        "countryname": "unitedstates",
        "type": "morgan",
        "typeid": 10,
        "name": "Morgan Dollars",
        "monarchtype": "unitedstates",
        "year": 1878,
        "tagline": "Morgan Dollars",
        "title": "1878 S Morgan Dollars",
        "description": "1878 S Morgan Dollars",
        "frontImg": "1878sf.jpg",
        "frontTitle": "San Francisco",
        "frontDescription": "The bust of Lady Liberty",
        "backImg": "1878sb.jpg",
        "backTitle": "Liberty",
        "backDescription": "Morgans depiction of the eagle is extremely intricate and the its feathers can be seen in full detail.",
        "bottomContent": "Morgans depiction of the eagle is extremely intricate and the its feathers can be seen in full detail.",
        "price": "$76.00",
        "ebay": ""
      }
    ];

		return {
			menu,
			pages,
			features,
			images,
			companies,
			feedback,
			plans,
			websites,
			countrytypeList,
			coinList,
			cointypeList,
			monarchList,
		};
	}
}
